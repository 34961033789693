import React from 'react'

import { IonContent, IonicSlides } from '@ionic/react'
import { RouteComponentProps } from 'react-router'
import { Navigation, Pagination } from 'swiper/modules'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Box, Flex, Heading, Page, Text } from '@components'

import './FAQ.css'
import faqs from './faqs.json'

interface Props
    extends RouteComponentProps<{
        key: string
    }> {}

export const FAQItem = ({ match }: Props) => {
    const { key } = match.params
    const faq = faqs.filter((faq) => faq.key === key)[0]

    return (
        <Page title="FAQs" showBackButton>
            <IonContent>
                <Flex
                    flexDirection="column"
                    height="100%"
                    textAlign="center"
                    flexWrap="nowrap"
                    color="white"
                >
                    <Box
                        height="max(35%, 250px)"
                        position="absolute"
                        style={{
                            // Generated based off of the top toolbar color. https://cssgradient.io
                            backgroundImage:
                                'linear-gradient(180deg, rgba(16,43,66,1) 0%, rgba(0,0,0,0) 30%), url(/assets/home/splash_background_wide.png)',
                            backgroundPosition: 'bottom',
                        }}
                        px={4}
                        width={1}
                    >
                        <Heading as="h3">{faq.faq_title || faq.title}</Heading>
                    </Box>
                    <Box
                        display="inline-block"
                        position="relative"
                        top="55px"
                        minHeight={400}
                        style={{ maxHeight: 'calc(100% - 55px)' }}
                    >
                        <Swiper
                            modules={[Pagination, Navigation, IonicSlides]}
                            navigation
                            pagination
                            style={{ height: '100%' }}
                        >
                            {faq.steps.map((step) => (
                                <SwiperSlide key={step.image}>
                                    <Box p={5} height="100%">
                                        <Text.p mb={6}>{step.description}</Text.p>
                                        <img
                                            src={step.image}
                                            className="faq-image"
                                            alt={step.description}
                                            // @ts-ignore
                                            style={{ width: step.maxWidth }}
                                        />
                                    </Box>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </Box>
                </Flex>
            </IonContent>
        </Page>
    )
}
