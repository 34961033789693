import React, { useState } from 'react'

import {
    IonButtons,
    IonHeader,
    IonImg,
    IonModal,
    IonSpinner,
    IonTitle,
    IonToolbar,
    useIonAlert,
} from '@ionic/react'
import Papa from 'papaparse'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'

import {
    Box,
    Button,
    Centered,
    Content,
    ErrorMessage,
    Flex,
    Heading,
    Page,
    Text,
} from '@components'
import { ClientInput, useImportClientsMutation } from '@graphql'
import { EMAIL_REGEX, getPhoneFormatted, getPhoneMatch } from '@utils'
import { ImportClientsHelp } from './ImportClientsHelp'

interface ImportClientsInput {
    file: FileList
}

// 1 MB which could store several thousand users. Safe upper limit.
const MAX_FILE_SIZE = 1024 * 1000
const ALLOWED_CLIENT_TYPES = ['Veteran', 'A+', 'A', 'B', 'C']

interface RawClient {
    first_name?: string
    last_name?: string
    phone?: string
    email?: string
    spouse_first?: string
    spouse_last?: string
    spouse_phone?: string
    street?: string
    city?: string
    state?: string
    zip?: string
    client_type?: string
    agent_name?: string
    agent_phone?: string
    company?: string
    notes?: string
}

interface ValidClient {
    first_name: string
    last_name: string
    phone?: string
    email?: string
    spouse_first?: string
    spouse_last?: string
    spouse_phone?: string
    street: string
    city: string
    state: string
    zip: string
    client_type?: string
    agent_name?: string
    agent_phone?: string
    company?: string
    notes?: string
}

interface RowError {
    index: number
    error: string
}

interface UploadError {
    header: string
    rowErrors: RowError[]
    numValid: number
}

const requiredFields = ['first_name', 'last_name', 'street', 'city', 'state', 'zip']

const ERROR_NO_FILE = 'Please select a file to import.'
const ERROR_MULTIPLE_FILES = 'Please select a single file to import.'
const ERROR_TOO_BIG = 'Please ensure your file is 1 MB or less.'
const ERROR_INVALID_FILE =
    'Please upload a text or csv file (.txt/.csv). You may need to export ' +
    "your file as a CSV. If it still isn't working, make sure you're using " +
    'Chrome, Firefox, or Safari to do the upload.'
const ERROR_EMPTY = 'The file was empty.'
const ERROR_NO_CLIENTS = 'There were no clients in your file.'
const ERROR_GENERIC = 'There was an error uploading your file.'

export const ImportClients = () => (
    <Page title="Import Clients" showBackButton>
        <Content>
            <ImportClientsContent />
        </Content>
    </Page>
)

const trim = (val: string) => (val ? val.trim() : val)

interface Props {
    refresh?: () => void
    uid?: string
}

export const ImportClientsContent = ({ refresh, uid }: Props) => {
    const history = useHistory()
    const [processing, setProcessing] = useState(false)
    const [uploadError, setUploadError] = useState<UploadError>()
    const [importClients] = useImportClientsMutation()
    const { handleSubmit, register, formState, reset } = useForm<ImportClientsInput>()
    const [showAlert] = useIonAlert()

    const submit = async (data: ImportClientsInput) => {
        try {
            setProcessing(true)
            const files = data.file

            if (!files.length) return fail(ERROR_NO_FILE)
            else if (files.length > 1) return fail(ERROR_MULTIPLE_FILES)
            else if (files[0].size > MAX_FILE_SIZE) return fail(ERROR_TOO_BIG)
            else if (files[0].type && !files[0].type.startsWith('text/'))
                return fail(ERROR_INVALID_FILE)

            const reader = new FileReader()

            reader.addEventListener('load', async () => {
                const fileData = (reader.result as string).trim()
                const lines = fileData.split(/\r\n|\r|\n/)

                if (!lines.length) return fail(ERROR_EMPTY)
                else if (lines.length < 2) return fail(ERROR_NO_CLIENTS)

                await _parseAndSubmit(fileData)
            })

            reader.readAsText(files[0])
        } catch (e) {
            console.error(e)
            return fail(ERROR_GENERIC)
        }
    }

    const fail = async (reason: string) => {
        console.warn(reason)
        setProcessing(false)
        await showAlert(reason)
    }

    const failModal = (header: string, rowErrors: RowError[], validClients: ValidClient[]) => {
        console.warn(
            `Import finished: ${validClients.length} clients were imported, 
            and ${rowErrors.length} had errors and were skipped.`,
        )

        setUploadError({ header, rowErrors, numValid: validClients.length })
        setProcessing(false)
    }

    const _parseAndSubmit = async (fileData: string) => {
        console.debug('File passed initial validation; parsing clients.')

        const rawClients = Papa.parse(fileData.trim(), {
            header: true,
            skipEmptyLines: 'greedy',
            transform: (value: string) => trim(value),
        }).data as RawClient[]

        const validClients: ValidClient[] = []
        const errorClients: { index: number; error: string }[] = []

        for (let index = 0; index < rawClients.length; index++) {
            const [client, error] = validateClient(rawClients[index])

            if (error) {
                errorClients.push({ index: index + 1, error })
            } else {
                validClients.push(client as ValidClient)
            }
        }

        console.debug(
            `${validClients.length} clients will be imported and 
            ${errorClients.length} were invalid and will be ignored.`,
        )

        if (validClients.length === 0) {
            return failModal('No clients were imported', errorClients, validClients)
        }

        // Let's get these clients into the shape the backend is expecting.
        const clientsToSubmit: ClientInput[] = []

        for (const client of validClients) {
            clientsToSubmit.push({
                firstName: client.first_name,
                lastName: client.last_name,
                phone: client.phone,
                spouseFirstName: client.spouse_first,
                spouseLastName: client.spouse_last,
                spousePhone: client.spouse_phone,
                email: client.email,
                type: client.client_type,
                address: {
                    street: client.street,
                    city: client.city,
                    state: client.state,
                    zip: client.zip,
                },
                agentName: client.agent_name,
                agentPhone: client.agent_phone,
                company: client.company,
                notes: client.notes || '',
            })
        }

        const resp = await importClients({ variables: { clients: clientsToSubmit, uid } })

        if (resp.data?.importClients?.length) {
            if (errorClients.length) {
                return failModal('Some clients were imported', errorClients, validClients)
            } else {
                if (uid) {
                    await showAlert(`Imported all ${clientsToSubmit.length} clients for user!`)
                    setProcessing(false)
                    if (refresh) {
                        refresh()
                    }
                } else {
                    await showAlert('All your clients were imported!')
                    history.push('/app/clients')
                }
            }
        } else {
            return fail(
                `There was an error importing ${uid ? "user's" : 'your'} clients. ` +
                    'Please try again and contact us if the problem persists.',
            )
        }
    }

    const validateClient = (client: RawClient): [RawClient | ValidClient, string | null] => {
        const missingFields = []

        client.phone = client.phone?.replace(/\D/g, '') || ''
        client.agent_phone = client.agent_phone?.replace(/\D/g, '') || ''

        for (const field of requiredFields as [keyof RawClient]) {
            if (client[field] === '') {
                missingFields.push(field)
            }
        }

        let error: null | string = null

        if (missingFields.length) {
            error = `Missing information: ${missingFields.join(', ')}.`
        } else if (client.email && !EMAIL_REGEX.test(client.email)) {
            error = 'Invalid email address.'
        } else if (client.phone && client.phone.length !== 10) {
            error = 'Phone number must have 10 digits (e.g. 888-867-5309).'
        } else if (client.client_type && !ALLOWED_CLIENT_TYPES.includes(client.client_type)) {
            error = `Invalid client type. Pick one of ${ALLOWED_CLIENT_TYPES.join(
                ', ',
            )}, or leave it blank.`
        }

        // Format phone numbers nicely for display
        if (!error) {
            if (client.phone) {
                const phoneMatch = getPhoneMatch(client.phone)
                client.phone = phoneMatch ? getPhoneFormatted(phoneMatch) : client.phone
            }

            if (client.agent_phone) {
                const phoneMatch = getPhoneMatch(client.agent_phone)
                client.agent_phone = phoneMatch ? getPhoneFormatted(phoneMatch) : client.agent_phone
            }
        }

        return [client, error]
    }

    if (processing) {
        return (
            <Centered>
                <IonSpinner />
                <Text.p mt={3}>Importing contacts. This may take a couple minutes.</Text.p>
                <Text.p>Do not leave or refresh this page.</Text.p>
            </Centered>
        )
    }

    const numErrors = uploadError?.rowErrors.length || 0
    const extraErrors = Math.max(numErrors - 10, 0)

    return (
        <>
            <Box p={3}>
                {uid ? (
                    <>
                        <Heading as="h2">Upload User Clients</Heading>
                    </>
                ) : (
                    <>
                        <Heading as="h2"mb={4}>Bulk Import Clients From a File</Heading>
                        <Text.p>
                            Using the Import Template, you can upload all your clients at once
                            without having to enter their information individually.
                        </Text.p>
                        <Heading as="h5"fontWeight="bold">Directions:</Heading>
                        <Flex as="ol" mb={5}>
                            <li>Download the Import Template</li>
                            <li>
                                Copy and Paste, or enter the information noted on the column
                                headings.
                            </li>
                            <li>
                                "Save As" or "Export" the file as a CSV file format on your
                                computer.
                            </li>
                            <li>Upload a CSV file via the "Choose File" button below.</li>
                            <li>Click on the Import button below.</li>
                        </Flex>
                        <Heading as="h5"fontWeight="bold">Please Note:</Heading>
                        <Flex as="ul" mb={5}>
                            <li>The first row of the template must remain unchanged.</li>
                            <li>Please do not add any additional header columns.</li>
                            <li>
                                All required fields, highlighted in{' '}
                                <Text.span color="red" fontWeight="bold">
                                    red and bolded
                                </Text.span>
                                , must be filled in.
                            </li>
                            <li>
                                Supported client_type(s) are{' '}
                                <strong>Veteran, A+, A, B, or C.</strong>
                            </li>
                        </Flex>
                        <Heading as="h5"fontWeight="bold">Uploaded file example:</Heading>
                        <Box mb={3}>
                            <IonImg src="/assets/client_import/sample_import_screenshot.png" />
                        </Box>
                        <Flex mb={4} justifyContent="center" width={1}>
                            <Button
                                variant="link"
                                download="sample_import.xlsx"
                                href="/assets/client_import/sample_import.xlsx"
                                m={0}
                            >
                                <Text.p
                                    fontWeight="bold"
                                    mb={0}
                                    style={{ textDecoration: 'underline' }}
                                    color="blue"
                                    fontSize={24}
                                >
                                    DOWNLOAD IMPORT TEMPLATE
                                </Text.p>
                            </Button>
                        </Flex>
                        <Text.p>
                            <strong>Deduplication Notice:</strong> If uploaded record(s) match one
                            of your existing clients (based on their email address, phone, or
                            address) we will update that client instead of making a new one.
                        </Text.p>
                    </>
                )}
                <Flex alignItems="center" borderTop="base" pt={3}>
                    <Flex alignItems="center" gap={3} flexGrow={1} width={[1, 1, 'auto']}>
                        <ErrorMessage error={formState.errors.file} />
                        <input type="file" {...register('file', { required: true })} />
                    </Flex>
                    <Flex
                        flexGrow={0}
                        width={[1, 1, 'auto']}
                        mt={[5, 5, 0]}
                        justifyContent={['center', 'center', 'flex-end']}
                    >
                        <Button variant="link" onClick={() => reset()}>
                            Reset
                        </Button>
                        <Button variant="primary" onClick={handleSubmit(submit)}>
                            Import
                        </Button>
                    </Flex>
                </Flex>
                <IonModal isOpen={!processing && !!uploadError}>
                    <IonHeader>
                        <IonToolbar>
                            <IonTitle>Bulk Import Errors</IonTitle>
                            <IonButtons slot="end">
                                <Button onClick={() => setUploadError(undefined)}>Done</Button>
                            </IonButtons>
                        </IonToolbar>
                    </IonHeader>
                    <Content>
                        <Box p={4}>
                            <Heading as="h3"mb={4} mt={0}>
                                {uploadError?.header}
                            </Heading>
                            <Text.p mb={4}>
                                <strong>{uploadError?.numValid}</strong> clients were imported and{' '}
                                <strong>{numErrors}</strong> had errors and{' '}
                                {numErrors === 1 ? 'was' : 'were'} skipped.
                            </Text.p>
                            <ul>
                                {uploadError?.rowErrors.slice(0, 10).map((error) => (
                                    <li key={error.index}>
                                        <strong>Row {error.index}:</strong> {error.error}
                                    </li>
                                ))}
                                {extraErrors > 0 ? (
                                    <li>...and {extraErrors} other errors</li>
                                ) : null}
                            </ul>
                        </Box>
                    </Content>
                </IonModal>
            </Box>
            {!uid && <ImportClientsHelp />}
        </>
    )
}
