import React from 'react'

import { Box, Heading, Text } from '@components'

export const PopByGiftsBanner = () => (
    <Box textAlign="center" width="min(100%, 300px)" mx="auto">
        <Heading as="h4"color="brandPrimary" fontWeight="bold" mb={4}>
            NEED GIFTS?
        </Heading>
        <a href="https://pop-by.gifts" target="_blank" rel="noopener noreferrer">
            <Box
                as="img"
                mb={3}
                px={3}
                pt={3}
                pb={1}
                borderRadius={12}
                style={{ boxShadow: '1px 1px 2px 0 #666666' }}
                bg="#FFFFFF"
                mx="auto"
                src="/assets/PopByGiftsVerticalBanner.jpg"
                alt="Pop-By Gifts Logo"
            />
        </a>
        <Text.p color="medium" fontSize="medium">
            <a
                href="https://pop-by.gifts"
                target="_blank"
                rel="noopener noreferrer"
            >
                Pop-By Gifts
            </a>{' '}
            are customized to use your picture, contact info and company logo on the gift postcard.
        </Text.p>
    </Box>
)
