import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date-time string at UTC, such as 2019-12-03T09:54:33Z, compliant with the date-time format. */
  DateTime: any;
};

export type Address = {
  __typename?: 'Address';
  city: Scalars['String'];
  coordinates: Coordinates;
  placeId: Scalars['String'];
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AddressInput = {
  city: Scalars['String'];
  coordinates?: InputMaybe<CoordinatesInput>;
  placeId?: InputMaybe<Scalars['String']>;
  state: Scalars['String'];
  street: Scalars['String'];
  zip: Scalars['String'];
};

export type AuthWithToken = {
  __typename?: 'AuthWithToken';
  access_token: Scalars['String'];
  email: Scalars['String'];
  id: Scalars['ID'];
  isAdmin?: Maybe<Scalars['Boolean']>;
  name: Scalars['String'];
  picture: Scalars['String'];
  stripeCustomerId: Scalars['String'];
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscribed?: Maybe<Scalars['Boolean']>;
  uid: Scalars['String'];
};

export type Client = {
  __typename?: 'Client';
  address: Address;
  agentName?: Maybe<Scalars['String']>;
  agentPhone?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  birthday?: Maybe<Scalars['DateTime']>;
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  lastPopby?: Maybe<Scalars['DateTime']>;
  notes: Scalars['String'];
  notifications: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  sales?: Maybe<Scalars['Float']>;
  spouseFirstName?: Maybe<Scalars['String']>;
  spouseLastName?: Maybe<Scalars['String']>;
  spousePhone?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type ClientInput = {
  address: AddressInput;
  agentName?: InputMaybe<Scalars['String']>;
  agentPhone?: InputMaybe<Scalars['String']>;
  birthday?: InputMaybe<Scalars['DateTime']>;
  company?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  lastName: Scalars['String'];
  notes: Scalars['String'];
  phone?: InputMaybe<Scalars['String']>;
  sales?: InputMaybe<Scalars['Float']>;
  spouseFirstName?: InputMaybe<Scalars['String']>;
  spouseLastName?: InputMaybe<Scalars['String']>;
  spousePhone?: InputMaybe<Scalars['String']>;
  type?: InputMaybe<Scalars['String']>;
};

export type ClientUploadUrl = {
  __typename?: 'ClientUploadURL';
  key: Scalars['String'];
  url: Scalars['String'];
};

export type CompleteSubscriptionInput = {
  checkoutSessionId: Scalars['String'];
  userId: Scalars['String'];
};

export type Coordinates = {
  __typename?: 'Coordinates';
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CoordinatesInput = {
  lat: Scalars['Float'];
  lng: Scalars['Float'];
};

export type CreateCheckoutSession = {
  __typename?: 'CreateCheckoutSession';
  url: Scalars['String'];
};

export type CreatePortalSession = {
  __typename?: 'CreatePortalSession';
  url: Scalars['String'];
};

export type DeletedUser = {
  __typename?: 'DeletedUser';
  uid: Scalars['String'];
};

export type Gift = {
  __typename?: 'Gift';
  archived?: Maybe<Scalars['Boolean']>;
  cost?: Maybe<Scalars['Float']>;
  description: Scalars['String'];
  id: Scalars['ID'];
  name: Scalars['String'];
  quantity: Scalars['Int'];
};

export type LoginInput = {
  displayName?: InputMaybe<Scalars['String']>;
  idToken: Scalars['String'];
};

export type Mutation = {
  __typename?: 'Mutation';
  archiveClient: Client;
  archiveGift: Gift;
  completeSubscription: OptionalSubscription;
  createClientUpload: ClientUploadUrl;
  deleteGift: Gift;
  deleteMe: DeletedUser;
  deleteTemplateById: Template;
  handleUnsubscribe: Array<RemainingClientUiDs>;
  importClients: Array<Client>;
  login: AuthWithToken;
  sendClientUpload: ClientUploadUrl;
  setClientNotifications: Client;
  setUserNotificationInterval: User;
  setUserNotifications: User;
  updateUser: User;
  upsertClient: Client;
  upsertGift: Gift;
  upsertPopby: PopbyModel;
  upsertTemplate: Template;
};


export type MutationArchiveClientArgs = {
  archived: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationArchiveGiftArgs = {
  archived: Scalars['Boolean'];
  id: Scalars['ID'];
};


export type MutationCompleteSubscriptionArgs = {
  completeSubscriptionData: CompleteSubscriptionInput;
};


export type MutationCreateClientUploadArgs = {
  fileType: Scalars['String'];
};


export type MutationDeleteGiftArgs = {
  id: Scalars['ID'];
};


export type MutationDeleteMeArgs = {
  uid: Scalars['String'];
};


export type MutationDeleteTemplateByIdArgs = {
  id: Scalars['ID'];
};


export type MutationHandleUnsubscribeArgs = {
  clientUIDs: Array<Scalars['String']>;
};


export type MutationImportClientsArgs = {
  clients: Array<ClientInput>;
  uid?: InputMaybe<Scalars['String']>;
};


export type MutationLoginArgs = {
  loginInput: LoginInput;
};


export type MutationSendClientUploadArgs = {
  filename: Scalars['String'];
  key: Scalars['String'];
};


export type MutationSetClientNotificationsArgs = {
  id: Scalars['ID'];
  notify: Scalars['Boolean'];
};


export type MutationSetUserNotificationIntervalArgs = {
  interval: Scalars['Float'];
};


export type MutationSetUserNotificationsArgs = {
  notify: Scalars['Boolean'];
};


export type MutationUpdateUserArgs = {
  updates: UpdateUserInput;
};


export type MutationUpsertClientArgs = {
  client: ClientInput;
};


export type MutationUpsertGiftArgs = {
  upsertGiftData: UpsertGiftInput;
};


export type MutationUpsertPopbyArgs = {
  popby: PopbyInput;
};


export type MutationUpsertTemplateArgs = {
  upsertTemplateData: UpsertTemplateInput;
};

export type NonArchivedClientCount = {
  __typename?: 'NonArchivedClientCount';
  count: Scalars['Float'];
};

export type OptionalSubscription = {
  __typename?: 'OptionalSubscription';
  id: Scalars['ID'];
};

export type PopbyInput = {
  clientId?: InputMaybe<Scalars['String']>;
  giftId: Scalars['String'];
  id?: InputMaybe<Scalars['String']>;
  message?: InputMaybe<Scalars['String']>;
  method: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type PopbyModel = {
  __typename?: 'PopbyModel';
  client: Client;
  giftCost?: Maybe<Scalars['Float']>;
  giftName: Scalars['String'];
  id: Scalars['ID'];
  message: Scalars['String'];
  method: Scalars['String'];
  timestamp: Scalars['DateTime'];
};

export type Query = {
  __typename?: 'Query';
  getActiveGiftsForUser: Array<Gift>;
  getCheckoutSession: CreateCheckoutSession;
  getClient: Client;
  getClientsForUser: Array<Client>;
  getGiftById: Gift;
  getGiftsForUser: Array<Gift>;
  getModClientsForUser: Array<Client>;
  getNonArchivedClientCount: NonArchivedClientCount;
  getPopby: PopbyModel;
  getPopbysForClient: Array<PopbyModel>;
  getPopbysForUser: Array<PopbyModel>;
  getPortalSession: CreatePortalSession;
  getTemplateById: Template;
  getTemplatesForUser: Array<Template>;
  getUser: User;
  getUserDetails: User;
  getUsers: Array<User>;
};


export type QueryGetCheckoutSessionArgs = {
  affiliate?: InputMaybe<Scalars['String']>;
  duration: Scalars['String'];
};


export type QueryGetClientArgs = {
  id: Scalars['ID'];
};


export type QueryGetGiftByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetModClientsForUserArgs = {
  uid: Scalars['String'];
};


export type QueryGetPopbyArgs = {
  id: Scalars['ID'];
};


export type QueryGetPopbysForClientArgs = {
  clientId: Scalars['ID'];
};


export type QueryGetTemplateByIdArgs = {
  id: Scalars['String'];
};


export type QueryGetUserDetailsArgs = {
  uid: Scalars['String'];
};

export type RemainingClientUiDs = {
  __typename?: 'RemainingClientUIDs';
  clientUID: Scalars['String'];
};

export type Template = {
  __typename?: 'Template';
  id: Scalars['ID'];
  message: Scalars['String'];
  name: Scalars['String'];
};

export type UpdateUserInput = {
  company?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  lastName?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  signature?: InputMaybe<Scalars['String']>;
};

export type UpsertGiftInput = {
  cost?: InputMaybe<Scalars['Float']>;
  description?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['String']>;
  name: Scalars['String'];
  quantity: Scalars['Float'];
};

export type UpsertTemplateInput = {
  id?: InputMaybe<Scalars['String']>;
  message: Scalars['String'];
  name: Scalars['String'];
};

export type User = {
  __typename?: 'User';
  company?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  isAdmin?: Maybe<Scalars['Boolean']>;
  lastName?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  notificationInterval: Scalars['Float'];
  notifications: Scalars['Boolean'];
  phone?: Maybe<Scalars['String']>;
  signature?: Maybe<Scalars['String']>;
  stripeSubscriptionId?: Maybe<Scalars['String']>;
  subscribed: Scalars['Boolean'];
  uid: Scalars['String'];
};

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', getUser: { __typename?: 'User', name?: string | null, firstName?: string | null, lastName?: string | null, phone?: string | null, company?: string | null, signature?: string | null, notifications: boolean, notificationInterval: number, isAdmin?: boolean | null, subscribed: boolean, stripeSubscriptionId?: string | null } };

export type GetGiftsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetGiftsQuery = { __typename?: 'Query', getGiftsForUser: Array<{ __typename?: 'Gift', id: string, name: string, description: string, quantity: number, cost?: number | null, archived?: boolean | null }> };

export type GetTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTemplatesQuery = { __typename?: 'Query', getTemplatesForUser: Array<{ __typename?: 'Template', id: string, name: string, message: string }> };

export type UpdateUserMutationVariables = Exact<{
  updates: UpdateUserInput;
}>;


export type UpdateUserMutation = { __typename?: 'Mutation', updateUser: { __typename?: 'User', name?: string | null } };

export type LoginMutationVariables = Exact<{
  login: LoginInput;
}>;


export type LoginMutation = { __typename?: 'Mutation', login: { __typename?: 'AuthWithToken', uid: string, access_token: string, name: string, email: string, stripeCustomerId: string, stripeSubscriptionId?: string | null, subscribed?: boolean | null, isAdmin?: boolean | null } };

export type GetClientsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetClientsQuery = { __typename?: 'Query', getClientsForUser: Array<{ __typename?: 'Client', id: string, firstName: string, lastName: string, phone?: string | null, spouseFirstName?: string | null, spousePhone?: string | null, email?: string | null, type?: string | null, notifications: boolean, lastPopby?: any | null, archived?: boolean | null, agentName?: string | null, agentPhone?: string | null, birthday?: any | null, address: { __typename?: 'Address', city: string, state: string, zip: string, coordinates: { __typename?: 'Coordinates', lat: number, lng: number } } }>, getUser: { __typename?: 'User', notificationInterval: number } };

export type AddOrEditClientMutationVariables = Exact<{
  client: ClientInput;
}>;


export type AddOrEditClientMutation = { __typename?: 'Mutation', upsertClient: { __typename?: 'Client', id: string, address: { __typename?: 'Address', street: string, city: string, state: string, zip: string, placeId: string, coordinates: { __typename?: 'Coordinates', lat: number, lng: number } } } };

export type GetClientQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type GetClientQuery = { __typename?: 'Query', getClient: { __typename?: 'Client', id: string, firstName: string, lastName: string, phone?: string | null, spouseFirstName?: string | null, spousePhone?: string | null, email?: string | null, type?: string | null, notes: string, sales?: number | null, birthday?: any | null, notifications: boolean, archived?: boolean | null, agentName?: string | null, agentPhone?: string | null, company?: string | null, address: { __typename?: 'Address', street: string, city: string, state: string, zip: string, placeId: string, coordinates: { __typename?: 'Coordinates', lat: number, lng: number } } } };

export type ArchiveClientMutationVariables = Exact<{
  clientId: Scalars['ID'];
  archived: Scalars['Boolean'];
}>;


export type ArchiveClientMutation = { __typename?: 'Mutation', archiveClient: { __typename?: 'Client', id: string } };

export type SetClientNotificationsMutationVariables = Exact<{
  clientId: Scalars['ID'];
  notify: Scalars['Boolean'];
}>;


export type SetClientNotificationsMutation = { __typename?: 'Mutation', setClientNotifications: { __typename?: 'Client', notifications: boolean } };

export type GetPopbysForClientQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type GetPopbysForClientQuery = { __typename?: 'Query', getPopbysForClient: Array<{ __typename?: 'PopbyModel', giftName: string, giftCost?: number | null, method: string, timestamp: any }> };

export type ImportClientsMutationVariables = Exact<{
  clients: Array<ClientInput> | ClientInput;
  uid?: InputMaybe<Scalars['String']>;
}>;


export type ImportClientsMutation = { __typename?: 'Mutation', importClients: Array<{ __typename?: 'Client', id: string }> };

export type GetNonArchivedClientCountQueryVariables = Exact<{ [key: string]: never; }>;


export type GetNonArchivedClientCountQuery = { __typename?: 'Query', getNonArchivedClientCount: { __typename?: 'NonArchivedClientCount', count: number } };

export type HandleUnsubscribeMutationVariables = Exact<{
  clientUIDs: Array<Scalars['String']> | Scalars['String'];
}>;


export type HandleUnsubscribeMutation = { __typename?: 'Mutation', handleUnsubscribe: Array<{ __typename?: 'RemainingClientUIDs', clientUID: string }> };

export type CreateClientUploadMutationVariables = Exact<{
  fileType: Scalars['String'];
}>;


export type CreateClientUploadMutation = { __typename?: 'Mutation', createClientUpload: { __typename?: 'ClientUploadURL', key: string, url: string } };

export type SendClientUploadMutationVariables = Exact<{
  key: Scalars['String'];
  filename: Scalars['String'];
}>;


export type SendClientUploadMutation = { __typename?: 'Mutation', sendClientUpload: { __typename?: 'ClientUploadURL', key: string } };

export type AddOrEditPopbyMutationVariables = Exact<{
  popby: PopbyInput;
}>;


export type AddOrEditPopbyMutation = { __typename?: 'Mutation', upsertPopby: { __typename?: 'PopbyModel', id: string } };

export type GetPopbyInfoQueryVariables = Exact<{
  clientId: Scalars['ID'];
}>;


export type GetPopbyInfoQuery = { __typename?: 'Query', getClient: { __typename?: 'Client', firstName: string, lastName: string, phone?: string | null, spouseFirstName?: string | null, spouseLastName?: string | null, spousePhone?: string | null, notes: string, agentPhone?: string | null, address: { __typename?: 'Address', street: string, city: string, state: string, zip: string, placeId: string, coordinates: { __typename?: 'Coordinates', lat: number, lng: number } } }, getTemplatesForUser: Array<{ __typename?: 'Template', id: string, name: string, message: string }>, getPopbysForClient: Array<{ __typename?: 'PopbyModel', timestamp: any, giftName: string }> };

export type GetPopbysQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPopbysQuery = { __typename?: 'Query', getPopbysForUser: Array<{ __typename?: 'PopbyModel', id: string, timestamp: any, giftName: string, client: { __typename?: 'Client', firstName: string, lastName: string, type?: string | null, agentName?: string | null } }> };

export type GetPopbyQueryVariables = Exact<{
  popbyId: Scalars['ID'];
}>;


export type GetPopbyQuery = { __typename?: 'Query', getPopby: { __typename?: 'PopbyModel', id: string, timestamp: any, giftName: string, giftCost?: number | null, method: string, client: { __typename?: 'Client', firstName: string, lastName: string, spouseFirstName?: string | null, agentName?: string | null, address: { __typename?: 'Address', street: string, city: string, state: string, zip: string } } } };

export type AddOrEditGiftsMutationVariables = Exact<{
  gift: UpsertGiftInput;
}>;


export type AddOrEditGiftsMutation = { __typename?: 'Mutation', upsertGift: { __typename?: 'Gift', id: string, name: string, description: string, quantity: number, cost?: number | null } };

export type ArchiveGiftMutationVariables = Exact<{
  giftId: Scalars['ID'];
  archived: Scalars['Boolean'];
}>;


export type ArchiveGiftMutation = { __typename?: 'Mutation', archiveGift: { __typename?: 'Gift', id: string } };

export type DeleteGiftMutationVariables = Exact<{
  giftId: Scalars['ID'];
}>;


export type DeleteGiftMutation = { __typename?: 'Mutation', deleteGift: { __typename?: 'Gift', id: string } };

export type AddOrEditTemplateMutationVariables = Exact<{
  template: UpsertTemplateInput;
}>;


export type AddOrEditTemplateMutation = { __typename?: 'Mutation', upsertTemplate: { __typename?: 'Template', id: string, name: string, message: string } };

export type DeleteTemplateMutationVariables = Exact<{
  templateId: Scalars['ID'];
}>;


export type DeleteTemplateMutation = { __typename?: 'Mutation', deleteTemplateById: { __typename?: 'Template', id: string } };

export type GetUsersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUsersQuery = { __typename?: 'Query', getUsers: Array<{ __typename?: 'User', uid: string, email?: string | null, name?: string | null }> };

export type GetUserDetailsQueryVariables = Exact<{
  uid: Scalars['String'];
}>;


export type GetUserDetailsQuery = { __typename?: 'Query', getUserDetails: { __typename?: 'User', uid: string, email?: string | null, name?: string | null, firstName?: string | null, lastName?: string | null }, getModClientsForUser: Array<{ __typename?: 'Client', id: string }> };

export type SetUserNotificationsMutationVariables = Exact<{
  notify: Scalars['Boolean'];
}>;


export type SetUserNotificationsMutation = { __typename?: 'Mutation', setUserNotifications: { __typename?: 'User', notifications: boolean } };

export type SetUserNotificationIntervalMutationVariables = Exact<{
  interval: Scalars['Float'];
}>;


export type SetUserNotificationIntervalMutation = { __typename?: 'Mutation', setUserNotificationInterval: { __typename?: 'User', notifications: boolean } };

export type GetBillingPortalSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type GetBillingPortalSessionQuery = { __typename?: 'Query', getPortalSession: { __typename?: 'CreatePortalSession', url: string } };

export type DeleteUserMutationVariables = Exact<{
  uid: Scalars['String'];
}>;


export type DeleteUserMutation = { __typename?: 'Mutation', deleteMe: { __typename?: 'DeletedUser', uid: string } };

export type GetCheckoutSessionQueryVariables = Exact<{
  duration: Scalars['String'];
  affiliate?: InputMaybe<Scalars['String']>;
}>;


export type GetCheckoutSessionQuery = { __typename?: 'Query', getCheckoutSession: { __typename?: 'CreateCheckoutSession', url: string } };

export type CompleteSubscriptionMutationVariables = Exact<{
  subscription: CompleteSubscriptionInput;
}>;


export type CompleteSubscriptionMutation = { __typename?: 'Mutation', completeSubscription: { __typename?: 'OptionalSubscription', id: string } };


export const GetUserDocument = gql`
    query GetUser {
  getUser {
    name
    firstName
    lastName
    phone
    company
    signature
    notifications
    notificationInterval
    isAdmin
    subscribed
    stripeSubscriptionId
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetGiftsDocument = gql`
    query GetGifts {
  getGiftsForUser {
    id
    name
    description
    quantity
    cost
    archived
  }
}
    `;

/**
 * __useGetGiftsQuery__
 *
 * To run a query within a React component, call `useGetGiftsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGiftsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGiftsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetGiftsQuery(baseOptions?: Apollo.QueryHookOptions<GetGiftsQuery, GetGiftsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGiftsQuery, GetGiftsQueryVariables>(GetGiftsDocument, options);
      }
export function useGetGiftsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGiftsQuery, GetGiftsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGiftsQuery, GetGiftsQueryVariables>(GetGiftsDocument, options);
        }
export type GetGiftsQueryHookResult = ReturnType<typeof useGetGiftsQuery>;
export type GetGiftsLazyQueryHookResult = ReturnType<typeof useGetGiftsLazyQuery>;
export type GetGiftsQueryResult = Apollo.QueryResult<GetGiftsQuery, GetGiftsQueryVariables>;
export const GetTemplatesDocument = gql`
    query GetTemplates {
  getTemplatesForUser {
    id
    name
    message
  }
}
    `;

/**
 * __useGetTemplatesQuery__
 *
 * To run a query within a React component, call `useGetTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, options);
      }
export function useGetTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTemplatesQuery, GetTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTemplatesQuery, GetTemplatesQueryVariables>(GetTemplatesDocument, options);
        }
export type GetTemplatesQueryHookResult = ReturnType<typeof useGetTemplatesQuery>;
export type GetTemplatesLazyQueryHookResult = ReturnType<typeof useGetTemplatesLazyQuery>;
export type GetTemplatesQueryResult = Apollo.QueryResult<GetTemplatesQuery, GetTemplatesQueryVariables>;
export const UpdateUserDocument = gql`
    mutation UpdateUser($updates: UpdateUserInput!) {
  updateUser(updates: $updates) {
    name
  }
}
    `;
export type UpdateUserMutationFn = Apollo.MutationFunction<UpdateUserMutation, UpdateUserMutationVariables>;

/**
 * __useUpdateUserMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutation, { data, loading, error }] = useUpdateUserMutation({
 *   variables: {
 *      updates: // value for 'updates'
 *   },
 * });
 */
export function useUpdateUserMutation(baseOptions?: Apollo.MutationHookOptions<UpdateUserMutation, UpdateUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateUserMutation, UpdateUserMutationVariables>(UpdateUserDocument, options);
      }
export type UpdateUserMutationHookResult = ReturnType<typeof useUpdateUserMutation>;
export type UpdateUserMutationResult = Apollo.MutationResult<UpdateUserMutation>;
export type UpdateUserMutationOptions = Apollo.BaseMutationOptions<UpdateUserMutation, UpdateUserMutationVariables>;
export const LoginDocument = gql`
    mutation Login($login: LoginInput!) {
  login(loginInput: $login) {
    uid
    access_token
    name
    email
    stripeCustomerId
    stripeSubscriptionId
    subscribed
    isAdmin
  }
}
    `;
export type LoginMutationFn = Apollo.MutationFunction<LoginMutation, LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      login: // value for 'login'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: Apollo.MutationHookOptions<LoginMutation, LoginMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<LoginMutation, LoginMutationVariables>(LoginDocument, options);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = Apollo.MutationResult<LoginMutation>;
export type LoginMutationOptions = Apollo.BaseMutationOptions<LoginMutation, LoginMutationVariables>;
export const GetClientsDocument = gql`
    query GetClients {
  getClientsForUser {
    id
    firstName
    lastName
    phone
    spouseFirstName
    spousePhone
    email
    type
    address {
      city
      state
      zip
      coordinates {
        lat
        lng
      }
    }
    notifications
    lastPopby
    archived
    agentName
    agentPhone
    birthday
  }
  getUser {
    notificationInterval
  }
}
    `;

/**
 * __useGetClientsQuery__
 *
 * To run a query within a React component, call `useGetClientsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetClientsQuery(baseOptions?: Apollo.QueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
      }
export function useGetClientsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientsQuery, GetClientsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientsQuery, GetClientsQueryVariables>(GetClientsDocument, options);
        }
export type GetClientsQueryHookResult = ReturnType<typeof useGetClientsQuery>;
export type GetClientsLazyQueryHookResult = ReturnType<typeof useGetClientsLazyQuery>;
export type GetClientsQueryResult = Apollo.QueryResult<GetClientsQuery, GetClientsQueryVariables>;
export const AddOrEditClientDocument = gql`
    mutation AddOrEditClient($client: ClientInput!) {
  upsertClient(client: $client) {
    id
    address {
      street
      city
      state
      zip
      coordinates {
        lat
        lng
      }
      placeId
    }
  }
}
    `;
export type AddOrEditClientMutationFn = Apollo.MutationFunction<AddOrEditClientMutation, AddOrEditClientMutationVariables>;

/**
 * __useAddOrEditClientMutation__
 *
 * To run a mutation, you first call `useAddOrEditClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditClientMutation, { data, loading, error }] = useAddOrEditClientMutation({
 *   variables: {
 *      client: // value for 'client'
 *   },
 * });
 */
export function useAddOrEditClientMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditClientMutation, AddOrEditClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditClientMutation, AddOrEditClientMutationVariables>(AddOrEditClientDocument, options);
      }
export type AddOrEditClientMutationHookResult = ReturnType<typeof useAddOrEditClientMutation>;
export type AddOrEditClientMutationResult = Apollo.MutationResult<AddOrEditClientMutation>;
export type AddOrEditClientMutationOptions = Apollo.BaseMutationOptions<AddOrEditClientMutation, AddOrEditClientMutationVariables>;
export const GetClientDocument = gql`
    query GetClient($clientId: ID!) {
  getClient(id: $clientId) {
    id
    firstName
    lastName
    phone
    spouseFirstName
    spousePhone
    email
    type
    address {
      street
      city
      state
      zip
      coordinates {
        lat
        lng
      }
      placeId
    }
    notes
    sales
    birthday
    notifications
    archived
    agentName
    agentPhone
    company
  }
}
    `;

/**
 * __useGetClientQuery__
 *
 * To run a query within a React component, call `useGetClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetClientQuery(baseOptions: Apollo.QueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
      }
export function useGetClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetClientQuery, GetClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetClientQuery, GetClientQueryVariables>(GetClientDocument, options);
        }
export type GetClientQueryHookResult = ReturnType<typeof useGetClientQuery>;
export type GetClientLazyQueryHookResult = ReturnType<typeof useGetClientLazyQuery>;
export type GetClientQueryResult = Apollo.QueryResult<GetClientQuery, GetClientQueryVariables>;
export const ArchiveClientDocument = gql`
    mutation ArchiveClient($clientId: ID!, $archived: Boolean!) {
  archiveClient(id: $clientId, archived: $archived) {
    id
  }
}
    `;
export type ArchiveClientMutationFn = Apollo.MutationFunction<ArchiveClientMutation, ArchiveClientMutationVariables>;

/**
 * __useArchiveClientMutation__
 *
 * To run a mutation, you first call `useArchiveClientMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveClientMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveClientMutation, { data, loading, error }] = useArchiveClientMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useArchiveClientMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveClientMutation, ArchiveClientMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveClientMutation, ArchiveClientMutationVariables>(ArchiveClientDocument, options);
      }
export type ArchiveClientMutationHookResult = ReturnType<typeof useArchiveClientMutation>;
export type ArchiveClientMutationResult = Apollo.MutationResult<ArchiveClientMutation>;
export type ArchiveClientMutationOptions = Apollo.BaseMutationOptions<ArchiveClientMutation, ArchiveClientMutationVariables>;
export const SetClientNotificationsDocument = gql`
    mutation SetClientNotifications($clientId: ID!, $notify: Boolean!) {
  setClientNotifications(id: $clientId, notify: $notify) {
    notifications
  }
}
    `;
export type SetClientNotificationsMutationFn = Apollo.MutationFunction<SetClientNotificationsMutation, SetClientNotificationsMutationVariables>;

/**
 * __useSetClientNotificationsMutation__
 *
 * To run a mutation, you first call `useSetClientNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetClientNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setClientNotificationsMutation, { data, loading, error }] = useSetClientNotificationsMutation({
 *   variables: {
 *      clientId: // value for 'clientId'
 *      notify: // value for 'notify'
 *   },
 * });
 */
export function useSetClientNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SetClientNotificationsMutation, SetClientNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetClientNotificationsMutation, SetClientNotificationsMutationVariables>(SetClientNotificationsDocument, options);
      }
export type SetClientNotificationsMutationHookResult = ReturnType<typeof useSetClientNotificationsMutation>;
export type SetClientNotificationsMutationResult = Apollo.MutationResult<SetClientNotificationsMutation>;
export type SetClientNotificationsMutationOptions = Apollo.BaseMutationOptions<SetClientNotificationsMutation, SetClientNotificationsMutationVariables>;
export const GetPopbysForClientDocument = gql`
    query GetPopbysForClient($clientId: ID!) {
  getPopbysForClient(clientId: $clientId) {
    giftName
    giftCost
    method
    timestamp
  }
}
    `;

/**
 * __useGetPopbysForClientQuery__
 *
 * To run a query within a React component, call `useGetPopbysForClientQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopbysForClientQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopbysForClientQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetPopbysForClientQuery(baseOptions: Apollo.QueryHookOptions<GetPopbysForClientQuery, GetPopbysForClientQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPopbysForClientQuery, GetPopbysForClientQueryVariables>(GetPopbysForClientDocument, options);
      }
export function useGetPopbysForClientLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPopbysForClientQuery, GetPopbysForClientQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPopbysForClientQuery, GetPopbysForClientQueryVariables>(GetPopbysForClientDocument, options);
        }
export type GetPopbysForClientQueryHookResult = ReturnType<typeof useGetPopbysForClientQuery>;
export type GetPopbysForClientLazyQueryHookResult = ReturnType<typeof useGetPopbysForClientLazyQuery>;
export type GetPopbysForClientQueryResult = Apollo.QueryResult<GetPopbysForClientQuery, GetPopbysForClientQueryVariables>;
export const ImportClientsDocument = gql`
    mutation ImportClients($clients: [ClientInput!]!, $uid: String) {
  importClients(clients: $clients, uid: $uid) {
    id
  }
}
    `;
export type ImportClientsMutationFn = Apollo.MutationFunction<ImportClientsMutation, ImportClientsMutationVariables>;

/**
 * __useImportClientsMutation__
 *
 * To run a mutation, you first call `useImportClientsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportClientsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importClientsMutation, { data, loading, error }] = useImportClientsMutation({
 *   variables: {
 *      clients: // value for 'clients'
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useImportClientsMutation(baseOptions?: Apollo.MutationHookOptions<ImportClientsMutation, ImportClientsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportClientsMutation, ImportClientsMutationVariables>(ImportClientsDocument, options);
      }
export type ImportClientsMutationHookResult = ReturnType<typeof useImportClientsMutation>;
export type ImportClientsMutationResult = Apollo.MutationResult<ImportClientsMutation>;
export type ImportClientsMutationOptions = Apollo.BaseMutationOptions<ImportClientsMutation, ImportClientsMutationVariables>;
export const GetNonArchivedClientCountDocument = gql`
    query GetNonArchivedClientCount {
  getNonArchivedClientCount {
    count
  }
}
    `;

/**
 * __useGetNonArchivedClientCountQuery__
 *
 * To run a query within a React component, call `useGetNonArchivedClientCountQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNonArchivedClientCountQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNonArchivedClientCountQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNonArchivedClientCountQuery(baseOptions?: Apollo.QueryHookOptions<GetNonArchivedClientCountQuery, GetNonArchivedClientCountQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetNonArchivedClientCountQuery, GetNonArchivedClientCountQueryVariables>(GetNonArchivedClientCountDocument, options);
      }
export function useGetNonArchivedClientCountLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetNonArchivedClientCountQuery, GetNonArchivedClientCountQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetNonArchivedClientCountQuery, GetNonArchivedClientCountQueryVariables>(GetNonArchivedClientCountDocument, options);
        }
export type GetNonArchivedClientCountQueryHookResult = ReturnType<typeof useGetNonArchivedClientCountQuery>;
export type GetNonArchivedClientCountLazyQueryHookResult = ReturnType<typeof useGetNonArchivedClientCountLazyQuery>;
export type GetNonArchivedClientCountQueryResult = Apollo.QueryResult<GetNonArchivedClientCountQuery, GetNonArchivedClientCountQueryVariables>;
export const HandleUnsubscribeDocument = gql`
    mutation HandleUnsubscribe($clientUIDs: [String!]!) {
  handleUnsubscribe(clientUIDs: $clientUIDs) {
    clientUID
  }
}
    `;
export type HandleUnsubscribeMutationFn = Apollo.MutationFunction<HandleUnsubscribeMutation, HandleUnsubscribeMutationVariables>;

/**
 * __useHandleUnsubscribeMutation__
 *
 * To run a mutation, you first call `useHandleUnsubscribeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useHandleUnsubscribeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [handleUnsubscribeMutation, { data, loading, error }] = useHandleUnsubscribeMutation({
 *   variables: {
 *      clientUIDs: // value for 'clientUIDs'
 *   },
 * });
 */
export function useHandleUnsubscribeMutation(baseOptions?: Apollo.MutationHookOptions<HandleUnsubscribeMutation, HandleUnsubscribeMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<HandleUnsubscribeMutation, HandleUnsubscribeMutationVariables>(HandleUnsubscribeDocument, options);
      }
export type HandleUnsubscribeMutationHookResult = ReturnType<typeof useHandleUnsubscribeMutation>;
export type HandleUnsubscribeMutationResult = Apollo.MutationResult<HandleUnsubscribeMutation>;
export type HandleUnsubscribeMutationOptions = Apollo.BaseMutationOptions<HandleUnsubscribeMutation, HandleUnsubscribeMutationVariables>;
export const CreateClientUploadDocument = gql`
    mutation CreateClientUpload($fileType: String!) {
  createClientUpload(fileType: $fileType) {
    key
    url
  }
}
    `;
export type CreateClientUploadMutationFn = Apollo.MutationFunction<CreateClientUploadMutation, CreateClientUploadMutationVariables>;

/**
 * __useCreateClientUploadMutation__
 *
 * To run a mutation, you first call `useCreateClientUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateClientUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createClientUploadMutation, { data, loading, error }] = useCreateClientUploadMutation({
 *   variables: {
 *      fileType: // value for 'fileType'
 *   },
 * });
 */
export function useCreateClientUploadMutation(baseOptions?: Apollo.MutationHookOptions<CreateClientUploadMutation, CreateClientUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateClientUploadMutation, CreateClientUploadMutationVariables>(CreateClientUploadDocument, options);
      }
export type CreateClientUploadMutationHookResult = ReturnType<typeof useCreateClientUploadMutation>;
export type CreateClientUploadMutationResult = Apollo.MutationResult<CreateClientUploadMutation>;
export type CreateClientUploadMutationOptions = Apollo.BaseMutationOptions<CreateClientUploadMutation, CreateClientUploadMutationVariables>;
export const SendClientUploadDocument = gql`
    mutation SendClientUpload($key: String!, $filename: String!) {
  sendClientUpload(key: $key, filename: $filename) {
    key
  }
}
    `;
export type SendClientUploadMutationFn = Apollo.MutationFunction<SendClientUploadMutation, SendClientUploadMutationVariables>;

/**
 * __useSendClientUploadMutation__
 *
 * To run a mutation, you first call `useSendClientUploadMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendClientUploadMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendClientUploadMutation, { data, loading, error }] = useSendClientUploadMutation({
 *   variables: {
 *      key: // value for 'key'
 *      filename: // value for 'filename'
 *   },
 * });
 */
export function useSendClientUploadMutation(baseOptions?: Apollo.MutationHookOptions<SendClientUploadMutation, SendClientUploadMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendClientUploadMutation, SendClientUploadMutationVariables>(SendClientUploadDocument, options);
      }
export type SendClientUploadMutationHookResult = ReturnType<typeof useSendClientUploadMutation>;
export type SendClientUploadMutationResult = Apollo.MutationResult<SendClientUploadMutation>;
export type SendClientUploadMutationOptions = Apollo.BaseMutationOptions<SendClientUploadMutation, SendClientUploadMutationVariables>;
export const AddOrEditPopbyDocument = gql`
    mutation AddOrEditPopby($popby: PopbyInput!) {
  upsertPopby(popby: $popby) {
    id
  }
}
    `;
export type AddOrEditPopbyMutationFn = Apollo.MutationFunction<AddOrEditPopbyMutation, AddOrEditPopbyMutationVariables>;

/**
 * __useAddOrEditPopbyMutation__
 *
 * To run a mutation, you first call `useAddOrEditPopbyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditPopbyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditPopbyMutation, { data, loading, error }] = useAddOrEditPopbyMutation({
 *   variables: {
 *      popby: // value for 'popby'
 *   },
 * });
 */
export function useAddOrEditPopbyMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditPopbyMutation, AddOrEditPopbyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditPopbyMutation, AddOrEditPopbyMutationVariables>(AddOrEditPopbyDocument, options);
      }
export type AddOrEditPopbyMutationHookResult = ReturnType<typeof useAddOrEditPopbyMutation>;
export type AddOrEditPopbyMutationResult = Apollo.MutationResult<AddOrEditPopbyMutation>;
export type AddOrEditPopbyMutationOptions = Apollo.BaseMutationOptions<AddOrEditPopbyMutation, AddOrEditPopbyMutationVariables>;
export const GetPopbyInfoDocument = gql`
    query GetPopbyInfo($clientId: ID!) {
  getClient(id: $clientId) {
    firstName
    lastName
    phone
    spouseFirstName
    spouseLastName
    spousePhone
    address {
      street
      city
      state
      zip
      coordinates {
        lat
        lng
      }
      placeId
    }
    notes
    agentPhone
  }
  getTemplatesForUser {
    id
    name
    message
  }
  getPopbysForClient(clientId: $clientId) {
    timestamp
    giftName
  }
}
    `;

/**
 * __useGetPopbyInfoQuery__
 *
 * To run a query within a React component, call `useGetPopbyInfoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopbyInfoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopbyInfoQuery({
 *   variables: {
 *      clientId: // value for 'clientId'
 *   },
 * });
 */
export function useGetPopbyInfoQuery(baseOptions: Apollo.QueryHookOptions<GetPopbyInfoQuery, GetPopbyInfoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPopbyInfoQuery, GetPopbyInfoQueryVariables>(GetPopbyInfoDocument, options);
      }
export function useGetPopbyInfoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPopbyInfoQuery, GetPopbyInfoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPopbyInfoQuery, GetPopbyInfoQueryVariables>(GetPopbyInfoDocument, options);
        }
export type GetPopbyInfoQueryHookResult = ReturnType<typeof useGetPopbyInfoQuery>;
export type GetPopbyInfoLazyQueryHookResult = ReturnType<typeof useGetPopbyInfoLazyQuery>;
export type GetPopbyInfoQueryResult = Apollo.QueryResult<GetPopbyInfoQuery, GetPopbyInfoQueryVariables>;
export const GetPopbysDocument = gql`
    query GetPopbys {
  getPopbysForUser {
    id
    timestamp
    client {
      firstName
      lastName
      type
      agentName
    }
    giftName
  }
}
    `;

/**
 * __useGetPopbysQuery__
 *
 * To run a query within a React component, call `useGetPopbysQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopbysQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopbysQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPopbysQuery(baseOptions?: Apollo.QueryHookOptions<GetPopbysQuery, GetPopbysQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPopbysQuery, GetPopbysQueryVariables>(GetPopbysDocument, options);
      }
export function useGetPopbysLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPopbysQuery, GetPopbysQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPopbysQuery, GetPopbysQueryVariables>(GetPopbysDocument, options);
        }
export type GetPopbysQueryHookResult = ReturnType<typeof useGetPopbysQuery>;
export type GetPopbysLazyQueryHookResult = ReturnType<typeof useGetPopbysLazyQuery>;
export type GetPopbysQueryResult = Apollo.QueryResult<GetPopbysQuery, GetPopbysQueryVariables>;
export const GetPopbyDocument = gql`
    query GetPopby($popbyId: ID!) {
  getPopby(id: $popbyId) {
    id
    timestamp
    client {
      firstName
      lastName
      spouseFirstName
      agentName
      address {
        street
        city
        state
        zip
      }
    }
    giftName
    giftCost
    method
  }
}
    `;

/**
 * __useGetPopbyQuery__
 *
 * To run a query within a React component, call `useGetPopbyQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPopbyQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPopbyQuery({
 *   variables: {
 *      popbyId: // value for 'popbyId'
 *   },
 * });
 */
export function useGetPopbyQuery(baseOptions: Apollo.QueryHookOptions<GetPopbyQuery, GetPopbyQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPopbyQuery, GetPopbyQueryVariables>(GetPopbyDocument, options);
      }
export function useGetPopbyLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPopbyQuery, GetPopbyQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPopbyQuery, GetPopbyQueryVariables>(GetPopbyDocument, options);
        }
export type GetPopbyQueryHookResult = ReturnType<typeof useGetPopbyQuery>;
export type GetPopbyLazyQueryHookResult = ReturnType<typeof useGetPopbyLazyQuery>;
export type GetPopbyQueryResult = Apollo.QueryResult<GetPopbyQuery, GetPopbyQueryVariables>;
export const AddOrEditGiftsDocument = gql`
    mutation AddOrEditGifts($gift: UpsertGiftInput!) {
  upsertGift(upsertGiftData: $gift) {
    id
    name
    description
    quantity
    cost
  }
}
    `;
export type AddOrEditGiftsMutationFn = Apollo.MutationFunction<AddOrEditGiftsMutation, AddOrEditGiftsMutationVariables>;

/**
 * __useAddOrEditGiftsMutation__
 *
 * To run a mutation, you first call `useAddOrEditGiftsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditGiftsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditGiftsMutation, { data, loading, error }] = useAddOrEditGiftsMutation({
 *   variables: {
 *      gift: // value for 'gift'
 *   },
 * });
 */
export function useAddOrEditGiftsMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditGiftsMutation, AddOrEditGiftsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditGiftsMutation, AddOrEditGiftsMutationVariables>(AddOrEditGiftsDocument, options);
      }
export type AddOrEditGiftsMutationHookResult = ReturnType<typeof useAddOrEditGiftsMutation>;
export type AddOrEditGiftsMutationResult = Apollo.MutationResult<AddOrEditGiftsMutation>;
export type AddOrEditGiftsMutationOptions = Apollo.BaseMutationOptions<AddOrEditGiftsMutation, AddOrEditGiftsMutationVariables>;
export const ArchiveGiftDocument = gql`
    mutation ArchiveGift($giftId: ID!, $archived: Boolean!) {
  archiveGift(id: $giftId, archived: $archived) {
    id
  }
}
    `;
export type ArchiveGiftMutationFn = Apollo.MutationFunction<ArchiveGiftMutation, ArchiveGiftMutationVariables>;

/**
 * __useArchiveGiftMutation__
 *
 * To run a mutation, you first call `useArchiveGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useArchiveGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [archiveGiftMutation, { data, loading, error }] = useArchiveGiftMutation({
 *   variables: {
 *      giftId: // value for 'giftId'
 *      archived: // value for 'archived'
 *   },
 * });
 */
export function useArchiveGiftMutation(baseOptions?: Apollo.MutationHookOptions<ArchiveGiftMutation, ArchiveGiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ArchiveGiftMutation, ArchiveGiftMutationVariables>(ArchiveGiftDocument, options);
      }
export type ArchiveGiftMutationHookResult = ReturnType<typeof useArchiveGiftMutation>;
export type ArchiveGiftMutationResult = Apollo.MutationResult<ArchiveGiftMutation>;
export type ArchiveGiftMutationOptions = Apollo.BaseMutationOptions<ArchiveGiftMutation, ArchiveGiftMutationVariables>;
export const DeleteGiftDocument = gql`
    mutation DeleteGift($giftId: ID!) {
  deleteGift(id: $giftId) {
    id
  }
}
    `;
export type DeleteGiftMutationFn = Apollo.MutationFunction<DeleteGiftMutation, DeleteGiftMutationVariables>;

/**
 * __useDeleteGiftMutation__
 *
 * To run a mutation, you first call `useDeleteGiftMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGiftMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGiftMutation, { data, loading, error }] = useDeleteGiftMutation({
 *   variables: {
 *      giftId: // value for 'giftId'
 *   },
 * });
 */
export function useDeleteGiftMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGiftMutation, DeleteGiftMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGiftMutation, DeleteGiftMutationVariables>(DeleteGiftDocument, options);
      }
export type DeleteGiftMutationHookResult = ReturnType<typeof useDeleteGiftMutation>;
export type DeleteGiftMutationResult = Apollo.MutationResult<DeleteGiftMutation>;
export type DeleteGiftMutationOptions = Apollo.BaseMutationOptions<DeleteGiftMutation, DeleteGiftMutationVariables>;
export const AddOrEditTemplateDocument = gql`
    mutation AddOrEditTemplate($template: UpsertTemplateInput!) {
  upsertTemplate(upsertTemplateData: $template) {
    id
    name
    message
  }
}
    `;
export type AddOrEditTemplateMutationFn = Apollo.MutationFunction<AddOrEditTemplateMutation, AddOrEditTemplateMutationVariables>;

/**
 * __useAddOrEditTemplateMutation__
 *
 * To run a mutation, you first call `useAddOrEditTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddOrEditTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addOrEditTemplateMutation, { data, loading, error }] = useAddOrEditTemplateMutation({
 *   variables: {
 *      template: // value for 'template'
 *   },
 * });
 */
export function useAddOrEditTemplateMutation(baseOptions?: Apollo.MutationHookOptions<AddOrEditTemplateMutation, AddOrEditTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddOrEditTemplateMutation, AddOrEditTemplateMutationVariables>(AddOrEditTemplateDocument, options);
      }
export type AddOrEditTemplateMutationHookResult = ReturnType<typeof useAddOrEditTemplateMutation>;
export type AddOrEditTemplateMutationResult = Apollo.MutationResult<AddOrEditTemplateMutation>;
export type AddOrEditTemplateMutationOptions = Apollo.BaseMutationOptions<AddOrEditTemplateMutation, AddOrEditTemplateMutationVariables>;
export const DeleteTemplateDocument = gql`
    mutation DeleteTemplate($templateId: ID!) {
  deleteTemplateById(id: $templateId) {
    id
  }
}
    `;
export type DeleteTemplateMutationFn = Apollo.MutationFunction<DeleteTemplateMutation, DeleteTemplateMutationVariables>;

/**
 * __useDeleteTemplateMutation__
 *
 * To run a mutation, you first call `useDeleteTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteTemplateMutation, { data, loading, error }] = useDeleteTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useDeleteTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteTemplateMutation, DeleteTemplateMutationVariables>(DeleteTemplateDocument, options);
      }
export type DeleteTemplateMutationHookResult = ReturnType<typeof useDeleteTemplateMutation>;
export type DeleteTemplateMutationResult = Apollo.MutationResult<DeleteTemplateMutation>;
export type DeleteTemplateMutationOptions = Apollo.BaseMutationOptions<DeleteTemplateMutation, DeleteTemplateMutationVariables>;
export const GetUsersDocument = gql`
    query GetUsers {
  getUsers {
    uid
    email
    name
  }
}
    `;

/**
 * __useGetUsersQuery__
 *
 * To run a query within a React component, call `useGetUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUsersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUsersQuery(baseOptions?: Apollo.QueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
      }
export function useGetUsersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUsersQuery, GetUsersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUsersQuery, GetUsersQueryVariables>(GetUsersDocument, options);
        }
export type GetUsersQueryHookResult = ReturnType<typeof useGetUsersQuery>;
export type GetUsersLazyQueryHookResult = ReturnType<typeof useGetUsersLazyQuery>;
export type GetUsersQueryResult = Apollo.QueryResult<GetUsersQuery, GetUsersQueryVariables>;
export const GetUserDetailsDocument = gql`
    query GetUserDetails($uid: String!) {
  getUserDetails(uid: $uid) {
    uid
    email
    name
    firstName
    lastName
  }
  getModClientsForUser(uid: $uid) {
    id
  }
}
    `;

/**
 * __useGetUserDetailsQuery__
 *
 * To run a query within a React component, call `useGetUserDetailsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserDetailsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserDetailsQuery({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useGetUserDetailsQuery(baseOptions: Apollo.QueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
      }
export function useGetUserDetailsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserDetailsQuery, GetUserDetailsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserDetailsQuery, GetUserDetailsQueryVariables>(GetUserDetailsDocument, options);
        }
export type GetUserDetailsQueryHookResult = ReturnType<typeof useGetUserDetailsQuery>;
export type GetUserDetailsLazyQueryHookResult = ReturnType<typeof useGetUserDetailsLazyQuery>;
export type GetUserDetailsQueryResult = Apollo.QueryResult<GetUserDetailsQuery, GetUserDetailsQueryVariables>;
export const SetUserNotificationsDocument = gql`
    mutation SetUserNotifications($notify: Boolean!) {
  setUserNotifications(notify: $notify) {
    notifications
  }
}
    `;
export type SetUserNotificationsMutationFn = Apollo.MutationFunction<SetUserNotificationsMutation, SetUserNotificationsMutationVariables>;

/**
 * __useSetUserNotificationsMutation__
 *
 * To run a mutation, you first call `useSetUserNotificationsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserNotificationsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserNotificationsMutation, { data, loading, error }] = useSetUserNotificationsMutation({
 *   variables: {
 *      notify: // value for 'notify'
 *   },
 * });
 */
export function useSetUserNotificationsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserNotificationsMutation, SetUserNotificationsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserNotificationsMutation, SetUserNotificationsMutationVariables>(SetUserNotificationsDocument, options);
      }
export type SetUserNotificationsMutationHookResult = ReturnType<typeof useSetUserNotificationsMutation>;
export type SetUserNotificationsMutationResult = Apollo.MutationResult<SetUserNotificationsMutation>;
export type SetUserNotificationsMutationOptions = Apollo.BaseMutationOptions<SetUserNotificationsMutation, SetUserNotificationsMutationVariables>;
export const SetUserNotificationIntervalDocument = gql`
    mutation SetUserNotificationInterval($interval: Float!) {
  setUserNotificationInterval(interval: $interval) {
    notifications
  }
}
    `;
export type SetUserNotificationIntervalMutationFn = Apollo.MutationFunction<SetUserNotificationIntervalMutation, SetUserNotificationIntervalMutationVariables>;

/**
 * __useSetUserNotificationIntervalMutation__
 *
 * To run a mutation, you first call `useSetUserNotificationIntervalMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserNotificationIntervalMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserNotificationIntervalMutation, { data, loading, error }] = useSetUserNotificationIntervalMutation({
 *   variables: {
 *      interval: // value for 'interval'
 *   },
 * });
 */
export function useSetUserNotificationIntervalMutation(baseOptions?: Apollo.MutationHookOptions<SetUserNotificationIntervalMutation, SetUserNotificationIntervalMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserNotificationIntervalMutation, SetUserNotificationIntervalMutationVariables>(SetUserNotificationIntervalDocument, options);
      }
export type SetUserNotificationIntervalMutationHookResult = ReturnType<typeof useSetUserNotificationIntervalMutation>;
export type SetUserNotificationIntervalMutationResult = Apollo.MutationResult<SetUserNotificationIntervalMutation>;
export type SetUserNotificationIntervalMutationOptions = Apollo.BaseMutationOptions<SetUserNotificationIntervalMutation, SetUserNotificationIntervalMutationVariables>;
export const GetBillingPortalSessionDocument = gql`
    query GetBillingPortalSession {
  getPortalSession {
    url
  }
}
    `;

/**
 * __useGetBillingPortalSessionQuery__
 *
 * To run a query within a React component, call `useGetBillingPortalSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBillingPortalSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBillingPortalSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetBillingPortalSessionQuery(baseOptions?: Apollo.QueryHookOptions<GetBillingPortalSessionQuery, GetBillingPortalSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBillingPortalSessionQuery, GetBillingPortalSessionQueryVariables>(GetBillingPortalSessionDocument, options);
      }
export function useGetBillingPortalSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBillingPortalSessionQuery, GetBillingPortalSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBillingPortalSessionQuery, GetBillingPortalSessionQueryVariables>(GetBillingPortalSessionDocument, options);
        }
export type GetBillingPortalSessionQueryHookResult = ReturnType<typeof useGetBillingPortalSessionQuery>;
export type GetBillingPortalSessionLazyQueryHookResult = ReturnType<typeof useGetBillingPortalSessionLazyQuery>;
export type GetBillingPortalSessionQueryResult = Apollo.QueryResult<GetBillingPortalSessionQuery, GetBillingPortalSessionQueryVariables>;
export const DeleteUserDocument = gql`
    mutation DeleteUser($uid: String!) {
  deleteMe(uid: $uid) {
    uid
  }
}
    `;
export type DeleteUserMutationFn = Apollo.MutationFunction<DeleteUserMutation, DeleteUserMutationVariables>;

/**
 * __useDeleteUserMutation__
 *
 * To run a mutation, you first call `useDeleteUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteUserMutation, { data, loading, error }] = useDeleteUserMutation({
 *   variables: {
 *      uid: // value for 'uid'
 *   },
 * });
 */
export function useDeleteUserMutation(baseOptions?: Apollo.MutationHookOptions<DeleteUserMutation, DeleteUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteUserMutation, DeleteUserMutationVariables>(DeleteUserDocument, options);
      }
export type DeleteUserMutationHookResult = ReturnType<typeof useDeleteUserMutation>;
export type DeleteUserMutationResult = Apollo.MutationResult<DeleteUserMutation>;
export type DeleteUserMutationOptions = Apollo.BaseMutationOptions<DeleteUserMutation, DeleteUserMutationVariables>;
export const GetCheckoutSessionDocument = gql`
    query GetCheckoutSession($duration: String!, $affiliate: String) {
  getCheckoutSession(duration: $duration, affiliate: $affiliate) {
    url
  }
}
    `;

/**
 * __useGetCheckoutSessionQuery__
 *
 * To run a query within a React component, call `useGetCheckoutSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCheckoutSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCheckoutSessionQuery({
 *   variables: {
 *      duration: // value for 'duration'
 *      affiliate: // value for 'affiliate'
 *   },
 * });
 */
export function useGetCheckoutSessionQuery(baseOptions: Apollo.QueryHookOptions<GetCheckoutSessionQuery, GetCheckoutSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCheckoutSessionQuery, GetCheckoutSessionQueryVariables>(GetCheckoutSessionDocument, options);
      }
export function useGetCheckoutSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCheckoutSessionQuery, GetCheckoutSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCheckoutSessionQuery, GetCheckoutSessionQueryVariables>(GetCheckoutSessionDocument, options);
        }
export type GetCheckoutSessionQueryHookResult = ReturnType<typeof useGetCheckoutSessionQuery>;
export type GetCheckoutSessionLazyQueryHookResult = ReturnType<typeof useGetCheckoutSessionLazyQuery>;
export type GetCheckoutSessionQueryResult = Apollo.QueryResult<GetCheckoutSessionQuery, GetCheckoutSessionQueryVariables>;
export const CompleteSubscriptionDocument = gql`
    mutation CompleteSubscription($subscription: CompleteSubscriptionInput!) {
  completeSubscription(completeSubscriptionData: $subscription) {
    id
  }
}
    `;
export type CompleteSubscriptionMutationFn = Apollo.MutationFunction<CompleteSubscriptionMutation, CompleteSubscriptionMutationVariables>;

/**
 * __useCompleteSubscriptionMutation__
 *
 * To run a mutation, you first call `useCompleteSubscriptionMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteSubscriptionMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeSubscriptionMutation, { data, loading, error }] = useCompleteSubscriptionMutation({
 *   variables: {
 *      subscription: // value for 'subscription'
 *   },
 * });
 */
export function useCompleteSubscriptionMutation(baseOptions?: Apollo.MutationHookOptions<CompleteSubscriptionMutation, CompleteSubscriptionMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteSubscriptionMutation, CompleteSubscriptionMutationVariables>(CompleteSubscriptionDocument, options);
      }
export type CompleteSubscriptionMutationHookResult = ReturnType<typeof useCompleteSubscriptionMutation>;
export type CompleteSubscriptionMutationResult = Apollo.MutationResult<CompleteSubscriptionMutation>;
export type CompleteSubscriptionMutationOptions = Apollo.BaseMutationOptions<CompleteSubscriptionMutation, CompleteSubscriptionMutationVariables>;