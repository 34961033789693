import React from 'react'

import {
    IonButtons,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonToast,
} from '@ionic/react'
import { useForm } from 'react-hook-form'

import { Button, ErrorMessage, Flex } from '@components'
import { useAddOrEditGiftsMutation } from '@graphql'

import { GiftActions } from './GiftActions'
import { Gift } from './Gifts.types'
import { PopByGiftsBanner } from './PopByGiftsBanner'

export interface GiftFormInputs {
    name: string
    quantity: string | number
    description: string
    cost: string | number
}

export const GiftForm = ({
    gift,
    onSuccess,
    onCancel,
}: {
    gift?: Gift
    onSuccess: () => void
    onCancel: () => void
}) => {
    const [addOrEditGifts, { error }] = useAddOrEditGiftsMutation()
    const [showAlert] = useIonAlert()
    const [present] = useIonToast()
    const isEdit = !!gift
    const {
        handleSubmit,
        formState: { errors },
        register,
        setValue,
    } = useForm<GiftFormInputs>({
        defaultValues: {
            name: gift?.name,
            description: gift?.description,
            quantity: gift?.quantity,
            cost: gift?.cost?.toFixed(2) || undefined,
        },
    })

    const submit = async (data: GiftFormInputs) => {
        await addOrEditGifts({
            variables: {
                gift: {
                    id: gift?.id,
                    name: data.name,
                    description: data.description || undefined,
                    quantity: Number(data.quantity),
                    cost: data.cost ? Number(data.cost) : undefined,
                },
            },
        })

        if (!error) {
            await present({
                message: `Gift ${isEdit ? 'updated' : 'added'}`,
                color: 'medium',
                duration: 2500,
            })
            onSuccess()
        } else {
            await showAlert(
                'There was an error saving this gift. ' +
                    'Please try again and contact us if the problem persists.',
            )
        }
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{isEdit ? 'Edit' : 'Add'} Gift</IonTitle>
                    <IonButtons slot="start">
                        <Button onClick={onCancel}>Cancel</Button>
                    </IonButtons>
                    <IonButtons slot="end">
                        <Button onClick={handleSubmit(submit)}>{isEdit ? 'Save' : 'Add'}</Button>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <IonList lines="full">
                <IonItem>
                    <Flex mt={2} justifyContent="space-between">
                        <IonLabel position="stacked">Name*</IonLabel>
                        <ErrorMessage error={errors.name} />
                    </Flex>
                    <IonInput placeholder="Coffee Mug" {...register('name', { required: true })} />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Description</IonLabel>
                    <IonInput
                        placeholder="An example coffee mug"
                        {...register('description', { required: false })}
                    />
                </IonItem>
                <IonItem>
                    <Flex mt={2} justifyContent="space-between">
                        <IonLabel position="stacked">Quantity*</IonLabel>
                        <ErrorMessage error={errors.quantity} />
                    </Flex>
                    <IonInput
                        placeholder="0"
                        type="number"
                        inputMode="numeric"
                        min="0"
                        // Subscribe to any changes here so that native field events.
                        // (i.e. clicking the up arrow or down arrow register in useForm).
                        onIonChange={(e) => setValue('quantity', e.detail.value || '')}
                        {...register('quantity', { required: true })}
                    />
                </IonItem>
                <IonItem>
                    <IonLabel position="stacked">Cost Per Unit</IonLabel>
                    <IonInput
                        placeholder="0.00"
                        type="number"
                        inputMode="decimal"
                        min="0"
                        step="0.01"
                        // Subscribe to any changes here so that native field events.
                        // (i.e. clicking the up arrow or down arrow register in useForm).
                        onIonChange={(e) => setValue('cost', e.detail.value || '')}
                        {...register('cost', { required: false })}
                    />
                </IonItem>
            </IonList>
            {isEdit ? <GiftActions gift={gift} onSuccess={onSuccess} /> : <PopByGiftsBanner />}
        </>
    )
}
