import React from 'react'

import {
    IonButtons,
    IonContent,
    IonHeader,
    IonImg,
    IonMenu,
    IonMenuButton,
    IonMenuToggle,
    IonPage,
    IonToolbar,
    useIonAlert,
} from '@ionic/react'
import { arrowForward, close } from 'ionicons/icons'
import { Link } from 'react-router-dom'
import styled from 'styled-components'

import { Box, Button, Centered, Flex, Heading, Icon, Text } from '@components'
import { useAuth } from '@hooks'

import { FeatureList } from './FeatureList'
import { Footer } from './Footer'
import { Pricing } from './Pricing'
import { Section } from './Section'

const NavToolbar = styled(IonToolbar)`
    --ion-toolbar-background: white;
    --ion-toolbar-color: var(--ion-color-logo-primary);
    --padding-top: 0;
    --padding-bottom: 0;
    --padding-start: 0;
    --padding-end: 0;
    padding: 0 calc((100% - 1000px) / 2);

    & img {
        height: 48px;
    }
`

export const HomePage = () => {
    const [showAlert] = useIonAlert()
    const { authorized } = useAuth()

    return (
        <>
            <IonMenu contentId="main-content">
                <IonHeader>
                    <NavToolbar style={{ padding: '8px' }}>
                        <Flex alignItems="center">
                            <img src="/assets/icon/icon.png" alt="Pop-By Pro" />
                            <Text.p fontSize="large" color="brandPrimary" mb={0} mr={6} ml={3}>
                                POP-BY PRO
                            </Text.p>
                        </Flex>
                        <IonButtons slot="end">
                            <IonMenuToggle>
                                <Button variant="linkPrimary">
                                    <Icon icon={close} size={32} />
                                </Button>
                            </IonMenuToggle>
                        </IonButtons>
                    </NavToolbar>
                </IonHeader>
                <IonContent>
                    <Box p={4}>
                        <NavLink label="Overview" scrollTo="#overview" mobile />
                        <NavLink label="Features" scrollTo="#features" mobile />
                        <NavLink label="Pricing" scrollTo="#pricing" mobile />
                        <NavLink label="Support" href="mailto:support@nest.work" mobile />
                        <Flex borderTop="base" mb={3} />
                        {authorized ? (
                            <NavLink label="Account" to="/app" mobile />
                        ) : (
                            <>
                                <NavLink label="Sign in" to="/login" mobile />
                                <NavLink label="Sign up" to="/login?signup=true" mobile />
                            </>
                        )}
                    </Box>
                </IonContent>
            </IonMenu>
            <IonPage id="main-content">
                <IonHeader>
                    <NavToolbar>
                        <Flex px={[2, 2, 2, 0]} py={2} alignItems="center">
                            <Box>
                                <Flex alignItems="center">
                                    <Box>
                                        <Link to="/" style={{ textDecoration: 'none' }}>
                                            <Flex alignItems="center">
                                                <img src="/assets/icon/icon.png" alt="Pop-By Pro" />
                                                <Text.p
                                                    fontSize="large"
                                                    color="brandPrimary"
                                                    mb={0}
                                                    mr={6}
                                                    ml={3}
                                                >
                                                    POP-BY PRO
                                                </Text.p>
                                            </Flex>
                                        </Link>
                                    </Box>
                                    <Flex
                                        gap={[3, 3, 3, 5]}
                                        width="auto"
                                        display={['none', 'none', 'flex']}
                                    >
                                        <NavLink label="Overview" scrollTo="#overview" />
                                        <NavLink label="Features" scrollTo="#features" />
                                        <NavLink label="Pricing" scrollTo="#pricing" />
                                        <NavLink label="Support" href="mailto:support@nest.work" />
                                    </Flex>
                                </Flex>
                            </Box>
                            <Box ml="auto" display={['none', 'none', 'block']}>
                                {authorized ? (
                                    <Button
                                        variant="neonPrimary"
                                        borderRadius="16px"
                                        ml={3}
                                        routerLink="/app"
                                        className="ion-no-margin"
                                    >
                                        <Text.p mb={0} mr={2} fontSize="base">
                                            Account
                                        </Text.p>
                                        <Icon icon={arrowForward} size={16} />
                                    </Button>
                                ) : (
                                    <>
                                        <Button
                                            variant="linkPrimary"
                                            routerLink="/login"
                                            className="ion-no-margin"
                                        >
                                            Sign in
                                        </Button>
                                        <Button
                                            variant="neonPrimary"
                                            borderRadius="16px"
                                            ml={3}
                                            routerLink="/login?signup=true"
                                            className="ion-no-margin"
                                        >
                                            <Text.p mb={0} mr={2} fontSize="base">
                                                Sign up
                                            </Text.p>
                                            <Icon icon={arrowForward} size={16} />
                                        </Button>
                                    </>
                                )}
                            </Box>
                            <Box ml="auto" display={['block', 'block', 'none']}>
                                <IonMenuButton />
                            </Box>
                        </Flex>
                    </NavToolbar>
                </IonHeader>
                <IonContent>
                    <Flex flexDirection="column" height="100%" flexWrap="nowrap">
                        <Section
                            style={{
                                backgroundImage: 'url(/assets/home/splash_background_wide.png)',
                                backgroundSize: 'cover',
                                backgroundPosition: 'center center',
                                backgroundRepeat: 'no-repeat',
                            }}
                            color="white"
                            py={[0, 0, 7, 9]}
                            px={[0, 0, 'auto']}
                        >
                            <Flex alignItems="center">
                                <Box
                                    width={9 / 20}
                                    pr={6}
                                    display={['none', 'none', 'block']}
                                    height="100%"
                                >
                                    <Centered>
                                        <img
                                            src="/assets/home/Header_gift_image.jpg"
                                            alt="Person holding gift with tag containing Pop-By Pro logo"
                                        />
                                    </Centered>
                                </Box>
                                <Box width={[1, 1, 11 / 20]} px={[5, 6, 0]} py={[6, 7, 0]}>
                                    {/*mb={[6, 6, 0]}>*/}
                                    <Heading as="h1"fontSize={[48, 48, 48, 64]} mt={0}>
                                        Become a<br />
                                        Pop-By Pro!
                                    </Heading>
                                    <Text.p fontSize="large" mb={0}>
                                        Easy. Automated. Reliable.
                                    </Text.p>
                                    <Text.p fontSize="large" mb={4}>
                                        Pop-By Pro is the simplest way to surprise, delight, and
                                        connect with your clients.
                                    </Text.p>
                                    <Button
                                        variant="neonPrimary"
                                        borderRadius="16px"
                                        mb={3}
                                        routerLink="/login?signup=true"
                                    >
                                        <Text.p mb={0} mr={2}>
                                            Get started
                                        </Text.p>
                                        <Icon icon={arrowForward} size={20} />
                                    </Button>
                                </Box>
                                <Box width={1} display={['block', 'block', 'none']}>
                                    <Centered>
                                        <img
                                            src="/assets/home/Header_gift_image.jpg"
                                            alt="Person holding gift with tag containing Pop-By Pro logo"
                                        />
                                    </Centered>
                                </Box>
                            </Flex>
                        </Section>
                        <Section bg="light" py={0} id="overview">
                            <Flex height="100%">
                                <Box
                                    width={[1, 1, 1, 1 / 2]}
                                    pt={[7, 7, 7, 9]}
                                    pb={[5, 5, 5, 9]}
                                    my="auto"
                                >
                                    <Heading as="h2"fontWeight="900" mt={0}>
                                        Make your pop-by work for you.
                                    </Heading>
                                    <Text.p fontSize="large" mb={6}>
                                        We keep track of your gifts and clients so you can easily
                                        fit pop-bys into your schedule.
                                    </Text.p>
                                    <Flex justifyContent={['center', 'center', 'center', 'left']}>
                                        <Box
                                            as="a"
                                            href="https://apps.apple.com/us/app/pop-by-pro/id1641621318"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                        >
                                            <IonImg
                                                src="/assets/home/Download_on_the_App_Store_Badge.svg"
                                                alt="Download on the App Store"
                                                style={{ width: '150px' }}
                                            />
                                        </Box>
                                    </Flex>
                                </Box>
                                <Box width={[1, 1, 1, 1 / 2]} overflow="visible" pt={[0, 0, 0, 6]}>
                                    <Box mx="auto" display="inline">
                                        <Box
                                            height="auto"
                                            width="min(90%, 350px)"
                                            mx="auto"
                                            borderRadius="48px 48px 0 0"
                                            borderTop="10px solid black"
                                            borderX="10px solid black"
                                            overflow="hidden"
                                        >
                                            <IonImg
                                                src="/assets/home/view_client_sample_backdrop.png"
                                                alt="View of Thomas Anderson's client page on the iPhone"
                                            />
                                        </Box>
                                    </Box>
                                    <Box height={0} position="relative" top="-86%">
                                        <Box
                                            mx="auto"
                                            borderRadius={16}
                                            overflow="hidden"
                                            style={{
                                                width: 'min(100%, 400px)',
                                                boxShadow: '2px 2px 6px 0 #666666',
                                            }}
                                        >
                                            <IonImg
                                                src="/assets/home/view_client_sample.png"
                                                alt="View of Thomas Anderson's client page"
                                            />
                                        </Box>
                                    </Box>
                                </Box>
                            </Flex>
                        </Section>
                        <FeatureList />
                        <Pricing />
                        <Section bg="blues.2" color="brandPrimary">
                            <Flex justifyContent="center" alignItems="center" gap={4}>
                                <Box width={[1, 1, 1, 2 / 5]} pr={[0, 0, 0, 5]}>
                                    <Heading as="h2"fontWeight="900" mt={0}>
                                        Try Pop-By Pro today.
                                    </Heading>
                                    <Text.p fontSize="large" mb={[4, 4, 4, 0]}>
                                        Transform your client relationships and make connections
                                        that matter.
                                    </Text.p>
                                </Box>
                                <Box width={[1, 1, 1, 1 / 5]} textAlign="center">
                                    <Button
                                        variant="logoPrimary"
                                        borderRadius="16px"
                                        mb={3}
                                        routerLink="/login?signup=true"
                                    >
                                        <Text.p mb={0} mr={2}>
                                            Get Pop-By Pro today
                                        </Text.p>
                                        <Icon icon={arrowForward} size={20} />
                                    </Button>
                                    <Text.p fontSize="small">
                                        Questions?{' '}
                                        <Text.span
                                            as="a"
                                            href="mailto:support@nest.work"
                                            color="brandPrimary"
                                        >
                                            Contact us!
                                        </Text.span>
                                    </Text.p>
                                </Box>
                            </Flex>
                        </Section>
                        <Footer />
                    </Flex>
                </IonContent>
            </IonPage>
        </>
    )
}

const NavLink = ({
    label,
    to,
    href,
    scrollTo,
    mobile,
}: {
    label: string
    to?: string
    href?: string
    scrollTo?: string
    mobile?: boolean
}) => {
    if (href) {
        return (
            <Text.p
                as="a"
                display="block"
                href={href}
                style={{ textDecoration: 'none' }}
                color="brandPrimary"
                fontSize={mobile ? 'large' : undefined}
                mb={mobile ? 4 : 0}
            >
                {label}
            </Text.p>
        )
    }

    if (scrollTo) {
        const link = (
            <Text.p
                style={{ cursor: 'pointer' }}
                color="brandPrimary"
                fontSize={mobile ? 'large' : undefined}
                mb={mobile ? 4 : 0}
                onClick={(e: any) => {
                    e.preventDefault()
                    document?.querySelector(scrollTo)?.scrollIntoView({ behavior: 'smooth' })
                }}
            >
                {label}
            </Text.p>
        )

        return mobile ? <IonMenuToggle>{link}</IonMenuToggle> : link
    }

    return (
        <Link to={to!} style={{ textDecoration: 'none' }}>
            <Text.p
                color="brandPrimary"
                fontSize={mobile ? 'large' : undefined}
                mb={mobile ? 4 : 0}
            >
                {label}
            </Text.p>
        </Link>
    )
}
