import React, { useEffect } from 'react'

import { Preferences } from '@capacitor/preferences'
import { IonSpinner } from '@ionic/react'
import { Route } from 'react-router-dom'

import { Centered } from '@components'
import { SETUP_AFFILIATE, useSetup } from '@utils'

/**
 * Steps to add a new affiliate:
 *      1. Add a type in this enum.
 *      2. Add new prices in the stripe dashboard (copy existing settings).
 *          Note: add a metadata key `affiliate` mapped to the value you add below.
 *          (e.g. "affiliate": "PLANTING_POP_BYS")
 *      3. Add another `Affiliate` route below with the target AffiliateType.
 *      4. Share the link with the affiliate, and you're off to the races!
 */
enum AffiliateType {
    DIVITACREATIVE = 'DIVITACREATIVE',
    LEVEL_UP = 'LEVEL_UP',
    LEVERAGEDAGENT = 'LEVERAGEDAGENT',
    PLANTING_POP_BYS = 'PLANTING_POP_BYS',
    CBPREMIER = 'CBPREMIER',
}

/**
 * Renders a list of affiliate routes which store the affiliate key, so
 * we can properly attach it to their account when they're buying their
 * subscription.
 */
export const Affiliates = () => (
    <>
        <Route path={['/divitacreative', '/DIVITACREATIVE']} exact>
            <Affiliate affiliate={AffiliateType.DIVITACREATIVE} />
        </Route>
        <Route path="/level-up" exact>
            <Affiliate affiliate={AffiliateType.LEVEL_UP} />
        </Route>
        <Route path={['/leveragedagent', '/LEVERAGEDAGENT']} exact>
            <Affiliate affiliate={AffiliateType.LEVERAGEDAGENT} />
        </Route>
        <Route path="/planting-pop-bys" exact>
            <Affiliate affiliate={AffiliateType.PLANTING_POP_BYS} />
        </Route>
        <Route path={['/cbpremier', '/CBPREMIER']} exact>
            <Affiliate affiliate={AffiliateType.CBPREMIER} />
        </Route>
    </>
)

const Affiliate = ({ affiliate }: { affiliate: AffiliateType }) => {
    const { proceed } = useSetup()

    useEffect(() => {
        const setAffiliate = async () => {
            // Not using usePreferences here since requests will be
            // un-authed, and so we know what to look for later.
            await Preferences.set({ key: SETUP_AFFILIATE, value: affiliate })
            await proceed()
        }

        setAffiliate()
    }, [])

    return (
        <Centered>
            <IonSpinner />
        </Centered>
    )
}
