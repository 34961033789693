import React from 'react'

import { IonImg, IonThumbnail } from '@ionic/react'
import { personCircleOutline } from 'ionicons/icons'

import { Icon } from '@components'
import { useAuth } from '@hooks'

import './ProfilePicture.css'

export const ProfilePicture = ({ huge = false }: { huge?: boolean }) => {
    const { user } = useAuth()

    if (user) {
        // TODO: The response on the simulator has different keys?
        const pictureUrl = user.photoUrl || (user as { picture?: string })?.picture
        const userName = user.displayName || (user as { name?: string })?.name

        return (
            <IonThumbnail className={huge ? 'profile profile-huge' : 'profile'}>
                {pictureUrl ? (
                    <IonImg src={pictureUrl} alt={`${userName} profile`} />
                ) : (
                    <Icon icon={personCircleOutline} />
                )}
            </IonThumbnail>
        )
    }

    return (
        <IonThumbnail className={huge ? 'profile profile-huge' : 'profile'}>
            <Icon icon={personCircleOutline} />
        </IonThumbnail>
    )
}
