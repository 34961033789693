import React from 'react'

import { useIonAlert, useIonToast } from '@ionic/react'

import { Box, Button } from '@components'
import { useDeleteTemplateMutation } from '@graphql'

export const DeleteTemplateButton = ({
    templateId,
    numTemplates,
    onDelete,
}: {
    templateId: string
    numTemplates: number
    onDelete: () => void
}) => {
    const disabled = numTemplates <= 1
    const [showAlert] = useIonAlert()
    const [toast] = useIonToast()

    const [deleteTemplateMutation, { error }] = useDeleteTemplateMutation()

    const handleDelete = async () => {
        if (disabled) {
            await showAlert({
                message: 'This cannot be deleted. You must have at least one message template.',
                buttons: ['OK'],
            })
        } else {
            await showAlert({
                message: 'Are you sure you want to delete this template? This cannot be undone.',
                buttons: [
                    'Cancel',
                    {
                        text: 'Delete',
                        role: 'destructive',
                        handler: async () => await deleteTemplate(),
                    },
                ],
            })
        }
    }

    const deleteTemplate = async () => {
        await deleteTemplateMutation({ variables: { templateId } })

        if (!error) {
            await toast({
                message: 'Template Deleted',
                color: 'medium',
                duration: 2500,
            })
            onDelete()
        } else {
            await showAlert(
                'There was an error deleting this template. ' +
                    'Please try again and contact us if the problem persists.',
            )
        }
    }

    return (
        <Box p={4} textAlign="center">
            <Button variant="danger" fill="outline" onClick={handleDelete}>
                Delete Template
            </Button>
        </Box>
    )
}
