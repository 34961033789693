import { isPlatform } from '@ionic/react'

/**
 * Set this to true if you are developing the Ionic app in the browser.
 *
 * Note that capacitor functionality will be disabled and some other
 * pieces of the app may not work like they would on the actual device.
 */
const DEBUG_APP_IN_BROWSER = false

/**
 * Set this to true if you want requests to hit your local backend.
 *
 * This should never be checked in to main as `true`.
 */
export const LOCAL_DEV = DEBUG_APP_IN_BROWSER || false

// -------------------------------------------------------------------------------------------------
// Don't touch these
// -------------------------------------------------------------------------------------------------
export const SITE_URL = LOCAL_DEV ? 'http://localhost:3000' : 'https://pop-by.pro'

const isCapacitor = isPlatform('capacitor')
const isMobile = isPlatform('mobile')

export const isApp = DEBUG_APP_IN_BROWSER ? true : isCapacitor // Are we in the mobile app?
export const isAppInBrowser = isApp && DEBUG_APP_IN_BROWSER
export const isLocal = LOCAL_DEV
export const isPhone = DEBUG_APP_IN_BROWSER ? true : isCapacitor || isMobile // Are we on a phone?
export const isWebsite = !isApp // Are we on the website (not the app)
// -------------------------------------------------------------------------------------------------
// Don't touch these
// -------------------------------------------------------------------------------------------------
