import React from 'react'
import { IonSpinner } from '@ionic/react'
import { RouteComponentProps } from 'react-router'

import { Box, Centered, Content, Heading } from '@components'
import { useGetUserDetailsQuery } from '@graphql'
import { ImportClientsContent } from '../Clients/ImportClients'

interface Props
    extends RouteComponentProps<{
        uid: string
    }> {}

export const UserView = ({ match }: Props) => {
    const { data, loading, refetch } = useGetUserDetailsQuery({
        variables: { uid: match.params.uid },
    })

    const refreshClients = () => {
        refetch()
    }

    if (loading) {
        return (
            <Centered>
                <IonSpinner />
            </Centered>
        )
    }

    const name = data?.getUserDetails.name
        ? data.getUserDetails.name
        : `${data?.getUserDetails.firstName} ${data?.getUserDetails.lastName}`
    return (
        <Content>
            <Heading as="h2">User Details {name ? ` - ${name}` : ''}</Heading>
            <Box>
                <Heading as="h3">Client Count</Heading>
                <p>{data?.getModClientsForUser.length}</p>
            </Box>
            <ImportClientsContent refresh={refreshClients} uid={match.params.uid} />
        </Content>
    )
}
