import React from 'react'

import { IonPage, IonRouterOutlet } from '@ionic/react'
import { RouteComponentProps } from 'react-router'
import { Route } from 'react-router-dom'

import { isWebsite } from '@utils'

import { Clients } from './Clients'
import { ImportClients } from './ImportClients'
import { PopbyClient } from './PopbyClient'
import { ViewClient } from './ViewClient'

export const ClientsRoutes = ({ match }: RouteComponentProps) => (
    <IonPage>
        <IonRouterOutlet>
            <>
                {isWebsite && (
                    <Route path={`${match.url}/import`} exact component={ImportClients} />
                )}
                <Route path={`${match.url}/view/:clientId/popby`} exact component={PopbyClient} />
                <Route path={`${match.url}/view/:clientId`} exact component={ViewClient} />
                <Route path={`${match.url}`} exact component={Clients} />
            </>
        </IonRouterOutlet>
    </IonPage>
)
