import React from 'react'

import { IonItemDivider } from '@ionic/react'

import { Box } from '@components'

import { ClientItem } from './ClientItem'
import { Client, ClientFilter, ClientType, ClientTypes, Section } from './Clients.types'

const sortClientTypeDisplays = (a: string, b: string) => {
    if (a === ClientType.A_PLUS) {
        return -1
    } else if (b === ClientType.A_PLUS) {
        return 1
    }
    return a.localeCompare(b)
}

export const ClientSection = ({
    section,
    clients,
    filter,
}: {
    section: string
    clients: Client[]
    filter?: ClientFilter
}) => {
    if (clients.length === 0) {
        return null
    }

    let heading = section

    if (section === Section.ALL && filter!.clientTypes.length === ClientTypes.length) {
        heading = 'All Clients'
    } else if (section === Section.ALL) {
        const displayTypes = filter!.clientTypes
            .join(' ')
            .replace(/Blank/, 'Unspecified')
            .split(' ')
        displayTypes.sort(sortClientTypeDisplays)
        heading = `All ${displayTypes.join(', ')} Clients`
    }

    return (
        <>
            <IonItemDivider
                sticky
                className={section === Section.DUE ? 'section-needs-attention' : undefined}
            >
                {heading} ({clients.length})
            </IonItemDivider>
            <Box opacity={section === Section.INACTIVE || section === Section.ARCHIVED ? 0.6 : 1}>
                {clients.map((client, index) => (
                    <ClientItem
                        key={`client-${index}`}
                        client={client}
                        showDueForPopby={section === Section.ACTIVE}
                    />
                ))}
            </Box>
        </>
    )
}
