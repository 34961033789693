import React from 'react'

import { Preferences } from '@capacitor/preferences'
import { Button, Flex, Text } from '@components'

import { SettingsItem } from './SettingsItem'

export const ExportClients = () => {
    const onExportClick = async () => {
        const authToken = await Preferences.get({ key: 'authToken' })
        window.open(
            `https://pop-by.pro/api/export/clients?user=${authToken.value}`
        )
    }

    return (
        <SettingsItem>
            <Flex alignItems="center" justifyContent="space-between">
                <Text.p marginBottom={0}>Export Clients</Text.p>
                <Button onClick={onExportClick} variant="link">
                    Export
                </Button>
            </Flex>
        </SettingsItem>
    )
}
