import React, { useEffect } from 'react'

import { IonInput, IonItem, IonLabel } from '@ionic/react'
import { FieldErrors } from 'react-hook-form'
import { UseFormRegister, UseFormSetValue } from 'react-hook-form/dist/types/form'

import { Box, ErrorMessage, Flex } from '@components'
import { ClientInput } from '@graphql'
import { GOOGLE_MAPS_API_KEY } from '@utils'

const componentKeyMap: {
    [key: string]: 'long_name' | 'short_name'
} = {
    street_number: 'short_name',
    route: 'long_name', // street
    locality: 'long_name', //city
    administrative_area_level_1: 'short_name', //state
    postal_code: 'short_name', //zip
}

export const AddressFields = ({
    register,
    setValue,
    errors,
}: {
    register: UseFormRegister<ClientInput>
    setValue: UseFormSetValue<ClientInput>
    errors: FieldErrors<ClientInput>
}) => {
    useEffect(() => {
        let autocomplete: google.maps.places.Autocomplete
        let listener: null

        const handleChange = () => {
            const place = autocomplete.getPlace()
            const result: { [key: string]: string } = {}

            for (const component of place.address_components as google.maps.GeocoderAddressComponent[]) {
                const addressType = component.types[0]
                const key = componentKeyMap[addressType]
                if (key) {
                    result[addressType] = component[key]
                }
            }

            let street = result['street_number']
            if (result['route']) {
                if (street) {
                    street += ' ' + result['route']
                } else {
                    street = result['route']
                }
            }

            setValue('address', {
                street: street,
                city: result['locality'],
                state: result['administrative_area_level_1'],
                zip: result['postal_code'],
            })
        }

        const initAutocomplete = () => {
            const waitAndTryAgain = () =>
                setTimeout(() => {
                    if ('google' in window) {
                        initAutocomplete()
                    }
                }, 500)

            try {
                const field = document.querySelector('#pac-input input')

                if (!field) {
                    waitAndTryAgain()
                    return
                }

                autocomplete = new google.maps.places.Autocomplete(
                    document.querySelector('#pac-input input') as HTMLInputElement,
                    {
                        // Will need updated to support additional countries in the future.
                        componentRestrictions: { country: ['us'] },
                        fields: ['address_component'],
                        types: ['geocode'],
                    },
                )
                if (listener) {
                    google.maps.event.removeListener(listener)
                }
                autocomplete.addListener('place_changed', handleChange)
            } catch (error) {
                waitAndTryAgain()
            }
        }

        const loadGooglePlacesAPI = () => {
            if (!document.getElementById('google-places')) {
                const script = document.createElement('script')
                script.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAPS_API_KEY}&libraries=places`
                script.id = 'google-places'
                script.onload = initAutocomplete
                document.body.appendChild(script)
            } else {
                initAutocomplete()
            }
        }

        loadGooglePlacesAPI()

        return () => {
            if (listener) {
                google.maps.event.removeListener(listener)
            }
        }
    }, [setValue])

    return (
        <>
            <IonItem>
                <Flex mt={2} justifyContent="space-between">
                    <IonLabel position="stacked">Address*</IonLabel>
                    <ErrorMessage error={errors.address?.street} />
                </Flex>
                <IonInput
                    id="pac-input"
                    placeholder="1600 Pennsylvania Avenue"
                    {...register('address.street', { required: true })}
                />
            </IonItem>
            <IonItem>
                <Flex mt={2} flexWrap="nowrap" gap={3}>
                    <Box width={1 / 2}>
                        <IonLabel position="stacked">City*</IonLabel>
                        <IonInput
                            placeholder="Washington"
                            {...register('address.city', { required: true })}
                        />
                        <ErrorMessage error={errors.address?.city} />
                    </Box>
                    <Box width={1 / 4}>
                        <IonLabel position="stacked">State*</IonLabel>
                        <IonInput
                            placeholder="DC"
                            {...register('address.state', { required: true })}
                        />
                        <ErrorMessage error={errors.address?.state} />
                    </Box>
                    <Box width={1 / 4}>
                        <IonLabel position="stacked">Zip Code*</IonLabel>
                        <IonInput
                            placeholder="20500"
                            {...register('address.zip', { required: true })}
                        />
                        <ErrorMessage error={errors.address?.zip} />
                    </Box>
                </Flex>
            </IonItem>
        </>
    )
}
