import React, { useEffect, useState } from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { IonLabel, IonToggle } from '@ionic/react'

import { Flex } from '@components'
import { Client, GetClientQuery, useSetClientNotificationsMutation } from '@graphql'
import { Geo } from '@utils'

export const ClientNotificationsToggle = ({
    client,
    refetch,
}: {
    client: Client
    refetch: () => Promise<ApolloQueryResult<GetClientQuery>>
}) => {
    const [setNotifications] = useSetClientNotificationsMutation()
    const [notify, setNotify] = useState<boolean>(client.notifications)

    // A useEffect is used here instead of inlining this code into the
    // onIonChange prop below to prevent duplicate submissions.
    useEffect(() => {
        const update = async () => {
            if (Geo && notify) {
                Geo.unsnoozeClient({ clientUserId: client.id })
            } else if (Geo) {
                Geo.snoozeClient({ clientUserId: client.id })
            }

            await setNotifications({ variables: { clientId: client.id, notify } })
            await refetch()
        }

        if (notify !== client.notifications) {
            update()
        }

        // client.notifications not included in deps because we want to
        // compare against the original value here.
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [notify, refetch, client.id, setNotifications])

    return (
        <Flex justifyContent="space-between" alignItems="center">
            <IonLabel>Notifications</IonLabel>
            <IonToggle
                checked={client.notifications}
                onIonChange={(e) => setNotify(e.detail.checked)}
            />
        </Flex>
    )
}
