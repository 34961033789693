import React from 'react'

import { IonPage, IonSpinner } from '@ionic/react'

import { Centered } from '@components'
import { usePreferences } from '@hooks'
import { Geo, SETUP_GEO_PERMS, SETUP_NOTIF_PERMS, useSetup } from '@utils'

export const GeoPermissions = () => {
    const preferences = usePreferences()
    const { proceed } = useSetup()

    const handleCompletion = async () => {
        await preferences.set({ key: SETUP_GEO_PERMS, value: 'true' })
        await proceed()
    }

    if (Geo) {
        Geo.requestWhenInUsePermissions().then(handleCompletion).catch(handleCompletion)
    } else {
        handleCompletion()
    }

    return (
        <IonPage>
            <Centered>
                <IonSpinner />
            </Centered>
        </IonPage>
    )
}

export const NotificationPermissions = () => {
    const preferences = usePreferences()
    const { proceed } = useSetup()

    const handleCompletion = async () => {
        await preferences.set({ key: SETUP_NOTIF_PERMS, value: 'true' })
        await proceed()
    }

    if (Geo) {
        Geo.requestNotificationPermissions().then(handleCompletion).catch(handleCompletion)
    } else {
        handleCompletion()
    }

    return (
        <IonPage>
            <Centered>
                <IonSpinner />
            </Centered>
        </IonPage>
    )
}
