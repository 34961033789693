import React, { useState } from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { IonFab, IonFabButton, IonModal } from '@ionic/react'
import { add, cloudUpload } from 'ionicons/icons'

import { Button, Flex, Icon, Text } from '@components'
import { GetClientsQuery } from '@graphql'
import { isWebsite } from '@utils'

import { ClientForm } from '../ClientForm'

export const AddClientButton = ({
    refetch,
}: {
    refetch: () => Promise<ApolloQueryResult<GetClientsQuery>>
}) => {
    const [showModal, setShowModal] = useState<boolean>(false)

    const show = () => setShowModal(true)
    const dismiss = () => setShowModal(false)

    const onAddSuccess = async () => {
        dismiss()
        await refetch()
    }

    return (
        <>
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <Flex>
                    {isWebsite && (
                        <Button
                            variant="primary"
                            routerLink="/app/clients/import"
                            borderRadius="28px"
                            height={56}
                            m={0}
                            mr={2}
                        >
                            <Icon icon={cloudUpload} />
                            <Text.span ml={2}>Import from file</Text.span>
                        </Button>
                    )}
                    <IonFabButton color="link" onClick={show}>
                        <Icon icon={add} />
                    </IonFabButton>
                </Flex>
            </IonFab>
            <IonModal isOpen={showModal} onDidDismiss={dismiss}>
                <ClientForm onSuccess={onAddSuccess} onCancel={dismiss} />
            </IonModal>
        </>
    )
}
