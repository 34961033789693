import React, { ReactNode } from 'react'

import { Box, Flex } from '@components'

import { FlexProps } from '../../components/Design/Flex'

export const Section = ({
    children,
    ...rest
}: { children: ReactNode } & FlexProps & { style?: any; id?: string }) => (
    <Flex py={[7, 7, 7, 9]} px={[5, 6, 'auto']} {...rest} flexDirection="column">
        <Box mx="auto" width={['100%', '100%', 700, 1000]}>
            {children}
        </Box>
    </Flex>
)
