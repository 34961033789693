import React, { useEffect, useState } from 'react'

import {
    IonItem,
    IonLabel,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
} from '@ionic/react'

import { Flex, Text } from '@components'
import { usePreferences } from '@hooks'
import { Geo } from '@utils'

import { SettingsItem } from './SettingsItem'

const MILE_OPTIONS = [
    { value: '1', label: '1 mile' },
    { value: '2', label: '2 miles' },
    { value: '5', label: '5 miles' },
]

const ALL_MILE_OPTIONS = ['1', '2', '5']

const FEET_OPTIONS = [
    { value: '100', label: '100 feet' },
    { value: '200', label: '200 feet' },
    { value: '500', label: '500 feet' },
    { value: '1000', label: '1000 feet' },
]

const ALL_FEET_OPTIONS = ['100', '200', '500', '1000']

const convertFeetToMeters = (feet: number) => {
    return feet * 0.3048
}

const convertMilesToMeters = (miles: number) => {
    return miles * 1609.34
}

export const DistanceSettings = () => {
    const [distanceOptions, setDistanceOptions] = useState({ unit: 'miles', distance: '2' })
    const preferences = usePreferences()

    const updateGeo = async (unit: string, distance: string) => {
        if (Geo) {
            const dist = parseInt(distance)
            if (unit === 'miles') {
                const radius = convertMilesToMeters(dist)
                Geo.setRadius({ radius })
            } else {
                const radius = convertFeetToMeters(dist)
                Geo.setRadius({ radius })
            }
        }
    }

    const onUnitChange = async (unit: string) => {
        await preferences.set({ key: 'distanceType', value: unit })
        const updatedOptions = { unit, distance: unit === 'miles' ? '2' : '200' }
        setDistanceOptions((curDistanceOptions) => ({ ...curDistanceOptions, ...updatedOptions }))
    }

    const onDistanceChange = async (e: any) => {
        await preferences.set({ key: 'distance', value: e.detail.value })
        const distance = e.detail.value
        setDistanceOptions((curDistanceOptions) => ({ ...curDistanceOptions, distance }))
    }

    // A useEffect is used here instead of inlining this code into the
    // onIonChange prop below to prevent duplicate submissions.
    useEffect(() => {
        const getPreferences = async () => {
            const [distanceType, distance] = await Promise.all([
                preferences.get({ key: 'distanceType' }),
                preferences.get({ key: 'distance' }),
            ])

            let calcedUnit: string
            if (distanceType.value === 'miles' || !distanceType.value) {
                calcedUnit = 'miles'
            } else {
                calcedUnit = 'feet'
            }

            let calcedDistance: string
            if (calcedUnit === 'miles') {
                if (distance.value && ALL_MILE_OPTIONS.includes(distance.value)) {
                    calcedDistance = distance.value
                } else {
                    calcedDistance = '2'
                }
            } else {
                if (distance.value && ALL_FEET_OPTIONS.includes(distance.value)) {
                    calcedDistance = distance.value
                } else {
                    calcedDistance = '200'
                }
            }

            setDistanceOptions({
                unit: calcedUnit,
                distance: calcedDistance,
            })
        }

        getPreferences()
    }, [])

    useEffect(() => {
        if (
            (distanceOptions.unit === 'miles' &&
                parseInt(distanceOptions.distance) >= 1 &&
                parseInt(distanceOptions.distance) <= 5) ||
            (distanceOptions.unit === 'feet' &&
                parseInt(distanceOptions.distance) >= 100 &&
                parseInt(distanceOptions.distance) <= 1000)
        ) {
            updateGeo(distanceOptions.unit, distanceOptions.distance)
        }
    }, [distanceOptions.unit, distanceOptions.distance])

    return (
        <>
            <IonItem>
                <Flex
                    flexDirection={'row'}
                    flexWrap={'nowrap'}
                    my={3}
                    justifyContent="space-between"
                    alignItems="center"
                >
                    Distance Type
                    <IonSegment
                        style={{ maxWidth: '150px' }}
                        value={distanceOptions.unit}
                        onIonChange={(e) => {
                            const val = `${e.target.value}`
                            if (val) onUnitChange(val)
                        }}
                    >
                        <IonSegmentButton value="miles">
                            <IonLabel>Miles</IonLabel>
                        </IonSegmentButton>
                        <IonSegmentButton value="feet">
                            <IonLabel>Feet</IonLabel>
                        </IonSegmentButton>
                    </IonSegment>
                </Flex>
            </IonItem>
            <SettingsItem>
                <Flex alignItems="center" justifyContent="space-between" flexWrap="nowrap">
                    {distanceOptions.unit === 'miles' ? (
                        <IonSelect
                            interface="action-sheet"
                            label="Distance"
                            onIonChange={onDistanceChange}
                            value={distanceOptions.distance.toString()}
                        >
                            {MILE_OPTIONS.map((option) => (
                                <IonSelectOption key={option.value} value={option.value.toString()}>
                                    {option.label}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    ) : (
                        <IonSelect
                            interface="action-sheet"
                            label="Distance"
                            // onIonChange={(e) => setInterval(parseInt(e.detail.value!))}
                            onIonChange={onDistanceChange}
                            value={distanceOptions.distance.toString()}
                        >
                            {FEET_OPTIONS.map((option) => (
                                <IonSelectOption key={option.value} value={option.value.toString()}>
                                    {option.label}
                                </IonSelectOption>
                            ))}
                        </IonSelect>
                    )}
                </Flex>
            </SettingsItem>
        </>
    )
}
