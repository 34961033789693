import React from 'react'

import { IonChip, IonItem } from '@ionic/react'

import { Box, Flex, Heading, Text } from '@components'
import { getDateDisplay } from '@utils'

import { Client } from './Clients.types'

const ClientChip = ({ label, color }: { label: string; color: 'danger' | 'success' }) => (
    <Box as={IonChip} color={color} px="10px" py="6px" height="auto" fontSize="small">
        {label}
    </Box>
)

export const ClientItem = ({
    client,
    showDueForPopby,
}: {
    client: Client
    showDueForPopby?: boolean
}) => {
    // A negative distance means location services are not enabled.
    const showChips = client.notifications && !client.archived
    const showDistance = client.distance! >= 0
    const isDueForPopby = client.dueForPopby! && showDueForPopby
    // If it reads "2.0 mi" we should say it's nearby.
    const isNearby = showDistance && client.distance! <= 2.05
    const hasDisplayedChip = showChips && (isDueForPopby || isNearby)

    return (
        <IonItem detail={false} routerLink={`/app/clients/view/${client.id}`}>
            <Flex justifyContent="space-between" alignItems="center">
                <Box pt={hasDisplayedChip ? 2 : 3} pb={3}>
                    <Flex alignItems="center" mb={hasDisplayedChip ? 0 : 1}>
                        <Heading as="h6"my={0} mr={2}>
                            {client.firstName} {client.lastName}
                        </Heading>
                        {showChips && (
                            <>
                                {isDueForPopby && (
                                    <ClientChip color="danger" label="due for visit" />
                                )}
                                {isNearby && <ClientChip color="success" label="nearby" />}
                            </>
                        )}
                    </Flex>
                    <Text.p fontSize="small" color="medium" mb={0}>
                        Last Pop-By: {client.lastPopby ? getDateDisplay(client.lastPopby) : 'Never'}
                    </Text.p>
                </Box>
                {showDistance && (
                    <Box textAlign="right">
                        <Text.p my={1}>{client.distance!.toFixed(1)} mi</Text.p>
                    </Box>
                )}
            </Flex>
        </IonItem>
    )
}
