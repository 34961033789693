import React, { useEffect, useState } from 'react'

import {
    IonContent,
    IonGrid,
    IonRefresher,
    IonRefresherContent,
    IonSpinner,
    RefresherEventDetail,
} from '@ionic/react'
import { RouteComponentProps, useHistory } from 'react-router'

import { Box, Button, Centered, Filter, Heading, Page, SortDirection, Text } from '@components'
import { useAppState } from '@hooks'
import { isPhone } from '@utils'

import { AddGiftButton, GiftModal } from './AddGiftButton'
import { Gift, GiftFilter, INITIAL_FILTER, GiftSection as Section, SortMethod } from './Gifts.types'
import { matchGift } from './Gifts.utils'
import { GiftSections } from './GiftSections'
import { PopByGiftsBanner } from './PopByGiftsBanner'

export const Gifts = (props: RouteComponentProps) => (
    <Page title="Gifts">
        <IonContent>
            <GiftsContent {...props} />
        </IonContent>
    </Page>
)

export const GiftsContent = ({ match }: RouteComponentProps) => {
    const filter = useAppState().filters.gifts as GiftFilter
    const [selectedGift, setSelectedGift] = useState<Gift>()
    const {
        gifts: {
            data: { all: gifts },
            loading,
            error,
            refetch,
        },
    } = useAppState()
    const history = useHistory()

    useEffect(() => {
        // Reload gifts whenever this component is shown.
        if (history.location.pathname === match.url) {
            refetch()
        }
    }, [history.location.pathname, match.url, refetch])

    const onGiftClick = (gift: Gift) => {
        setSelectedGift(gift)
    }

    const onSave = () => {
        refetch()
        setSelectedGift(undefined)
    }

    if (loading) {
        return (
            <Centered>
                <IonSpinner />
            </Centered>
        )
    }

    if (error) {
        return (
            <Centered>
                <Text.p>There was an error fetching gifts</Text.p>
                <Button variant="primary" onClick={async () => await refetch()}>
                    Retry
                </Button>
            </Centered>
        )
    }

    const active = []
    const archived = []

    for (const gift of gifts.filter((gift) => matchGift(filter, gift))) {
        const _gift = { ...gift }

        if (_gift.archived) {
            archived.push(_gift)
        } else {
            active.push(_gift)
        }
    }

    return (
        <>
            {gifts.length === 0 ? (
                <Centered>
                    <Heading as="h4"mb={8}>You haven't added any gifts yet</Heading>
                    <PopByGiftsBanner />
                </Centered>
            ) : (
                <>
                    <Box mb={5}>
                        <IonGrid fixed={!isPhone}>
                            <IonRefresher
                                slot="fixed"
                                onIonRefresh={async (event: CustomEvent<RefresherEventDetail>) => {
                                    await refetch()
                                    event.detail.complete()
                                }}
                            >
                                <IonRefresherContent />
                            </IonRefresher>
                            <GiftFilterBar />
                            <GiftSections
                                numTotal={gifts.length}
                                filter={filter}
                                active={active}
                                archived={archived}
                                onGiftClick={onGiftClick}
                            />
                        </IonGrid>
                    </Box>
                    <PopByGiftsBanner />
                </>
            )}
            <AddGiftButton refetch={refetch} />
            <GiftModal
                gift={selectedGift}
                onClose={() => setSelectedGift(undefined)}
                onSuccess={onSave}
                showModal={!!selectedGift}
            />
        </>
    )
}

const GiftFilterBar = () => (
    <Filter
        name="gifts"
        initial={INITIAL_FILTER}
        sortMethods={[SortMethod.ALPHABETICAL, SortMethod.INVENTORY, SortMethod.COST]}
        sortMethodSections={{
            [SortMethod.ALPHABETICAL]: [Section.ACTIVE, Section.ARCHIVED, Section.ALL],
            [SortMethod.INVENTORY]: [Section.ACTIVE, Section.ARCHIVED, Section.ALL],
            [SortMethod.COST]: [Section.ACTIVE, Section.ARCHIVED, Section.ALL],
        }}
        sortDirectionDescriptions={{
            [SortMethod.ALPHABETICAL]: {
                [SortDirection.ASC]: 'A-Z',
                [SortDirection.DESC]: 'Z-A',
            },
            [SortMethod.INVENTORY]: {
                [SortDirection.ASC]: 'Most stocked gifts first',
                [SortDirection.DESC]: 'Least stocked gifts first',
            },
            [SortMethod.COST]: {
                [SortDirection.ASC]: 'Least expensive gifts first',
                [SortDirection.DESC]: 'Most expensive gifts first',
            },
        }}
    />
)
