import React, { useEffect } from 'react'

import { Style } from '@capacitor/status-bar'
import { IonPage, IonSpinner, useIonAlert } from '@ionic/react'

import { Centered, Content } from '@components'
import { useCompleteSubscriptionMutation } from '@graphql'
import { useAuth, usePreferences, useStatusBarTheme } from '@hooks'
import { SETUP_SUBSCRIBED_STRIPE, useQuery, useSetup } from '@utils'

export const SubscriptionSuccess = () => {
    useStatusBarTheme(Style.Light) // No toolbar on this page.
    const [showAlert] = useIonAlert()
    const urlSearchParams = useQuery()
    const [complete] = useCompleteSubscriptionMutation()
    const { authorized } = useAuth()
    const preferences = usePreferences()
    const { proceed } = useSetup()

    useEffect(() => {
        const handleError = () =>
            showAlert({
                header: 'Error Subscribing',
                message:
                    'We were unable to process your request. ' +
                    'Please refresh the page and try again.',
                onDidDismiss: () => proceed(),
                buttons: ['Refresh'],
            })

        const completeSubscription = async (userId: string, checkoutSessionId: string) => {
            try {
                await complete({ variables: { subscription: { userId, checkoutSessionId } } })
                await preferences.set({ key: SETUP_SUBSCRIBED_STRIPE, value: '1' })
                await proceed()
            } catch (e) {
                console.error('Unable to complete subscription: ', e)
                await handleError()
            }
        }

        const userId = urlSearchParams.get('userId')
        const checkoutSessionId = urlSearchParams.get('session_id')

        if (authorized) {
            if (userId && checkoutSessionId) {
                // Make request to server to update subscription
                completeSubscription(userId, checkoutSessionId)
            } else {
                handleError()
            }
        }
    }, [authorized, complete, urlSearchParams])

    return (
        <IonPage>
            <Content>
                <Centered>
                    <IonSpinner />
                </Centered>
            </Content>
        </IonPage>
    )
}
