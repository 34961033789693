import { useMemo } from 'react'

import { History } from 'history'
import { useHistory } from 'react-router'
import { useLocation } from 'react-router-dom'
import 'cordova-plugin-purchase'

import { PopByUser, useAuth, usePreferences, UsePreferences } from '@hooks'

import { isWebsite } from './platform'
import { Preferences } from '@capacitor/preferences'

export const SETUP_AFFILIATE = 'setup.affiliate'
export const SETUP_INTRO = 'setup.intro'
export const SETUP_BASIC_INFO = 'setup.basicInfoCollected'
export const SETUP_SUBSCRIBED = 'setup.subscribed'
export const SETUP_SUBSCRIBED_STRIPE = 'setup.subscribedStripe'
export const SETUP_NEXT_STEPS = 'setup.nextSteps'
export const SETUP_GEO_PERMS = 'setup.geoPermissionsGranted'
export const SETUP_GEO_ALWAYS_PERMS = 'setup.geoAlwaysPermissions'
export const SETUP_NOTIF_PERMS = 'setup.notifPermissions'

const STEP_INTRO = '/setup/intro'
const STEP_BASIC_INFO = '/setup/basic_info'
const STEP_SUBSCRIBE = '/setup/subscribe'
const STEP_UNSUBSCRIBE = '/setup/unsubscribe'
const STEP_NEXT_STEPS = '/setup/next_steps'
const STEP_NOTIF = '/setup/notif'
const STEP_GEO = '/setup/geo'

const STEPS = [STEP_INTRO, STEP_BASIC_INFO, STEP_SUBSCRIBE, STEP_NEXT_STEPS, STEP_NOTIF, STEP_GEO]

export const checkValue = async (preferences: UsePreferences, key: string) =>
    (await preferences.get({ key })).value

const checkSubscribed = async (store: CdvPurchase.Store, user: PopByUser | undefined) => {
    // Loop through the products and make sure we're subscribed
    let owned = false
    for (const product of store.products) {
        if (product.owned) {
            owned = true
            break
        }
    }

    const nativeOwned = (await Preferences.get({ key: SETUP_SUBSCRIBED })).value
    const stripeOwned = (await Preferences.get({ key: SETUP_SUBSCRIBED_STRIPE })).value

    const subscribed = owned || stripeOwned || nativeOwned || (user && user.subscribed)
    console.log('routing - Subscribed:', subscribed)

    return owned || stripeOwned || nativeOwned || (user && user.subscribed)
}

const checkAllSetupSteps = async (preferences: UsePreferences, user: PopByUser | undefined) =>
    Promise.all([
        checkValue(preferences, SETUP_INTRO),
        checkValue(preferences, SETUP_BASIC_INFO),
        checkValue(preferences, SETUP_NEXT_STEPS),
        checkValue(preferences, SETUP_GEO_PERMS),
        checkValue(preferences, SETUP_NOTIF_PERMS),
    ])

export const isSetupComplete = async (preferences: UsePreferences, user: PopByUser | undefined) => {
    const [
        introViewed,
        basicInfoCollected,
        nextStepsViewed,
        geoPermissionsGranted,
        notifPermissions,
    ] = await checkAllSetupSteps(preferences, user)

    return (
        introViewed &&
        basicInfoCollected &&
        nextStepsViewed &&
        (isWebsite || (notifPermissions && geoPermissionsGranted))
    )
}

const navigateToNextStep = async (
    history: History,
    preferences: UsePreferences,
    url: string | undefined,
    user: PopByUser | undefined,
) => {
    const [
        introViewed,
        basicInfoCollected,
        nextStepsViewed,
        geoPermissionsGranted,
        notifPermissions,
    ] = await checkAllSetupSteps(preferences, user)
    const current = history.location.pathname
    let next: string | null

    if (url && current.startsWith('/subscribe')) {
        return
    }

    if (url && STEPS.includes(url)) {
        // If setup is needed we will handle that below...otherwise,
        // don't let users come back to these routes.
        url = undefined
    }

    if (!introViewed) {
        next = STEP_INTRO
    } else if (!basicInfoCollected) {
        next = STEP_BASIC_INFO
    } else if (!nextStepsViewed) {
        next = STEP_NEXT_STEPS
    } else if (!isWebsite && !notifPermissions) {
        next = STEP_NOTIF
    } else if (!isWebsite && !geoPermissionsGranted) {
        next = STEP_GEO
    } else if (current.startsWith('/app')) {
        next = url || null
    } else {
        // All setup steps are done -- proceed to app.
        next = url || '/app'
    }

    if (next && current !== next) {
        try {
            history.push(next)
        } catch (e) {
            console.error(e)
        }
    }
}

export const useSetup = () => {
    const preferences = usePreferences()
    const history = useHistory()
    const { user } = useAuth()

    const { store } = CdvPurchase
    if (store && store.isReady) {
        store.when().finished(async () => {
            const owned = await checkSubscribed(store, user)

            if (owned) {
                navigateToNextStep(history, preferences, undefined, user)
            }
        })
    }

    return { proceed: (url?: string) => navigateToNextStep(history, preferences, url, user) }
}

export const useQuery = () => {
    const { search } = useLocation()

    return useMemo(() => new URLSearchParams(search), [search])
}
