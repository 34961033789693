import React from 'react'

import { IonItem } from '@ionic/react'

import { Box, Heading, Text } from '@components'
import { Template } from '@graphql'

const MAX_DISPLAYED_CHARS = 125

export const TemplateCard = ({
    template,
    onClick,
}: {
    template: Template
    onClick: (template: Template) => void
}) => (
    <IonItem button onClick={() => onClick(template)}>
        <Box py={3}>
            <Heading as="h6"my={0} mb={1}>
                {template.name}
            </Heading>
            <Text.p fontSize="small" color="medium" mb={0} style={{ whiteSpace: 'pre-wrap' }}>
                {template.message.length > MAX_DISPLAYED_CHARS
                    ? `${template.message.substring(0, MAX_DISPLAYED_CHARS).trim()}...`
                    : template.message}
            </Text.p>
        </Box>
    </IonItem>
)
