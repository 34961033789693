import { useEffect } from 'react'

import { Style } from '@capacitor/status-bar'
import { setStatusBarStyle } from '@theme'

export const useStatusBarTheme = (style: Style) => {
    useEffect(() => {
        setStatusBarStyle(style)

        return () => {
            setStatusBarStyle(Style.Dark)
        }
    }, [])
}
