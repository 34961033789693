import React, { useState } from 'react'

import {
    IonAccordion,
    IonAccordionGroup,
    IonButtons,
    IonDatetime,
    IonDatetimeButton,
    IonHeader,
    IonInput,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonModal,
    IonSelect,
    IonSelectOption,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonPopover,
    useIonToast,
} from '@ionic/react'
import { FieldError, useForm, UseFormRegisterReturn } from 'react-hook-form'

import { Box, Button, Content, ErrorMessage, Flex, PhoneField, Text } from '@components'
import { Client, ClientInput, useAddOrEditClientMutation } from '@graphql'
import { EMAIL_REGEX, Geo, isApp } from '@utils'

import { AddressFields } from './AddressFields'

export const ClientForm = ({
    client,
    onSuccess,
    onCancel,
}: {
    client?: Client
    onSuccess: ((clientId?: string) => Promise<void>) | ((clientId?: string) => void)
    onCancel: () => void
}) => {
    const [addOrEditClientMutation, { loading }] = useAddOrEditClientMutation()
    const [showAlert] = useIonAlert()
    const [toast] = useIonToast()
    const isEdit = !!client
    const { handleSubmit, formState, setValue, register, getValues } = useForm<ClientInput>({
        defaultValues: {
            id: client?.id,
            firstName: client?.firstName,
            lastName: client?.lastName,
            phone: client?.phone,
            spouseFirstName: client?.spouseFirstName,
            spouseLastName: client?.spouseLastName,
            spousePhone: client?.spousePhone,
            email: client?.email,
            type: client?.type,
            address: {
                street: client?.address?.street,
                city: client?.address?.city,
                state: client?.address?.state,
                zip: client?.address?.zip,
            },
            notes: client?.notes,
            sales: client?.sales || 0,
            birthday: client?.birthday,
            agentName: client?.agentName,
            agentPhone: client?.agentPhone,
            company: client?.company,
        },
    })

    const { min, max, ...birthdayProps } = register('birthday')
    const selectedBirthday = getValues('birthday')
    // This is tracked to update the IonDatetimeButton to provide a better user experience.
    const [birthday, setBirthday] = useState<string | null>(selectedBirthday)

    const submit = async (data: ClientInput) => {
        try {
            if (data.sales) {
                const stripped = `${data.sales}`.replace(/\D/g, '')
                data.sales = parseFloat(stripped) || null
            }
            if (!data.birthday) {
                data.birthday = undefined
            }
            const resp = await addOrEditClientMutation({ variables: { client: data } })

            if (resp.data) {
                await toast({
                    message: `Client ${isEdit ? 'updated' : 'added'}`,
                    color: 'medium',
                    duration: 2500,
                })

                const respData = resp.data
                const clientUserId = resp.data?.upsertClient.id

                // Update the iOS app
                if (Geo && clientUserId && (client || respData)) {
                    const updatedClient = {
                        clientUserId: clientUserId,
                        latitude: client
                            ? client.address.coordinates.lat
                            : respData!.upsertClient.address.coordinates.lat,
                        longitude: client
                            ? client.address.coordinates.lng
                            : respData!.upsertClient.address.coordinates.lng,
                    }

                    const result = await Geo.addClient(updatedClient)
                    console.log('Upserted client in Swift. Added = ', result)
                }

                await onSuccess(clientUserId)
            } else {
                console.error('Got a non-exception error when adding client: ', resp)
                await showAlert(
                    'There was an error saving this client. ' +
                        'Please try again and contact us if the problem persists.',
                )
            }
        } catch (e) {
            console.error('Exception occurred while saving client: ', e)
            await showAlert(
                'There was an error saving this client. ' +
                    'Please try again and contact us if the problem persists.',
            )
        }
    }

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{isEdit ? 'Edit' : 'Add'} Client</IonTitle>
                    <IonButtons slot="start">
                        <Button onClick={onCancel}>Cancel</Button>
                    </IonButtons>
                    <IonButtons slot="end">
                        <Button disabled={loading} onClick={handleSubmit(submit)}>
                            {isEdit ? 'Save' : 'Add'}
                        </Button>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <Content>
                {isApp && (
                    <Box
                        textAlign="center"
                        fontSize="small"
                        bg="greens.2"
                        color="brandPrimary"
                        p={2}
                    >
                        Log into https://pop-by.pro from your computer
                        <br />
                        to add/edit client records in bulk.
                    </Box>
                )}
                <IonList lines="full">
                    <IonItem>
                        <Flex mt={2} flexWrap="nowrap" gap={3}>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">First Name*</IonLabel>
                                    <ErrorMessage error={formState.errors.firstName} />
                                </Flex>
                                <IonInput
                                    placeholder="John"
                                    autoCapitalize="words"
                                    {...register('firstName', { required: true })}
                                />
                            </Box>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">Last Name*</IonLabel>
                                    <ErrorMessage error={formState.errors.lastName} />
                                </Flex>
                                <IonInput
                                    placeholder="Doe"
                                    autoCapitalize="words"
                                    {...register('lastName', { required: true })}
                                />
                            </Box>
                        </Flex>
                    </IonItem>

                    <IonItem>
                        <Flex mt={2} flexWrap="nowrap" gap={3}>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">Spouse First Name</IonLabel>
                                    <ErrorMessage error={formState.errors.spouseFirstName} />
                                </Flex>
                                <IonInput
                                    placeholder="Jane"
                                    autoCapitalize="words"
                                    {...register('spouseFirstName')}
                                />
                            </Box>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">Spouse Last Name</IonLabel>
                                    <ErrorMessage error={formState.errors.spouseLastName} />
                                </Flex>
                                <IonInput
                                    placeholder="Doe"
                                    autoCapitalize="words"
                                    {...register('spouseLastName')}
                                />
                            </Box>
                        </Flex>
                    </IonItem>
                    <IonItem>
                        <Flex mt={2} flexWrap="nowrap" gap={3}>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">Phone</IonLabel>
                                    <ErrorMessage error={formState.errors.phone} />
                                </Flex>
                                <PhoneField
                                    register={register}
                                    setValue={setValue}
                                    required={false}
                                />
                            </Box>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">Spouse Phone</IonLabel>
                                    <ErrorMessage error={formState.errors.spousePhone} />
                                </Flex>
                                <PhoneField
                                    name="spousePhone"
                                    register={register}
                                    setValue={setValue}
                                    required={false}
                                />
                            </Box>
                        </Flex>
                    </IonItem>
                    <IonItem>
                        <Flex mt={2} flexWrap="nowrap" gap={3}>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">Email</IonLabel>
                                    <ErrorMessage error={formState.errors.email} />
                                </Flex>
                                <IonInput
                                    type="email"
                                    inputMode="email"
                                    placeholder="client@example.com"
                                    {...register('email', { pattern: EMAIL_REGEX })}
                                />
                            </Box>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">Company</IonLabel>
                                    <ErrorMessage error={formState.errors.company} />
                                </Flex>
                                <IonInput placeholder="Company, LLC" {...register('company')} />
                            </Box>
                        </Flex>
                    </IonItem>
                    <IonItem>
                        <IonLabel>Client Type</IonLabel>
                        <IonSelect
                            interface="action-sheet"
                            placeholder="Choose..."
                            {...register('type')}
                        >
                            <IonSelectOption value="">None</IonSelectOption>
                            <IonSelectOption value="Veteran">Veteran</IonSelectOption>
                            <IonSelectOption value="A+">A+</IonSelectOption>
                            <IonSelectOption value="A">A</IonSelectOption>
                            <IonSelectOption value="B">B</IonSelectOption>
                            <IonSelectOption value="C">C</IonSelectOption>
                        </IonSelect>
                        <ErrorMessage error={formState.errors.type} />
                    </IonItem>
                    <IonItemDivider>Address</IonItemDivider>
                    <AddressFields
                        register={register}
                        setValue={setValue}
                        errors={formState.errors}
                    />
                    <IonItemDivider>Other</IonItemDivider>
                    <IonItem>
                        <Flex flexDirection="column">
                            <Flex
                                my={2}
                                justifyContent="space-between"
                                flexWrap="nowrap"
                                alignItems="center"
                            >
                                <Flex>
                                    <IonLabel>Birthday</IonLabel>
                                    <ErrorMessage error={formState.errors.birthday as FieldError} />
                                </Flex>
                                <Flex justifyContent="flex-end">
                                    <IonDatetimeButton datetime="birthday">
                                        <span slot="date-target">
                                            {!birthday
                                                ? 'Select'
                                                : new Date(birthday).toLocaleString('en-US', {
                                                      month: 'long',
                                                      day: 'numeric',
                                                  })}
                                        </span>
                                    </IonDatetimeButton>
                                </Flex>
                            </Flex>
                            <Text.p fontSize="small" color="medium" mb={2}>
                                If this value is set, we'll remind you a day before to give them a
                                pop-by.
                            </Text.p>
                        </Flex>

                        <IonModal keepContentsMounted={true} style={{ border: '1px solid black' }}>
                            <IonDatetime
                                id="birthday"
                                onIonBlur={birthdayProps.onBlur}
                                onIonChange={(evt) => {
                                    const value = evt.detail.value || null
                                    setBirthday(value as string | null)
                                    setValue('birthday', value)
                                }}
                                max={new Date().toISOString()}
                                preferWheel
                                presentation="date"
                                showClearButton
                                showDefaultButtons
                                showDefaultTitle
                                style={{
                                    border: '1px solid gray',
                                    borderRadius: '12px',
                                }}
                                {...birthdayProps}
                            >
                                <span slot="title">Enter your client's birthday</span>
                            </IonDatetime>
                        </IonModal>
                    </IonItem>

                    <IonItem>
                        <Flex mt={2} flexWrap="nowrap" gap={3}>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">Agent Name</IonLabel>
                                    <ErrorMessage error={formState.errors.agentName} />
                                </Flex>
                                <IonInput
                                    autoCapitalize="words"
                                    placeholder="James Bee"
                                    {...register('agentName')}
                                />
                            </Box>
                            <Box width={1 / 2}>
                                <Flex mt={1} justifyContent="space-between">
                                    <IonLabel position="stacked">Agent Phone</IonLabel>
                                    <ErrorMessage error={formState.errors.agentPhone} />
                                </Flex>
                                <PhoneField
                                    name="agentPhone"
                                    register={register}
                                    setValue={setValue}
                                    required={false}
                                />
                            </Box>
                        </Flex>
                    </IonItem>

                    <IonItem>
                        <Flex mt={2} justifyContent="space-between">
                            <IonLabel position="stacked">Total Sales</IonLabel>
                            <ErrorMessage error={formState.errors.sales} />
                        </Flex>
                        <IonInput inputMode="numeric" {...register('sales')} />
                    </IonItem>
                    <IonItem className="ion-align-items-start">
                        <IonLabel position="stacked">Notes</IonLabel>
                        <IonTextarea {...register('notes')} rows={6} />
                        <ErrorMessage error={formState.errors.notes} />
                    </IonItem>
                </IonList>
            </Content>
        </>
    )
}
