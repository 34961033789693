import React, { useState } from 'react'

import { IonSpinner, IonTextarea, useIonAlert } from '@ionic/react'
import { useForm } from 'react-hook-form'

import { Button, Centered, Flex, Text } from '@components'
import { useUpdateUserMutation } from '@graphql'
import { useAppState } from '@hooks'

import { SettingsItem } from './SettingsItem'

interface SignatureInput {
    signature: string
}

export const SignatureSetting = () => {
    const [update, { loading: saving, error }] = useUpdateUserMutation()
    const [editing, setEditing] = useState(false)
    const { user } = useAppState()
    const signature = user.data!.signature || ''
    const { handleSubmit, register } = useForm<SignatureInput>({ defaultValues: { signature } })
    const [showAlert] = useIonAlert()

    const saveSignature = async (data: SignatureInput) => {
        await update({ variables: { updates: data } })

        if (!error) {
            await user.refetch()
            setEditing(false)
        } else {
            await showAlert(
                'There was an error saving your signature. ' +
                    'Please try again and contact us if the problem persists.',
            )
        }
    }

    if (saving) {
        return (
            <SettingsItem>
                <Centered>
                    <IonSpinner />
                </Centered>
            </SettingsItem>
        )
    }

    return (
        <SettingsItem>
            <Flex alignItems="center" justifyContent="space-between" flexWrap="nowrap">
                <Text.p mb={0}>Message Signature</Text.p>
                {editing ? (
                    <Button variant="primary" onClick={handleSubmit(saveSignature)}>
                        Save
                    </Button>
                ) : (
                    <Button variant="link" onClick={() => setEditing(true)}>
                        Edit
                    </Button>
                )}
            </Flex>
            {editing ? (
                <IonTextarea placeholder="Start typing..." {...register('signature')} rows={2} />
            ) : (
                <Text.p
                    fontSize="small"
                    lineHeight="1.5em"
                    fontStyle="italic"
                    color="medium"
                    style={{ whiteSpace: 'pre-wrap' }}
                    mb={0}
                >
                    {signature || 'Nothing here...'}
                </Text.p>
            )}
        </SettingsItem>
    )
}
