import React from 'react'

import { IonSelect, IonSelectOption } from '@ionic/react'
import { Chart, registerables } from 'chart.js'
import 'chartjs-adapter-date-fns'
import { format, parse } from 'date-fns'
import { caretDown } from 'ionicons/icons'
import { Bar } from 'react-chartjs-2'

import { Box, Flex, Icon, Text } from '@components'
import { useAppState, useAppTheme } from '@hooks'

import { ClientType } from '../../Clients/Clients/Clients.types'
import { DashboardFilter, Popby, Scale } from '../Dashboard.types'
import { Highlights } from './Highlights'

Chart.register(...registerables)

interface PopbyTimeMapping {
    [key: string]: number
}

const scaleFormats = {
    [Scale.DAY]: 'yyyy-MM-dd',
    [Scale.WEEK]: 'yyyy-I',
    [Scale.MONTH]: 'yyyy-MM',
    [Scale.QUARTER]: 'yyyy-QQ',
    [Scale.YEAR]: 'yyyy',
}

const parsePopbys = (scale: Scale, popbys: Popby[]) => {
    const getKey = (ts: Date) => format(ts, scaleFormats[scale])
    const parseKey = (key: string) => {
        if (scale === Scale.WEEK) {
            const [year, week] = key.split('-')

            const parsed = parse(week, 'I', new Date())
            parsed.setFullYear(parseInt(year))
            return parsed
        }

        return parse(key, scaleFormats[scale], new Date())
    }
    const labels: Date[] = []
    const data: number[] = []

    // First, group all the popbys into buckets matching the scale.
    const mapping = popbys.reduce((acc: PopbyTimeMapping, popby: Popby) => {
        const key = getKey(new Date(popby.timestamp))

        if (acc.hasOwnProperty(key)) {
            acc[key] += 1
        } else {
            acc[key] = 1
        }

        return acc
    }, {})

    // Lastly, prep labels/data to send to the chart.
    Object.entries(mapping)
        .sort((a, b) => (a[0] < b[0] ? -1 : 1))
        .forEach(([key, value]) => {
            labels.push(parseKey(key))
            data.push(value)
        })

    return { labels, data }
}

// This is similar to the comparer in Dashboard.tsx except it doesn't
// consider the search text.
const matchPopby = (filter: DashboardFilter, popby: Popby) => {
    if (
        (popby.client.type && !filter.clientTypes.includes(popby.client.type as ClientType)) ||
        !filter.clientTypes.includes(ClientType.NONE)
    ) {
        return false
    }

    return true
}

export const PopbyStats = ({ popbys }: { popbys: Popby[] }) => {
    const { filters, setFilter } = useAppState()
    const filter = filters.dashboard
    const scale = filter.method
    const { isLightTheme } = useAppTheme()
    const displayedPopbys = popbys.filter((popby) => matchPopby(filter, popby))
    const { labels, data } = parsePopbys(scale, displayedPopbys)

    return (
        <Box p={3} borderBottom="base">
            <Box position="relative" top={-16} right={-12} height={0} ml="auto" width={125}>
                <IonSelect
                    value={scale}
                    onIonChange={(e) =>
                        setFilter('dashboard', (prev: DashboardFilter) => ({
                            ...prev,
                            method: e.detail.value as Scale,
                        }))
                    }
                >
                    <IonSelectOption value={Scale.DAY}>Daily</IonSelectOption>
                    <IonSelectOption value={Scale.WEEK}>Weekly</IonSelectOption>
                    <IonSelectOption value={Scale.MONTH}>Monthly</IonSelectOption>
                    <IonSelectOption value={Scale.QUARTER}>Quarterly</IonSelectOption>
                    <IonSelectOption value={Scale.YEAR}>Yearly</IonSelectOption>
                </IonSelect>
            </Box>
            <Highlights popbys={displayedPopbys} scale={scale} />
            <Bar
                data={{
                    labels,
                    datasets: [
                        {
                            // TODO: need to figure out how to pass through ionic variables to charts
                            backgroundColor: isLightTheme ? '#1ebbff' : '#428cff',
                            borderColor: isLightTheme ? '#35c2ff' : '#5598ff',
                            data,
                        },
                    ],
                }}
                options={{
                    plugins: { legend: { display: false } },
                    scales: {
                        y: {
                            ticks: {
                                // Only show whole numbers
                                callback: (value) => (Number(value) % 1 === 0 ? value : ''),
                            },
                        },
                        x: {
                            type: 'time',
                            time: { unit: scale === Scale.DAY ? Scale.WEEK : scale },
                            ticks: { maxRotation: 0 },
                        },
                    },
                }}
            />
        </Box>
    )
}
