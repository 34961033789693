import React from 'react'

export const ClientNotes = ({ notes }: { notes: string }) => (
    <ul className="ion-no-margin" style={{ listStyle: 'disclosure-closed' }}>
        {notes
            .split('\n')
            .filter((val) => val !== '')
            .map((val, idx) => (
                <li key={idx}>{val}</li>
            ))}
    </ul>
)
