import React, { Fragment, useEffect, useState } from 'react'

import {
    IonContent,
    IonFab,
    IonFabButton,
    IonGrid,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonList,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonSpinner,
    RefresherEventDetail,
} from '@ionic/react'
import { navigate } from 'ionicons/icons'
import { RouteComponentProps, useHistory } from 'react-router'

import { Box, Button, Centered, Heading, Page, Text } from '@components'
import { SortDirection } from '@components'
import { useGetPopbysQuery } from '@graphql'
import { useAppState } from '@hooks'
import { getDateDisplay, getTimeDisplay, isPhone } from '@utils'

import { ClientType } from '../Clients/Clients/Clients.types'
import { DashboardFilter, INITIAL_FILTER, Popby } from './Dashboard.types'
import { DashboardFilterBar } from './DashboardFilterBar'
import { PopbyStats } from './Stats'

/**
 * This should match any of the visible text on the list item; we
 * recalculate date/time here (in addition to checking other fields) so
 * the user can search on what they can see.
 */
const matchPopby = (filter: DashboardFilter, popby: Popby) => {
    if (
        (popby.client.type && !filter.clientTypes.includes(popby.client.type as ClientType)) ||
        !filter.clientTypes.includes(ClientType.NONE)
    ) {
        return false
    }

    if (
        !filter.agents.includes('All') &&
        (!popby.client.agentName || !filter.agents.includes(popby.client.agentName))
    ) {
        return false
    }

    const _filter = filter.search.toLowerCase()
    const check = (value: string) => value.toLowerCase().includes(_filter)

    return (
        check(popby.giftName) ||
        check(`${popby.client.firstName} ${popby.client.lastName}`) ||
        check(popby.client.firstName) ||
        check(popby.client.lastName) ||
        check(getDateDisplay(popby.timestamp)) ||
        check(getTimeDisplay(popby.timestamp))
    )
}

export const Dashboard = (props: RouteComponentProps) => {
    const { globalHistory } = useAppState()

    return (
        <Page title="Pop-By Pro">
            <IonContent>
                <PopbyContent {...props} />
            </IonContent>
            {isPhone && (
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton color="link" onClick={() => globalHistory.push('/app/clients')}>
                        <Box
                            as={IonIcon}
                            icon={navigate}
                            position="relative"
                            top="2px"
                            right="2px"
                        />
                    </IonFabButton>
                </IonFab>
            )}
        </Page>
    )
}

interface DateGrouping {
    [key: string]: Popby[]
}

const PopbyContent = ({ match }: RouteComponentProps) => {
    const history = useHistory()
    const { filters } = useAppState()
    const filter = filters.dashboard as DashboardFilter
    const { loading, data, error, refetch } = useGetPopbysQuery()
    const popbys = [...(data?.getPopbysForUser || [])]

    if (filter.direction === SortDirection.DESC) {
        popbys.reverse()
    }

    useEffect(() => {
        // Reload popbys whenever this component is shown.
        if (history.location.pathname === match.url) {
            refetch()
        }
    }, [history.location.pathname, match.url, refetch])

    if (loading) {
        return (
            <Centered>
                <IonSpinner />
            </Centered>
        )
    }

    if (error) {
        return (
            <Centered>
                <Text.p>Error fetching pop-by history</Text.p>
                <Button variant="primary" onClick={async () => await refetch()}>
                    Retry
                </Button>
            </Centered>
        )
    }

    if (!loading && popbys.length === 0) {
        return (
            <Centered>
                <Heading as="h4">You haven't completed any pop-bys yet</Heading>
            </Centered>
        )
    }

    let numDisplayedResults = 0

    const groupedByDate = popbys.reduce((acc: DateGrouping, popby: Popby) => {
        // Filter out records that don't match. Do it in this
        // reducer, so we don't need to iterate twice.
        if (!matchPopby(filter, popby)) {
            return acc
        }

        const key = getDateDisplay(popby.timestamp)
        numDisplayedResults += 1

        if (acc.hasOwnProperty(key)) {
            acc[key].push(popby)
        } else {
            acc[key] = [popby]
        }

        return acc
    }, {})

    const agents = new Set<string>()
    popbys.map((popby) => (popby.client.agentName ? agents.add(popby.client.agentName) : null))
    const numTotal = popbys.length

    return (
        <IonGrid fixed={!isPhone}>
            <IonRefresher
                slot="fixed"
                onIonRefresh={async (event: CustomEvent<RefresherEventDetail>) => {
                    await refetch()
                    event.detail.complete()
                }}
            >
                <IonRefresherContent />
            </IonRefresher>
            <PopbyStats popbys={popbys} />
            <DashboardFilterBar agents={Array.from<string>(agents)} />
            {numDisplayedResults !== numTotal && (
                <Text.p p={2} pb={0} fontSize="small" textAlign="center">
                    Showing <strong>{numDisplayedResults}</strong> matching pop-by
                    {numDisplayedResults === 1 ? '' : 's'} out of {numTotal} total.
                </Text.p>
            )}
            <IonList lines="full">
                {Object.entries(groupedByDate).map(([dt, events]) => (
                    <Fragment key={dt}>
                        <IonItemDivider sticky>{dt}</IonItemDivider>
                        {events.map((popby: Popby, index: number) => (
                            <PopbyItem key={`popby-${index}`} popby={popby} />
                        ))}
                    </Fragment>
                ))}
                {Object.entries(groupedByDate).length === 0 ? (
                    <Text.p textAlign="center" p={4} mt={4}>
                        No pop-bys match your search criteria.
                    </Text.p>
                ) : null}
            </IonList>
        </IonGrid>
    )
}

const PopbyItem = ({ popby }: { popby: Popby }) => (
    <IonItem routerLink={`/app/dashboard/${popby.id}`}>
        <Box py={3} width={1}>
            <Heading as="h6"mt={0} mb={1}>
                {popby.client.firstName} {popby.client.lastName}
                {popby.client.agentName && (
                    <>
                        {' '}
                        (on behalf of <strong>{popby.client.agentName}</strong>)
                    </>
                )}
            </Heading>
            <Text.p
                fontSize="small"
                color="medium"
                mb={0}
                style={{
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                }}
            >
                {getTimeDisplay(popby.timestamp)} - {popby.giftName}
            </Text.p>
        </Box>
    </IonItem>
)
