import { useContext } from 'react'

import { AppThemeContext, Theme } from '@reducers'

export const useAppTheme = () => {
    const context = useContext(AppThemeContext)

    if (context === undefined) {
        throw new Error('useAppTheme must be used within an AppThemeProvider')
    }

    return {
        ...context,
        isDarkTheme: context.theme === Theme.DARK,
        isLightTheme: context.theme === Theme.LIGHT,
    }
}
