import React from 'react'

import { useIonAlert, useIonToast } from '@ionic/react'
import { useHistory } from 'react-router'

import { Box, Button } from '@components'
import { useArchiveGiftMutation, useDeleteGiftMutation } from '@graphql'

import { Gift } from './Gifts.types'

export const GiftActions = ({ gift, onSuccess }: { gift: Gift; onSuccess: () => void }) => {
    const [toggleArchiveMutation, { error: archiveError }] = useArchiveGiftMutation()
    const [deleteMutation, { error: deleteError }] = useDeleteGiftMutation()
    const [showAlert] = useIonAlert()
    const [toast] = useIonToast()
    const history = useHistory()
    const isArchived = gift.archived

    const handleArchive = async () => {
        if (isArchived) {
            await toggleArchive()
        } else {
            await showAlert({
                message: 'Are you sure you want to archive this gift?',
                buttons: [
                    'Cancel',
                    { text: 'Archive', handler: async () => await toggleArchive() },
                ],
            })
        }
    }

    const handleDelete = async () => {
        await showAlert({
            message: 'Are you sure you want to delete this gift? This action cannot be undone.',
            buttons: [
                'Cancel',
                { text: 'Delete', handler: async () => await deleteGift(), role: 'destructive' },
            ],
        })
    }

    const toggleArchive = async () => {
        await toggleArchiveMutation({ variables: { giftId: gift.id, archived: !isArchived } })

        if (!archiveError) {
            await toast({
                message: `${isArchived ? 'Unarchived' : 'Archived'} Gift`,
                color: 'medium',
                duration: 2500,
            })
            onSuccess()
        } else {
            await showAlert(
                'There was an error updating this gift. ' +
                    'Please try again and contact us if the problem persists.',
            )
        }
    }

    const deleteGift = async () => {
        await deleteMutation({ variables: { giftId: gift.id } })

        if (!deleteError) {
            await toast({ message: 'Deleted Gift', color: 'medium', duration: 2500 })
            onSuccess()
        } else {
            await showAlert(
                'There was an error deleting this gift. ' +
                    'Please try again and contact us if the problem persists.',
            )
        }
    }

    return (
        <Box p={4} textAlign="center">
            <Button
                variant={isArchived ? 'default' : undefined}
                fill="outline"
                onClick={handleArchive}
                mr={5}
            >
                {isArchived ? 'Unarchive' : 'Archive'}
            </Button>
            <Button variant="danger" fill="outline" onClick={handleDelete}>
                Delete
            </Button>
        </Box>
    )
}
