import React, { useEffect, useState } from 'react'

import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { IonImg, IonInput, IonItem, IonPage, IonSpinner, useIonAlert } from '@ionic/react'
import { useForm } from 'react-hook-form'
import { useHistory } from 'react-router'
import { AppleLoginButton, GoogleLoginButton } from 'react-social-login-buttons'
import styled from 'styled-components'

import { Box, Button, Centered, Content, Flex, Heading, Text, UserNameKey } from '@components'
import { useAuth } from '@hooks'
import { isWebsite, useSetup } from '@utils'
import { Preferences } from '@capacitor/preferences'

enum SignInProvider {
    APPLE = 'apple',
    GOOGLE = 'google',
    EMAIL = 'email',
}

const SubmitButton = styled(Button)`
    margin: 0 0 8px 0;
`

export interface LoginInput {
    email: string
    password: string
}

export const Login = () => {
    const history = useHistory()
    const isSignup = new URLSearchParams(history.location.search).get('signup')
    const [showRegister, setShowRegister] = useState<boolean>(isSignup === 'true')
    const { authorized } = useAuth()
    const { proceed } = useSetup()
    const [showAlert] = useIonAlert()

    const AUTHENTICATORS = {
        [SignInProvider.APPLE]: async () => {
            const result = await FirebaseAuthentication.signInWithApple({
                scopes: ['email', 'name'],
            })

            // Name fixes
            let name = 'Apple Test'
            if (result.user?.displayName) {
                name = result.user?.displayName
            } else if (result.additionalUserInfo?.profile?.name) {
                name = result.additionalUserInfo?.profile?.name as string
            } else if (
                result.additionalUserInfo?.profile?.given_name &&
                result.additionalUserInfo?.profile?.family_name
            ) {
                name = `${result.additionalUserInfo?.profile?.given_name} ${result.additionalUserInfo?.profile?.family_name}`
            } else if (result.user?.providerData) {
                for (let i = 0; i < result.user.providerData.length; i++) {
                    if (result.user.providerData[i].providerId === 'apple.com') {
                        name = result.user.providerData[i].displayName as string
                    }
                }
            }

            await Preferences.set({ key: UserNameKey, value: name })

            return result
        },
        [SignInProvider.GOOGLE]: FirebaseAuthentication.signInWithGoogle,
        [SignInProvider.EMAIL]: FirebaseAuthentication.signInWithEmailAndPassword,
    }

    useEffect(() => {
        // This ensures that if the user ever makes it to the login page
        // when they're already logged in we send them to the next step
        // in the process, so they're not just sitting there watching a
        // spinner. On initial login this does step on the toes of the
        // above `proceed` call but whatever, the user still gets to the
        // right spot.
        if (authorized) {
            proceed()
        }
    }, [authorized])

    if (authorized) {
        return (
            <IonPage>
                <Centered>
                    <IonSpinner />
                </Centered>
            </IonPage>
        )
    }

    const signIn = async (provider: SignInProvider, email?: string, password?: string) => {
        let result

        try {
            if (provider === SignInProvider.EMAIL) {
                const payload = { email: email!, password: password! }
                result = await AUTHENTICATORS[provider](payload)
            } else {
                result = await AUTHENTICATORS[provider]()
            }

            if (result?.user) {
                // We no longer manually authenticate the user here.
                // We depend on authStateChange being fired
                // await authenticateUser(result.user, true)
            } else {
                throw new Error('No user was returned; unable to authenticate')
            }
        } catch (e: any & { code?: string; message?: string }) {
            const code = e?.code

            if (code === 'auth/wrong-password') {
                await showAlert('The email/password combination you entered was invalid.')
            } else if (code === 'auth/too-many-requests') {
                await showAlert(
                    'Your account has been temporarily disabled due ' +
                        'to many failed login attempts. Please try again later.',
                )
            } else {
                console.error(e?.message || e)
                await showAlert('There was an error signing you in. Please try again.')
            }
        }
    }

    const signInWithEmail = async (data: LoginInput) =>
        await signIn(SignInProvider.EMAIL, data.email, data.password)

    return (
        <IonPage>
            <Content forceFullWidth>
                <Box
                    position="fixed"
                    zIndex={-1}
                    height="110%"
                    width="110%"
                    style={{
                        backgroundImage: 'url(/assets/splash_background_tall.png)',
                        backgroundSize: 'cover',
                        backgroundPosition: 'center center',
                        backgroundRepeat: 'no-repeat',
                    }}
                />
                <Centered height="auto" minHeight="100%" p={4} overflowY="scroll">
                    <Flex
                        width="min(95%, 300px)"
                        height="100%"
                        justifyContent="center"
                        flexDirection="column"
                        flexWrap="nowrap"
                    >
                        <Box mb={5} maxWidth={175} mx="auto">
                            <IonImg
                                onClick={isWebsite ? () => history.push('/') : undefined}
                                src="/assets/PopbyPro_vert_color_white_text.png"
                                alt="Pop-By Pro homepage"
                                style={{
                                    height: '100%',
                                    cursor: isWebsite ? 'pointer' : undefined,
                                }}
                            />
                        </Box>
                        {showRegister && (
                            <Heading as="h5" mt={0} mb={4} color="white">
                                Sign up using Apple or Google
                            </Heading>
                        )}
                        <Flex gap={2} flexWrap="nowrap">
                            {/* Put a white box behind this button because it has proven impossible */}
                            {/* to get the hover style to change (so it's not transparent). */}
                            <Flex flexGrow={1} borderRadius={12} bg="white">
                                <AppleLoginButton
                                    align="center"
                                    onClick={() => signIn(SignInProvider.APPLE)}
                                    style={{
                                        borderRadius: '12px',
                                        margin: 0,
                                        width: '100%',
                                        fontSize: '15px',
                                    }}
                                    text="Sign in with Apple"
                                />
                            </Flex>
                            <GoogleLoginButton
                                align="center"
                                onClick={() => signIn(SignInProvider.GOOGLE)}
                                style={{
                                    borderRadius: '12px',
                                    flexGrow: 1,
                                    margin: 0,
                                    fontSize: '15px',
                                }}
                                text="Sign in with Google"
                            />
                        </Flex>
                        <Flex flexWrap="nowrap" my={2}>
                            <Flex borderTop="base" mt={3} pt={3} flexGrow={1} />
                            <Box style={{ flexGrow: 0 }} mx={2} color="light">
                                or
                            </Box>
                            <Flex borderTop="base" mt={3} pt={3} flexGrow={1} />
                        </Flex>
                        {showRegister ? (
                            <RegistrationForm onCancel={() => setShowRegister(false)} />
                        ) : (
                            <LoginForm
                                showRegister={() => setShowRegister(true)}
                                signInWithEmail={signInWithEmail}
                            />
                        )}
                    </Flex>
                </Centered>
            </Content>
        </IonPage>
    )
}

const LoginForm = ({
    showRegister,
    signInWithEmail,
}: {
    showRegister: () => void
    signInWithEmail: (data: LoginInput) => Promise<void>
}) => {
    const { handleSubmit, register } = useForm<LoginInput>()

    return (
        <>
            <Box mb={2}>
                <IonItem style={{ marginBottom: '8px' }}>
                    <IonInput
                        placeholder="Email"
                        type="email"
                        {...register('email', { required: true })}
                    />
                </IonItem>
                <IonItem style={{ marginBottom: '8px' }}>
                    <IonInput
                        placeholder="Password"
                        type="password"
                        {...register('password', { required: true })}
                    />
                </IonItem>
                <SubmitButton
                    expand="block"
                    onClick={handleSubmit(signInWithEmail)}
                    variant="primary"
                >
                    Login
                </SubmitButton>
                <Text.p color="white" mt={4} mb={0}>
                    Don't have an account?{' '}
                    <Text.span
                        color="white"
                        mt={4}
                        mb={0}
                        onClick={showRegister}
                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    >
                        Sign up
                    </Text.span>
                </Text.p>
            </Box>
        </>
    )
}

interface RegisterInput {
    email: string
    password: string
    confirm_password: string
}

export const RegistrationForm = ({ onCancel }: { onCancel: () => void }) => {
    const { handleSubmit, register } = useForm<RegisterInput>()
    const [showAlert] = useIonAlert()

    const submit = async (data: RegisterInput) => {
        try {
            await FirebaseAuthentication.createUserWithEmailAndPassword({
                email: data.email,
                password: data.password,
            })
            await showAlert('Registered with Pop-By Pro! You can return to login to get started.')
            onCancel()
        } catch (e) {
            const firebaseCode = (e as any).code
            console.error('Unable to register.', firebaseCode)

            if (firebaseCode === 'auth/email-already-in-use') {
                await showAlert('Email already in use.')
            } else {
                await showAlert(
                    'There was an error creating your account. ' +
                        'Please try again and contact us if the problem persists.',
                )
            }
        }
    }

    return (
        <>
            <Heading as="h5" mt={0} mb={4} color="white">
                Sign up using your email
            </Heading>
            <Box as={IonItem} mb={2}>
                <IonInput
                    placeholder="Email"
                    type="email"
                    {...register('email', { required: true })}
                />
            </Box>
            <Box as={IonItem} mb={2}>
                <IonInput
                    placeholder="Password"
                    type="password"
                    {...register('password', { required: true })}
                />
            </Box>
            <Box as={IonItem} mb={2}>
                <IonInput
                    placeholder="Confirm Password"
                    type="password"
                    {...register('confirm_password', { required: true })}
                />
            </Box>
            <SubmitButton expand="block" onClick={handleSubmit(submit)} variant="primary">
                Sign up
            </SubmitButton>
            <Text.p
                color="white"
                mt={4}
                mb={0}
                onClick={onCancel}
                style={{ textDecoration: 'underline', cursor: 'pointer' }}
            >
                Cancel
            </Text.p>
        </>
    )
}
