import { useEffect, useState } from 'react'

import { registerPlugin } from '@capacitor/core'
import { GeoPlugin, Location } from '@popbypro/geo'

import { isApp, isAppInBrowser } from './platform'

export const Geo = isApp && !isAppInBrowser ? registerPlugin<GeoPlugin>('GeoPlugin') : null

// https://en.wikipedia.org/wiki/Earth_radius
const EARTH_RADIUS_MILES = 3956.5
// const EARTH_RADIUS_KM = 6367.5

export interface Coordinates {
    lat: number
    lng: number
}

export const useCoordinates = () => {
    const [coords, setCoords] = useState<Location>()

    const refreshLocation = async () => {
        if (Geo) {
            const newCoords = await Geo.getLocation()
            if (newCoords && newCoords['location']) {
                setCoords(newCoords['location'])
            }
        }
    }

    useEffect(() => {
        refreshLocation()
    }, [])

    return { coords, refreshLocation }
}

/**
 * Multi-planetary support baked in and can be utilized by overriding R.
 * (assuming planet has mostly uniform radius)
 *
 * We may need to replace this with a call to Google Maps' Distance
 * Matrix to get more accurate driving distances (i.e. this calculates a
 * straight-line distance).
 * https://developers.google.com/maps/documentation/distance-matrix
 *
 * Haversine formula used below.
 * http://www.jtrive.com/calculating-distance-between-geographic-coordinate-pairs.html
 */
export const getDistance = (
    location1: Coordinates,
    location2: Coordinates,
    R: number = EARTH_RADIUS_MILES,
) => {
    const degreesToRadians = (val: number) => (val * Math.PI) / 180

    // Convert degrees to radians then compute differences.
    const [rlat1, rlon1] = [degreesToRadians(location1.lat), degreesToRadians(location1.lng)]
    const [rlat2, rlon2] = [degreesToRadians(location2.lat), degreesToRadians(location2.lng)]
    const [drlat, drlon] = [rlat2 - rlat1, rlon2 - rlon1]

    const init =
        Math.pow(Math.sin(drlat / 2), 2) +
        Math.cos(rlat1) * Math.cos(rlat2) * Math.pow(Math.sin(drlon / 2), 2)

    // The intermediate result `init` is the great circle distance
    // in radians. The return value will be in the same units as R.
    // min protects against possible round-off errors that could
    // sabotage computation of the arc-sine if the two points are
    // very nearly antipodal, e.g., on opposite sides of the planet.
    return 2.0 * R * Math.asin(Math.min(1, Math.sqrt(init)))
}
