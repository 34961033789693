import styled from 'styled-components'
import {
    color,
    ColorProps,
    layout,
    LayoutProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
} from 'styled-system'

export type TextProps = LayoutProps & TypographyProps & ColorProps & SpaceProps

const p = styled.p<TextProps>`
    margin-top: 0;

    ${layout}
    ${typography}
    ${color}
    ${space}
`

const span = styled.span<TextProps>`
    ${layout}
    ${typography}
    ${color}
    ${space}
`

const texts = { p, span }
export default texts
