import React, { useEffect, useState } from 'react'

import { IonContent, IonFab, IonFabButton, IonModal } from '@ionic/react'
import { add } from 'ionicons/icons'
import { useHistory } from 'react-router'

import { Icon } from '@components'

import { GiftForm } from './GiftForm'
import { Gift } from './Gifts.types'

export const AddGiftButton = ({ refetch }: { refetch: () => void }) => {
    const history = useHistory()
    const queryParams = new URLSearchParams(history.location.search)
    const displayOnLoad = queryParams.get('add') === 'true'
    const [showModal, setShowModal] = useState<boolean>(displayOnLoad)
    const show = () => setShowModal(true)
    const dismiss = () => setShowModal(false)

    useEffect(() => {
        if (displayOnLoad) {
            show() // Make sure to show even if this a subsequent render.
            history.replace({ search: '' })
        }
    }, [displayOnLoad, history])

    const onSuccess = () => {
        refetch()
        dismiss()
    }

    return (
        <>
            <IonFab vertical="bottom" horizontal="end" slot="fixed">
                <IonFabButton color="link" onClick={show}>
                    <Icon icon={add} />
                </IonFabButton>
            </IonFab>
            <GiftModal onClose={dismiss} onSuccess={onSuccess} showModal={showModal} />
        </>
    )
}

export interface GiftModalProps {
    gift?: Gift
    showModal: boolean
    onClose: () => void
    onSuccess: () => void
}

export const GiftModal = ({ gift, onClose, onSuccess, showModal }: GiftModalProps) => (
    <IonModal isOpen={showModal} onDidDismiss={onClose}>
        <IonContent>
            <GiftForm
                gift={gift}
                onSuccess={async () => {
                    onClose()
                    onSuccess()
                }}
                onCancel={onClose}
            />
        </IonContent>
    </IonModal>
)
