import React from 'react'

import { IonButtons, IonHeader, IonModal, IonTitle, IonToolbar } from '@ionic/react'

import { Box, Button, Content, Heading } from '@components'
import { Duration, PRODUCTS } from '@utils'

export const IAPModal = ({
    isOpen,
    dismiss,
    interval,
    proceed,
}: {
    isOpen: boolean
    dismiss: () => void
    interval: Duration | undefined
    proceed: (interval: Duration) => void
}) => {
    const product = interval ? PRODUCTS[interval] : undefined

    return (
        <IonModal isOpen={isOpen} onDidDismiss={dismiss}>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>In-App Purchase</IonTitle>
                    <IonButtons slot="end">
                        <Button onClick={dismiss}>Cancel</Button>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <Content>
                <Box p={4}>
                    <Heading as="h4"mt={0}>Pop-By Pro Subscription</Heading>
                    <Box as="ul" pl={4} mb={6}>
                        <Box as="li" mb={4}>
                            This is an auto-renewable subscription that renews each{' '}
                            <strong>{interval?.toLowerCase()}</strong> for {product?.price}.
                        </Box>
                        <Box as="li" mb={4}>
                            Your subscription will be charged to your payment method at the end of
                            your trial period and will automatically renew (at the duration
                            selected) unless auto-renew is turned off at least 24 hours before the
                            end of the current period.
                        </Box>
                        <Box as="li" mb={4}>
                            The current subscription may not be cancelled during the active
                            subscription period; however, you can manage your subscription and/or
                            turn off auto-renewal by visiting your Settings.
                        </Box>
                        <Box as="li" mb={4}>
                            <a href="https://pop-by.pro?term=PRIVACY_POLICY">Privacy Policy</a>
                        </Box>
                        <Box as="li" mb={4}>
                            <a href="https://pop-by.pro?term=EULA">EULA</a>
                        </Box>
                    </Box>
                    <Button
                        variant="primary"
                        expand="block"
                        onClick={() => {
                            dismiss()
                            proceed(interval!)
                        }}
                    >
                        Continue
                    </Button>
                </Box>
            </Content>
        </IonModal>
    )
}
