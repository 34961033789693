import * as Sentry from '@sentry/capacitor'
import * as ReactSentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

import { isLocal } from './platform'

const SENTRY_DSN = 'https://8e4029f66eac427f8fda9c370709e77e@o1382109.ingest.sentry.io/6696735'

export const initSentry = () => {
    // if (!isLocal) {
    //     Sentry.init({
    //         dsn: SENTRY_DSN,
    //         release: '7da7783432b3641a5e1af38a68526c52ff4af155',
    //         dist: '1',
    //         tracesSampleRate: 0.1,
    //         integrations: [
    //             new BrowserTracing({ tracingOrigins: ['localhost', 'https://pop-by.pro'] }),
    //         ],
    //     })
    // }
}

export const initBrowserSentry = () => {
    if (!isLocal) {
        ReactSentry.init({
            dsn: SENTRY_DSN,
            release: `popbypro@${process.env.COMMIT_SHA}`,
            integrations: [
                new BrowserTracing({ tracingOrigins: ['localhost', 'https://pop-by.pro'] }),
            ],
            tracesSampleRate: 1.0,
        })
    }
}
