import React, { Dispatch, SetStateAction } from 'react'

import { IonCheckbox, IonItem, IonLabel } from '@ionic/react'

import { Filter, Heading, SortDirection } from '@components'
import { getUpdatedSelections } from '@components'
import { useAppState } from '@hooks'

import { ClientTypes, DashboardFilter, INITIAL_FILTER, Scale, Scales } from './Dashboard.types'

const sortDirectionDescriptions = {
    [SortDirection.ASC]: 'Most recent Pop-Bys first',
    [SortDirection.DESC]: 'Least recent Pop-Bys first',
}

export const DashboardFilterBar = ({ agents }: { agents: string[] }) => {
    const { filters, setFilter } = useAppState()
    const filter = filters.dashboard as DashboardFilter

    return (
        <Filter
            name="dashboard"
            initial={INITIAL_FILTER}
            sortMethods={Scales}
            searchPlaceholder="Search past pop-bys"
            sortDirectionDescriptions={{
                [Scale.DAY]: sortDirectionDescriptions,
                [Scale.WEEK]: sortDirectionDescriptions,
                [Scale.MONTH]: sortDirectionDescriptions,
                [Scale.QUARTER]: sortDirectionDescriptions,
                [Scale.YEAR]: sortDirectionDescriptions,
            }}
        >
            <Heading as="h5"mt={5}>Client Types</Heading>
            {ClientTypes.map((clientType, idx) => (
                <IonItem
                    key={clientType}
                    lines={idx === ClientTypes.length - 1 ? 'none' : undefined}
                >
                    <IonLabel style={{ marginRight: 0 }}>{clientType}</IonLabel>
                    <IonCheckbox
                        slot="start"
                        value={clientType}
                        checked={filter.clientTypes.includes(clientType)}
                        onIonChange={(e) => {
                            const selected = e.detail.checked
                            setFilter('dashboard', (prev: DashboardFilter) => ({
                                ...prev,
                                clientTypes: getUpdatedSelections(
                                    prev.clientTypes,
                                    clientType,
                                    selected,
                                ),
                            }))
                        }}
                    />
                </IonItem>
            ))}
            <Heading as="h5"mt={5}>Agent</Heading>
            <IonItem>
                <IonLabel style={{ marginRight: 0 }}>All (including blank)</IonLabel>
                <IonCheckbox
                    slot="start"
                    value="All"
                    checked={filter.agents.includes('All')}
                    onIonChange={(e) => {
                        const selected = e.detail.checked
                        setFilter('dashboard', (prev: DashboardFilter) => ({
                            ...prev,
                            agents: selected ? ['All'] : agents,
                        }))
                    }}
                />
            </IonItem>
            {agents.map((agent, idx) => (
                <IonItem key={agent} lines={idx === agents.length - 1 ? 'none' : undefined}>
                    <IonLabel style={{ marginRight: 0 }}>{agent}</IonLabel>
                    <IonCheckbox
                        slot="start"
                        value={agent}
                        checked={filter.agents.includes(agent)}
                        onIonChange={(e) => {
                            const selected = e.detail.checked
                            setFilter('dashboard', (prev: DashboardFilter) => ({
                                ...prev,
                                agents: getUpdatedSelections(prev.agents, agent, selected),
                            }))
                        }}
                        disabled={agent !== 'All' && filter.agents.includes('All')}
                    />
                </IonItem>
            ))}
        </Filter>
    )
}
