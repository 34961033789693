import React, { createContext, useState } from 'react'

const THEME_COOKIE_KEY = 'app-theme'

export enum Theme {
    LIGHT = 'light',
    DARK = 'dark',
}

const getTheme = () => {
    const themeCookie = window.localStorage.getItem(THEME_COOKIE_KEY)

    if (![Theme.LIGHT, Theme.DARK].includes(themeCookie as Theme)) {
        // Invalid value -- clear it out.
        window.localStorage.removeItem(THEME_COOKIE_KEY)
    }

    return (themeCookie ||
        (window.matchMedia('(prefers-color-scheme: dark)').matches
            ? Theme.DARK
            : Theme.LIGHT)) as Theme
}

export const AppThemeContext = createContext<{ theme: Theme; setTheme: (theme?: Theme) => void }>({
    theme: Theme.LIGHT,
    setTheme: () => null,
})

export const AppThemeProvider = ({ children }: { children: React.ReactNode }) => {
    // @ts-ignore
    const [_theme, _setTheme] = useState(Theme.LIGHT) //getTheme())

    // May want to consider updating this method to only call `setTheme`
    // if there's no cookie set (i.e. if the user made a decision to set
    // the theme, should we let a system preference override change it?)
    // const toggleTheme = (e: MediaQueryListEvent) => setTheme(e.matches ? Theme.DARK : Theme.LIGHT)

    // const setTheme = (theme?: Theme) => {
    //     theme = theme || getTheme()

    //     _setTheme(theme)

    //     window.localStorage.setItem(THEME_COOKIE_KEY, theme)
    //     document.body.classList.toggle(Theme.DARK, theme === Theme.DARK)
    // }

    // Temporarily forced to light theme
    // useEffect(() => {
    //     setTheme() // Initial setup.
    //
    //     const prefersDark = window.matchMedia('(prefers-color-scheme: dark)')
    //     prefersDark.addEventListener('change', toggleTheme)
    //
    //     return () => prefersDark.removeEventListener('change', toggleTheme)
    // })

    return (
        // Temporarily forced to light theme
        // <AppThemeContext.Provider value={{ theme: _theme, setTheme }}>
        <AppThemeContext.Provider value={{ theme: Theme.LIGHT, setTheme: () => null }}>
            {children}
        </AppThemeContext.Provider>
    )
}
