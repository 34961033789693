import React, { useState } from 'react'

import {
    IonButtons,
    IonHeader,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonTextarea,
    IonTitle,
    IonToolbar,
    useIonAlert,
    useIonToast,
} from '@ionic/react'
import { arrowForwardSharp } from 'ionicons/icons'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import { Box, Button, Content, ErrorMessage, Flex, Heading, Icon, Text } from '@components'
import { Template, UpsertTemplateInput, useAddOrEditTemplateMutation } from '@graphql'
import { useAppState } from '@hooks'
import { fillTemplate } from '@utils'

import { DeleteTemplateButton } from './DeleteTemplateButton'

const TemplateFormContent = styled(Content)`
    --background: var(--ion-color-light);
`

export const TemplateForm = ({
    template,
    numTemplates,
    onSuccess,
    onCancel,
}: {
    template?: Template
    numTemplates?: number
    onSuccess: (() => Promise<void>) | (() => void)
    onCancel: () => void
}) => {
    const [addOrEditTemplateMutation, { error }] = useAddOrEditTemplateMutation()
    const [showAlert] = useIonAlert()
    const [toast] = useIonToast()
    const isEdit = !!template
    const [message, setMessage] = useState<string>(template?.message || '')
    const [showTips, setShowTips] = useState(false)
    const { user } = useAppState()

    const {
        handleSubmit,
        formState: { errors },
        register,
    } = useForm<UpsertTemplateInput>({
        defaultValues: {
            id: template?.id,
            name: template?.name,
            message,
        },
    })

    const submit = async (data: UpsertTemplateInput) => {
        await addOrEditTemplateMutation({ variables: { template: data } })

        if (!error) {
            await toast({
                message: `Template ${isEdit ? 'updated' : 'added'}`,
                color: 'medium',
                duration: 2500,
            })
            await onSuccess()
        } else {
            await showAlert(
                'There was an error saving this template. ' +
                    'Please try again and contact us if the problem persists.',
            )
        }
    }

    const getExample = (val: string) =>
        fillTemplate(user.data, val, { firstName: 'John', lastName: 'Doe' })

    return (
        <>
            <IonHeader>
                <IonToolbar>
                    <IonTitle>{isEdit ? 'Edit' : 'Add'} Template</IonTitle>
                    <IonButtons slot="start">
                        <Button onClick={onCancel}>Cancel</Button>
                    </IonButtons>
                    <IonButtons slot="end">
                        <Button onClick={handleSubmit(submit)}>{isEdit ? 'Save' : 'Add'}</Button>
                    </IonButtons>
                </IonToolbar>
            </IonHeader>
            <TemplateFormContent>
                <IonList lines="full">
                    <IonItem>
                        <Flex justifyContent="space-between" mt={2}>
                            <IonLabel position="stacked">Description*</IonLabel>
                            <ErrorMessage error={errors.name} />
                        </Flex>
                        <IonInput
                            placeholder="Christmas Greetings"
                            autoCapitalize="words"
                            {...register('name', { required: true })}
                        />
                    </IonItem>
                    <IonItem className="ion-align-items-start">
                        <Flex justifyContent="space-between" alignItems="center" mt={2}>
                            <IonLabel position="stacked">Message*</IonLabel>
                            <ErrorMessage error={errors.message} />
                        </Flex>
                        <IonTextarea
                            style={{ lineHeight: '1.25em' }}
                            onIonChange={(e) => setMessage(e.detail.value || '')}
                            placeholder="Season's greetings, FIRST_NAME! I left you a little something for the holidays outside your front door!"
                            {...register('message', { required: true })}
                            rows={6}
                        />
                    </IonItem>
                </IonList>
                <Box p={4}>
                    <Heading as="h6"mt={0} mb={3}>
                        Your message will appear as:
                    </Heading>
                    <Box backgroundColor="white" p={3}>
                        <Text.p
                            fontSize="small"
                            lineHeight="1.5em"
                            fontStyle="italic"
                            style={{ whiteSpace: 'pre-wrap' }}
                            mb={0}
                        >
                            {getExample(message)}
                        </Text.p>
                    </Box>
                    {(showTips && (
                        <>
                            <Flex justifyContent="space-between" alignItems="center">
                                <Heading as="h6"mb={2}>Using Templates</Heading>
                                <Button
                                    variant="link"
                                    size="small"
                                    className="ion-no-padding ion-no-margin"
                                    onClick={() => setShowTips(false)}
                                >
                                    Hide
                                </Button>
                            </Flex>
                            <Text.p fontSize="small">
                                You can use the keywords below to dynamically insert client
                                information in your pop-by messages:
                            </Text.p>
                            <Text.p fontSize="small" mb={4}>
                                <code>FIRST_NAME, TIME_OF_DAY, SIGNATURE</code>
                            </Text.p>
                            <Text.p mt={1} mb={2} fontSize="small" fontWeight="bold">
                                Example
                            </Text.p>
                            <Text.p fontSize="small" fontStyle="italic" mb={1}>
                                Good TIME_OF_DAY, FIRST_NAME!
                            </Text.p>
                            <Flex alignItems="center">
                                <Text.p fontSize="small" mr={2} pt={1} mb={0}>
                                    <Icon icon={arrowForwardSharp} />
                                </Text.p>
                                <Text.p fontSize="small" fontStyle="italic" mb={0}>
                                    {getExample('Good TIME_OF_DAY, FIRST_NAME!')}
                                </Text.p>
                            </Flex>
                        </>
                    )) || (
                        <Flex justifyContent="end">
                            <Button
                                variant="link"
                                size="small"
                                className="ion-no-padding ion-no-margin"
                                onClick={() => setShowTips(true)}
                            >
                                Read tips about templates
                            </Button>
                        </Flex>
                    )}
                </Box>
                {isEdit && (
                    <DeleteTemplateButton
                        templateId={template.id}
                        numTemplates={numTemplates!}
                        onDelete={onSuccess}
                    />
                )}
            </TemplateFormContent>
        </>
    )
}
