import React, { useEffect, useState } from 'react'

import { IonSelect, IonSelectOption, IonSpinner } from '@ionic/react'

import { Centered, Flex, Text } from '@components'
import { useSetUserNotificationIntervalMutation } from '@graphql'
import { useAppState } from '@hooks'

import { SettingsItem } from './SettingsItem'

export const IntervalSetting = () => {
    const { user } = useAppState()
    const { notifications: notify, notificationInterval } = user.data!
    const [setNotificationInterval, mutation] = useSetUserNotificationIntervalMutation()
    const [selectedInterval, setInterval] = useState<number>(notificationInterval)

    // A useEffect is used here instead of inlining this code into the
    // onIonChange prop below to prevent duplicate submissions.
    useEffect(() => {
        const update = async () => {
            await setNotificationInterval({ variables: { interval: selectedInterval } })
            await user.refetch()
        }

        if (selectedInterval !== notificationInterval) {
            update()
        }
    }, [notify, notificationInterval, selectedInterval, user, setNotificationInterval])

    if (mutation.loading || user.loading) {
        return (
            <SettingsItem>
                <Centered>
                    <IonSpinner />
                </Centered>
            </SettingsItem>
        )
    }

    return (
        <SettingsItem disabled={!notify}>
            <Flex alignItems="center" justifyContent="space-between" flexWrap="nowrap">
                <IonSelect
                    interface="action-sheet"
                    label='Notification Interval'
                    onIonChange={(e) => setInterval(parseInt(e.detail.value!))}
                    value={selectedInterval.toString()}
                >
                    <IonSelectOption value="0">Always</IonSelectOption>
                    <IonSelectOption value="7">7 days</IonSelectOption>
                    <IonSelectOption value="30">30 days</IonSelectOption>
                    <IonSelectOption value="60">60 days</IonSelectOption>
                    <IonSelectOption value="90">90 days</IonSelectOption>
                    <IonSelectOption value="180">180 days</IonSelectOption>
                    <IonSelectOption value="360">360 days</IonSelectOption>
                </IonSelect>
            </Flex>
            <Text.p fontSize="small" color="medium" mb={0}>
                {(selectedInterval.toString() === '0' && (
                    <>You will always get notifications for in-range clients.</>
                )) || (
                    <>
                        After visiting a client, we won't notify you again about them for{' '}
                        {selectedInterval} days.
                    </>
                )}
            </Text.p>
        </SettingsItem>
    )
}
