import React, { useState } from 'react'

import {
    IonButtons,
    IonCheckbox,
    IonHeader,
    IonItem,
    IonLabel,
    IonModal,
    IonRadio,
    IonRadioGroup,
    IonSearchbar,
    IonSegment,
    IonSegmentButton,
    IonTitle,
    IonToolbar,
} from '@ionic/react'
import { funnelSharp } from 'ionicons/icons'

import {
    Box,
    Button,
    Content,
    Flex,
    Heading,
    Icon,
    SortDirection,
    SortDirections,
} from '@components'
import { useAppState } from '@hooks'

import { getUpdatedSelections } from './Filter.utils'

type SetFilter = (key: string, value: any) => void

interface BaseFilter<T> {
    method: string
    direction: SortDirection
    search: string
    sections: string[]
}

export const Filter = <T,>({
    name,
    initial,
    searchPlaceholder = 'Search...',
    sortMethods,
    sortMethodSections,
    sortDirectionDescriptions,
    hideDefaultOptions = false,
    children,
}: {
    name: string
    initial: BaseFilter<T>
    searchPlaceholder?: string
    sortMethods: string[]
    sortMethodSections?: { [key: string]: string[] }
    sortDirectionDescriptions: { [key: string]: { [key in SortDirection]: string } }
    hideDefaultOptions?: boolean
    children?: React.ReactNode
}) => {
    const { filters, setFilter } = useAppState()
    const filter = filters[name] as BaseFilter<T>
    const [showModal, setShowModal] = useState<boolean>(false)

    const showOptions = () => setShowModal(true)
    const hideOptions = () => setShowModal(false)
    const resetOptions = () => setFilter(name, () => initial)

    /**
     * Search pops up a modal with a bunch of options for filtering
     * which when selected or entered will close the modal and update
     * the filter search list.
     */
    return (
        <>
            <Box borderBottom="base" borderBottomWidth={2}>
                <Flex flexWrap="nowrap" alignItems="center">
                    <IonSearchbar
                        placeholder={searchPlaceholder}
                        onIonChange={(e) =>
                            setFilter(name, (prev: T) => ({
                                ...prev,
                                search: e.detail.value!,
                            }))
                        }
                        value={filter.search}
                    />
                    <Box mr={2}>
                        <Button
                            fill="clear"
                            height="36px"
                            className="ion-no-padding"
                            onClick={showOptions}
                        >
                            <Icon icon={funnelSharp} color="logo-primary" />
                        </Button>
                    </Box>
                </Flex>
            </Box>
            <IonModal isOpen={showModal} onDidDismiss={hideOptions}>
                <IonHeader>
                    <IonToolbar>
                        <IonButtons slot="start">
                            <Button onClick={resetOptions}>Reset</Button>
                        </IonButtons>
                        <IonTitle>View Settings</IonTitle>
                        <IonButtons slot="end">
                            <Button onClick={hideOptions}>Done</Button>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <Content className="ion-padding">
                    {!hideDefaultOptions && (
                        <>
                            <PickSortMethod
                                options={sortMethods}
                                name={name}
                                filter={filter}
                                setFilter={setFilter}
                            />
                            <PickSortDirection
                                sortMethodDescriptions={sortDirectionDescriptions}
                                name={name}
                                filter={filter}
                                setFilter={setFilter}
                            />
                            {sortMethodSections && (
                                <PickSections
                                    sortMethodSections={sortMethodSections}
                                    name={name}
                                    filter={filter}
                                    setFilter={setFilter}
                                />
                            )}
                        </>
                    )}
                    {children}
                </Content>
            </IonModal>
        </>
    )
}

interface PickerProps<T> {
    name: string
    filter: BaseFilter<T>
    setFilter: SetFilter
}

const PickSortMethod = <T,>({
    name,
    options,
    filter,
    setFilter,
}: PickerProps<T> & { options: string[] }) => (
    <>
        <Heading as="h5"mt={0}>Method</Heading>
        <IonSegment
            onIonChange={(e) =>
                setFilter(name, (prev: T) => ({
                    ...prev,
                    method: e.detail.value!,
                    direction: SortDirection.ASC,
                }))
            }
            value={filter.method}
        >
            {options.map((option) => (
                <IonSegmentButton key={option} value={option}>
                    <IonLabel>{option}</IonLabel>
                </IonSegmentButton>
            ))}
        </IonSegment>
    </>
)

const PickSortDirection = <T,>({
    name,
    sortMethodDescriptions,
    filter,
    setFilter,
}: PickerProps<T> & {
    sortMethodDescriptions: { [key: string]: { [key: string]: string } }
}) => (
    <>
        <Heading as="h5"mt={5}>Direction</Heading>
        <IonRadioGroup
            value={filter.direction}
            onIonChange={(e) =>
                setFilter(name, (prev: T) => ({
                    ...prev,
                    direction: e.detail.value,
                }))
            }
        >
            {SortDirections.map((direction, idx) => (
                <IonItem
                    key={direction}
                    lines={idx === SortDirections.length - 1 ? 'none' : undefined}
                >
                    <IonLabel>{sortMethodDescriptions[filter.method][direction]}</IonLabel>
                    <IonRadio slot="start" value={direction} />
                </IonItem>
            ))}
        </IonRadioGroup>
    </>
)

const PickSections = <T,>({
    sortMethodSections,
    name,
    filter,
    setFilter,
}: PickerProps<T> & {
    sortMethodSections: { [key: string]: string[] }
}) => {
    const sections = sortMethodSections[filter.method]

    const isSectionAll = (section: string) => section.startsWith('All')
    const isNextSectionAll = (idx: number) =>
        sections.length - 1 > idx && isSectionAll(sections[idx + 1])
    const hasSelectedAll = filter.sections.map((section) => isSectionAll(section)).includes(true)

    return (
        <>
            <Heading as="h5"mt={5}>Sections</Heading>
            {sections.map((section, idx) => (
                <IonItem
                    key={section}
                    lines={
                        isNextSectionAll(idx)
                            ? 'full'
                            : sections.length - 1 === idx
                            ? 'none'
                            : undefined
                    }
                    disabled={!isSectionAll(section) && hasSelectedAll}
                >
                    <IonLabel>{section}</IonLabel>
                    <IonCheckbox
                        slot="start"
                        value={section}
                        checked={filter.sections.includes(section) || hasSelectedAll}
                        onIonChange={(e) => {
                            const selected = e.detail.checked
                            setFilter(name, (prev: BaseFilter<T>) => ({
                                ...prev,
                                sections: getUpdatedSelections(prev.sections, section, selected),
                            }))
                        }}
                    />
                </IonItem>
            ))}
        </>
    )
}
