import React from 'react'
import ReactDOM from 'react-dom'

import {
    AppleProduct,
    SETUP_SUBSCRIBED,
    SITE_URL,
    initBrowserSentry,
    initSentry,
    isApp,
    isAppInBrowser,
} from '@utils'

import { App } from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration'
import { Preferences } from '@capacitor/preferences'
import { CapacitorHttp } from '@capacitor/core'

const startApp = () => {
    ReactDOM.render(
        <React.StrictMode>
            <App />
        </React.StrictMode>,
        document.getElementById('root'),
    )
}

if (isApp && !isAppInBrowser) {
    initSentry()

    const waitForAuthToken = async () => {
        let authToken: string | undefined = undefined
        while (!authToken) {
            const pref = await Preferences.get({ key: 'authToken' })
            if (pref.value) {
                authToken = pref.value
            } else {
                await new Promise((resolve) => setTimeout(resolve, 1000))
            }
        }

        return authToken
    }

    document.addEventListener('deviceready', () => {
        if (isApp && !isAppInBrowser) {
            const { store, ProductType, Platform } = CdvPurchase
            store.verbosity = CdvPurchase.LogLevel.DEBUG
            store.validator = async (receipt, callback) => {
                const authToken = await waitForAuthToken()

                if (!receipt.transaction) {
                    console.error('No transaction found in receipt')
                    callback({ ok: false })
                }

                const transaction = receipt.transaction
                const resp = await CapacitorHttp.post({
                    headers: { 'Content-Type': 'application/json' },
                    url: `${SITE_URL}/api/iap/verify`,
                    data: { transaction },
                    params: { user: authToken },
                })

                const { subscribed, ...rest } = resp.data
                if (subscribed) {
                    console.debug("Validator passed. Subscribed returned. Setting native")
                    await Preferences.set({ key: SETUP_SUBSCRIBED, value: '1' })
                } else {
                    // They completed a purchase but aren't subscribed. This
                    // shouldn't happen. Try a restore of purchases
                    store.restorePurchases()
                }

                callback(rest)
            }

            const syncReceipt = async (receipt: string) => {
                const authToken = await waitForAuthToken()

                const resp = await CapacitorHttp.post({
                    headers: { 'Content-Type': 'application/json' },
                    url: `${SITE_URL}/api/iap/sync_receipt`,
                    data: { receipt },
                    params: { user: authToken },
                })

                if (resp.data.subscribed) {
                    await Preferences.set({ key: SETUP_SUBSCRIBED, value: '1' })
                } else {
                    await Preferences.remove({ key: SETUP_SUBSCRIBED })
                }
            }

            store.register([
                {
                    type: ProductType.PAID_SUBSCRIPTION,
                    id: AppleProduct.MONTHLY,
                    platform: Platform.APPLE_APPSTORE,
                },
                {
                    type: ProductType.PAID_SUBSCRIPTION,
                    id: AppleProduct.ANNUAL,
                    platform: Platform.APPLE_APPSTORE,
                },
            ])

            store
                .when()
                .approved((transaction) => transaction.verify())
                .verified((receipt) => receipt.finish())
                .receiptUpdated((receipt) => {
                    // @todo: Use this as a hook to fetch the user's subscription status
                    // @ts-ignore
                    if (receipt && receipt.nativeData) {
                        // @ts-ignore
                        syncReceipt(receipt.nativeData)
                    }
                })

            store.ready(() => {
                startApp()
            })

            store.initialize([Platform.APPLE_APPSTORE])
        }
    })
} else {
    initBrowserSentry()
    startApp()
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister()
