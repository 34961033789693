import React from 'react'

import { IonIcon } from '@ionic/react'

type IonIconProps = Omit<React.ComponentProps<typeof IonIcon>, 'size'>

const Icon = ({ icon, size, ...rest }: { size?: number } & IonIconProps) => (
    <IonIcon icon={icon} style={size ? { fontSize: `${size}px` } : undefined} {...rest} />
)

export default Icon
