import React from 'react'

import { IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs } from '@ionic/react'
import { chatboxEllipses, gift, grid, helpCircle, people } from 'ionicons/icons'
import { Redirect, Route } from 'react-router-dom'

import { Icon } from '@components'
import { ClientsRoutes, DashboardRoutes, FAQsRoutes, Gifts, HandleUnsubscribe, Templates } from '@pages'
import { isApp } from '@utils'

import './AppHome.css'

const NavIcon = ({ icon }: { icon: string }) => <Icon icon={icon} size={24} />

export const AppHome = () => (
    <IonTabs>
        <IonRouterOutlet>
            <Route exact path="/app">
                <Redirect to="/app/dashboard" />
            </Route>
            <Route path="/app/dashboard" component={DashboardRoutes} />
            <Route path="/app/clients" component={ClientsRoutes} />
            <Route path="/app/gifts" exact component={Gifts} />
            <Route path="/app/messages" exact component={Templates} />
            <Route path="/app/faq" component={FAQsRoutes} />
        </IonRouterOutlet>
        <IonTabBar
            slot="bottom"
            style={{
                paddingBottom: isApp ? '12px' : '0',
                paddingLeft: isApp ? '12px' : '0',
                paddingRight: isApp ? '12px' : '0',
            }}
        >
            <IonTabButton tab="dashboard" href="/app/dashboard">
                <NavIcon icon={grid} />
                <IonLabel>Dashboard</IonLabel>
            </IonTabButton>
            <IonTabButton tab="clients" href="/app/clients">
                <NavIcon icon={people} />
                <IonLabel>Clients</IonLabel>
            </IonTabButton>
            <IonTabButton tab="gifts" href="/app/gifts">
                <NavIcon icon={gift} />
                <IonLabel>Gifts</IonLabel>
            </IonTabButton>
            <IonTabButton tab="messages" href="/app/messages">
                <NavIcon icon={chatboxEllipses} />
                <IonLabel>Messages</IonLabel>
            </IonTabButton>
            <IonTabButton tab="faq" href="/app/faq">
                <NavIcon icon={helpCircle} />
                <IonLabel>Support</IonLabel>
            </IonTabButton>
        </IonTabBar>
    </IonTabs>
)
