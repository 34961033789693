import React from 'react'

import { Style } from '@capacitor/status-bar'
import { IonPage } from '@ionic/react'
import '@ionic/react/css/ionic-swiper.css'
import { documents, gift, people } from 'ionicons/icons'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Box, Button, Centered, Content, DownloadApp, Flex, Heading, Icon, Text } from '@components'
import { usePreferences, useStatusBarTheme } from '@hooks'
import { isApp, isWebsite, SETUP_NEXT_STEPS, useSetup } from '@utils'

export const SetupNextSteps = () => {
    useStatusBarTheme(Style.Light) // No toolbar on this page.
    const preferences = usePreferences()
    const { proceed } = useSetup()

    const next = async () => {
        await preferences.set({ key: SETUP_NEXT_STEPS, value: 'true' })
        await proceed()
    }

    return (
        <IonPage>
            <Content>
                <Centered>
                    <Heading as="h1">Start Using Pop-By Pro!</Heading>
                    {isApp && (
                        <Text.p color="medium" mb={0}>
                            To get started, you will:
                        </Text.p>
                    )}
                    <Box
                        textAlign="left"
                        mx="auto"
                        m={5}
                        p={5}
                        borderRadius={12}
                        border="base"
                        width={265}
                        bg="white"
                    >
                        {isWebsite && (
                            <Box mb={5}>
                                <DownloadApp />
                                <Flex flexWrap="nowrap">
                                    <Flex borderTop="base" mt={3} pt={3} flexGrow={1} />
                                    <Box style={{ flexGrow: 0 }} mx={2} color="medium">
                                        then
                                    </Box>
                                    <Flex borderTop="base" mt={3} pt={3} flexGrow={1} />
                                </Flex>
                            </Box>
                        )}
                        <Flex gap={3} alignItems="center" mb={6} color="medium">
                            <Icon icon={people} size={24} />
                            <Text.p color="dark" mb={0}>
                                Add prospective clients
                            </Text.p>
                        </Flex>
                        <Flex gap={3} alignItems="center" mb={6} color="medium">
                            <Icon icon={gift} size={24} />
                            <Text.p color="dark" mb={0}>
                                Add your pop-by gifts
                            </Text.p>
                        </Flex>
                        <Flex gap={3} alignItems="center" color="medium">
                            <Icon icon={documents} size={24} />
                            <Text.p color="dark" mb={0}>
                                Setup message templates
                            </Text.p>
                        </Flex>
                    </Box>
                    <Box mb={3}>
                        <Button onClick={next} variant="primary">
                            Let's Go!
                        </Button>
                    </Box>
                </Centered>
            </Content>
        </IonPage>
    )
}
