import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client'
import { setContext } from '@apollo/client/link/context'
import { Preferences } from '@capacitor/preferences'

import { SITE_URL } from './platform'

// This should only be invoked a single time on App setup.
export const getApolloClient = () => {
    const httpLink = createHttpLink({ uri: `${SITE_URL}/graphql` })
    const authLink = setContext(async (_, { headers }) => {
        const token = await Preferences.get({ key: 'authToken' })
        return {
            headers: {
                ...headers,
                authorization: token.value ? `Bearer ${token.value}` : '',
            },
        }
    })

    return new ApolloClient({
        link: authLink.concat(httpLink),
        cache: new InMemoryCache(),
    })
}
