import React from 'react'

import { Address } from '@graphql'
import { GOOGLE_MAPS_API_KEY } from '@utils'

import { Box } from '../Design'

interface Props {
    address: Omit<Address, 'placeId' | 'coordinates'>
}

export const AddressMap = ({ address }: Props) => {
    const params = new URLSearchParams({
        key: GOOGLE_MAPS_API_KEY,
        q: [address.street, address.city, address.state, address.zip]
            .reduce((curr, value) => `${curr}${value ? value + ' ' : ''}`, '')
            .trim(),
        zoom: '13',
    })

    const mapUrl = `https://www.google.com/maps/embed/v1/place?${params.toString()}`

    return (
        <Box
            as="iframe"
            title="Client Address Map"
            src={mapUrl}
            border={0}
            minHeight="min(225px, 50%)"
            width={1}
        />
    )
}
