import { Client, ClientFilter, ClientType, SortMethod } from './Clients.types'

export const stateShortToLong = {
    AL: 'Alabama',
    AK: 'Alaska',
    AZ: 'Arizona',
    AR: 'Arkansas',
    CA: 'California',
    CO: 'Colorado',
    CT: 'Connecticut',
    DE: 'Delaware',
    FL: 'Florida',
    GA: 'Georgia',
    HI: 'Hawaii',
    ID: 'Idaho',
    IL: 'Illinois',
    IN: 'Indiana',
    IA: 'Iowa',
    KS: 'Kansas',
    KY: 'Kentucky',
    LA: 'Louisiana',
    ME: 'Maine',
    MD: 'Maryland',
    MA: 'Massachusetts',
    MI: 'Michigan',
    MN: 'Minnesota',
    MS: 'Mississippi',
    MO: 'Missouri',
    MT: 'Montana',
    NE: 'Nebraska',
    NV: 'Nevada',
    NH: 'New Hampshire',
    NJ: 'New Jersey',
    NM: 'New Mexico',
    NY: 'New York',
    NC: 'North Carolina',
    ND: 'North Dakota',
    OH: 'Ohio',
    OK: 'Oklahoma',
    OR: 'Oregon',
    PA: 'Pennsylvania',
    RI: 'Rhode Island',
    SC: 'South Carolina',
    SD: 'South Dakota',
    TN: 'Tennessee',
    TX: 'Texas',
    UT: 'Utah',
    VT: 'Vermont',
    VA: 'Virginia',
    WA: 'Washington',
    WV: 'West Virginia',
    WI: 'Wisconsin',
    WY: 'Wyoming',
} as { [key: string]: string }

/**
 * See if the filter deems the given client worthy to be displayed.
 */
export const matchClient = (filter: ClientFilter, client: Client) => {
    if (
        (client.type && !filter.clientTypes.includes(client.type)) ||
        (!client.type && !filter.clientTypes.includes(ClientType.NONE))
    ) {
        return false
    }

    if (
        !filter.agents.includes('All') &&
        (!client.agentName || !filter.agents.includes(client.agentName))
    ) {
        return false
    }

    if (
        filter.terms.length &&
        !filter.terms.some(
            (val) =>
                val === client.address.zip ||
                val === `${client.address.city}, ${client.address.state}` ||
                val === stateShortToLong[client.address.state],
        )
    ) {
        return false
    }

    const search = filter.search.toLowerCase()
    const check = (value: string) => value.toLowerCase().includes(search)

    return (
        check(`${client.firstName} ${client.lastName}`) ||
        check(client.firstName) ||
        check(client.lastName) ||
        check(client.email)
    )
}

/**
 * Sorts by full names in ascending alphabetical order.
 */
const sortByName = (a: Client, b: Client) =>
    `${a.firstName} ${a.lastName}`.localeCompare(`${b.firstName} ${b.lastName}`)

/**
 * Sorts by the distance to the client.
 *
 * Equidistant clients are resolved by name in ascending alphabetical order.
 */
const sortByDistance = (a: Client, b: Client) =>
    a.distance !== b.distance ? a.distance! - b.distance! : sortByName(a, b)

/**
 * Sorts by the last pop-by timestamp.
 *
 * Both null? Sort by name in ascending alphabetical order.
 * One null? Sort the one without above the other (should go to top)
 * Otherwise, compare the timestamps putting most recent first.
 */
const sortByLastVisit = (a: Client, b: Client) =>
    !a.lastPopby && !b.lastPopby
        ? sortByName(a, b)
        : !a.lastPopby
        ? -1
        : !b.lastPopby
        ? 1
        : b.lastPopby.localeCompare(a.lastPopby)

export const SORTERS = {
    [SortMethod.ALPHABETICAL]: sortByName,
    [SortMethod.DISTANCE]: sortByDistance,
    [SortMethod.LAST_POP_BY]: sortByLastVisit,
}
