import React, { useState } from 'react'

import { FirebaseAuthentication } from '@capacitor-firebase/authentication'
import { Preferences } from '@capacitor/preferences'
import {
    IonButtons,
    IonHeader,
    IonList,
    IonModal,
    IonTitle,
    IonToolbar,
    useIonAlert,
} from '@ionic/react'

import { Box, Button, Centered, Content, Flex, Heading, ProfilePicture, Text } from '@components'
import { useDeleteUserMutation } from '@graphql'
import { useAppState, useAuth, useSubscription } from '@hooks'

import { IntervalSetting } from './IntervalSetting'
import { NotificationsToggle } from './NotificationsToggle'
import { SignatureSetting } from './SignatureSetting'
import { SubscriptionSetting } from './SubscriptionSetting'
import { DistanceSettings } from './DistanceSettings'
import { ExportClients } from './ExportClients'

const logout = async () => {
    await Preferences.remove({ key: 'authToken' })
    await FirebaseAuthentication.signOut()
    window.location.assign('/login')
}

export const Settings = () => {
    const [showModal, setShowModal] = useState<boolean>(false)
    const [showAlert] = useIonAlert()
    const show = () => setShowModal(true)
    const dismiss = () => setShowModal(false)

    return (
        <>
            <Button onClick={show}>
                <ProfilePicture />
            </Button>
            <IonModal isOpen={showModal} canDismiss onDidDismiss={dismiss}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Settings</IonTitle>
                        <IonButtons slot="start">
                            <Button
                                onClick={() =>
                                    showAlert({
                                        message: 'Are you sure you want to sign out?',
                                        buttons: [
                                            { text: 'No' },
                                            { text: 'Yes', role: 'destructive', handler: logout },
                                        ],
                                    })
                                }
                            >
                                Sign Out
                            </Button>
                        </IonButtons>
                        <IonButtons slot="end">
                            <Button onClick={dismiss}>Done</Button>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                {showModal && <SettingsContent />}
            </IonModal>
        </>
    )
}

const SettingsContent = () => {
    const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false)
    const [showAlert] = useIonAlert()
    const { user: authUser } = useAuth()
    const { user } = useAppState()
    const [deleteMutation, _] = useDeleteUserMutation()
    const dbUser = user.data
    const subscription = useSubscription()

    if (!dbUser) {
        return null
    }

    const deleteUser = async () => {
        if (authUser) {
            const resp = await deleteMutation({ variables: { uid: authUser!.uid } })
            if (resp.data) {
                await logout()
            } else {
                await showAlert('There was an error deleting your account. Please try again.')
            }
        }
    }

    return (
        <>
            <Content>
                <Centered p={6} height="auto" borderBottom="base" borderBottomWidth={3}>
                    <ProfilePicture huge />
                    <Heading as="h3"mb={2}>
                        {dbUser.firstName} {dbUser.lastName}
                    </Heading>
                    <Text.p fontSize="small" color="medium" mb={0}>
                        {authUser!.email}
                    </Text.p>
                </Centered>
                <IonList lines="full">
                    <NotificationsToggle />
                    <DistanceSettings />
                    <IntervalSetting />
                    <SignatureSetting />
                    <SubscriptionSetting />
                    <ExportClients />
                    {/* Commented out until I can apply new theming to dark theme */}
                    {/*<ThemeToggle />*/}
                </IonList>
                <Centered height="auto" p={6}>
                    <Button fill="clear" onClick={() => setShowDeleteModal(true)} variant="danger">
                        Delete Account
                    </Button>
                </Centered>
            </Content>
            <IonModal
                isOpen={showDeleteModal}
                canDismiss
                onDidDismiss={() => setShowDeleteModal(false)}
            >
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>Delete Account</IonTitle>
                        <IonButtons slot="end">
                            <Button onClick={() => setShowDeleteModal(false)}>Cancel</Button>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <Content>
                    <Box p={4} textAlign="center">
                        <Heading as="h4"color="danger" mb={4}>
                            Delete Pop-By Pro Account
                        </Heading>
                        <Text.p textAlign="center">
                            Deleting your Pop-By Pro account is permanent. Once deleted, you cannot
                            recover your data by signing in again. A new account will be created,
                            requiring you to enter all your information again.
                        </Text.p>
                        <Text.p>
                            Your subscription is not automatically cancelled when your account is
                            deleted. Be sure to head to your settings and cancel your subscription
                            before deleting your account.
                        </Text.p>
                    </Box>
                    <Flex justifyContent="center" mb={4}>
                        <Button fill="outline" onClick={() => subscription.manage()}>
                            Cancel Subscription
                        </Button>
                    </Flex>
                    <Flex justifyContent="center">
                        <Button onClick={deleteUser} variant="danger" shape="round">
                            Delete Account
                        </Button>
                    </Flex>
                </Content>
            </IonModal>
        </>
    )
}
