import styled from 'styled-components'
import { flexbox, FlexboxProps, system } from 'styled-system'

import Box, { BoxProps } from './Box'

const gap = system({
    gap: {
        property: 'gap',
        scale: 'space',
    },
})

export type FlexProps = BoxProps & FlexboxProps & { gap?: string | string[] | number | number[] }

const Flex = styled(Box)<FlexProps>`
    ${flexbox}
    ${gap}
`

Flex.defaultProps = {
    display: 'flex',
    flexWrap: 'wrap',
    width: 1,
}

export default Flex
