import React from 'react'

import { IonList } from '@ionic/react'

import { Text } from '@components'
import { SortDirection } from '@components'
import { useAppState } from '@hooks'

import { Client, ClientFilter, Section, SortMethod } from './Clients.types'
import { SORTERS } from './Clients.utils'
import { ClientSection } from './ClientSection'

interface Props {
    numTotal: number
    all: Client[]
    active: Client[]
    due: Client[]
    notDue: Client[]
    inactive: Client[]
    archived: Client[]
}

export const ClientSections = ({
    numTotal,
    all,
    active,
    due,
    notDue,
    inactive,
    archived,
}: Props) => {
    const { filters } = useAppState()
    const filter = filters.clients as ClientFilter
    const { method: sortMethod, direction: sortDirection, sections } = filter
    const isAlphabetical = sortMethod === SortMethod.ALPHABETICAL

    const showAll = sections.includes(Section.ALL)
    const showActive = !showAll && isAlphabetical && sections.includes(Section.ACTIVE)
    const showDue = !showAll && !isAlphabetical && sections.includes(Section.DUE)
    const showNotDue = !showAll && !isAlphabetical && sections.includes(Section.NOT_DUE)
    const showInactive = !showAll && sections.includes(Section.INACTIVE)
    const showArchived = !showAll && sections.includes(Section.ARCHIVED)
    const numDisplayedResults =
        (showAll ? all.length : 0) +
        (isAlphabetical && showActive ? active.length : 0) +
        (showDue ? due.length : 0) +
        (showNotDue ? notDue.length : 0) +
        (showInactive ? inactive.length : 0) +
        (showArchived ? archived.length : 0)

    if (numDisplayedResults === 0) {
        return (
            <Text.p textAlign="center" p={4} mt={4}>
                No clients match your search criteria.
            </Text.p>
        )
    }

    const sorter = SORTERS[sortMethod]
    all.sort(sorter)
    due.sort(sorter)
    notDue.sort(sorter)
    active.sort(sorter)
    inactive.sort(sorter)
    archived.sort(sorter)

    if (sortDirection === SortDirection.DESC) {
        all.reverse()
        due.reverse()
        notDue.reverse()
        active.reverse()
        inactive.reverse()
        archived.reverse()
    }

    return (
        <>
            <Text.p p={2} pb={0} fontSize="small" textAlign="center">
                Showing <strong>{numDisplayedResults}</strong> matching client
                {numDisplayedResults === 1 ? '' : 's'} out of {numTotal} total
                {archived.length > 0 && <> ({archived.length} archived)</>}.
            </Text.p>
            <IonList lines="full">
                {showAll && <ClientSection section={Section.ALL} clients={all} filter={filter} />}
                {showActive && <ClientSection section={Section.ACTIVE} clients={active} />}
                {showDue && <ClientSection section={Section.DUE} clients={due} />}
                {showNotDue && <ClientSection section={Section.NOT_DUE} clients={notDue} />}
                {showInactive && <ClientSection section={Section.INACTIVE} clients={inactive} />}
                {showArchived && <ClientSection section={Section.ARCHIVED} clients={archived} />}
            </IonList>
        </>
    )
}
