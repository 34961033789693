import React, { useRef } from 'react'

import { InputChangeEventDetail, InputCustomEvent } from '@ionic/core'
import { IonInput } from '@ionic/react'

import { getPhoneFormatted, getPhoneMatch } from '@utils'

export const PhoneField = ({
    name = 'phone',
    register,
    setValue,
    required = true,
}: {
    name?: string
    register: any
    setValue: any
    required?: boolean
}) => {
    const lastKey = useRef<string>()

    // TODO: this still needs a bit of work for seamless UX; i.e. if a
    //       user clicks into the middle of the string and starts typing
    //       the behavior is a bit weird. Better than before though.
    const onChange = async (e: InputCustomEvent) => {
        if (!e.target || !e.detail?.value) {
            return
        }

        let val = e.detail.value.replace(/\D/g, '')
        const inputElement = (await e.target.getInputElement()) as HTMLInputElement
        if (!inputElement) {
            return
        }

        let caret = inputElement.selectionStart

        const key = lastKey.current
        const autoFilled = key === 'Unidentified'
        if (autoFilled && val.length > 10) {
            val = val.slice(val.length - 10)
        }

        const maskValue = getPhoneMatch(val)

        if (!maskValue) {
            return
        }

        const changedValue = getPhoneFormatted(maskValue)
        setValue(name, changedValue)

        if (!autoFilled) {
            if (maskValue[2] && caret === 4 && key !== 'Backspace' && key !== 'Delete') {
                caret += 3 // Brackets are being added
            } else if (!maskValue[2] && caret === 6 && key === 'Backspace') {
                caret -= 3 // Brackets are being removed
            } else if (maskValue[3] && caret === 10 && key !== 'Backspace' && key !== 'Delete') {
                caret += 1 // Dash is being added
            } else if (!maskValue[3] && caret === 10 && lastKey.current === 'Backspace') {
                caret -= 1 // Dash is being removed
            }
            window.requestAnimationFrame(() => {
                inputElement.setSelectionRange(caret, caret)
            })
        }
    }

    return (
        <IonInput
            type="tel"
            inputMode="tel"
            placeholder="(###) ###-####"
            maxlength={14}
            onIonChange={onChange}
            onKeyDown={(e) => (lastKey.current = e.key)}
            {...register(name, { required, minLength: 14, maxLength: 14 })}
        />
    )
}
