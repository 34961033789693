import React from 'react'

import { IonChip, IonItem } from '@ionic/react'

import { Box, Flex, Heading, Text } from '@components'

import { Gift } from './Gifts.types'

const GiftChip = ({ label, color }: { label: string; color: 'danger' | 'warning' }) => (
    <Box as={IonChip} color={color} px="10px" py="6px" height="auto" fontSize="small">
        {label}
    </Box>
)

export const GiftItem = ({ gift, onClick }: { gift: Gift; onClick: (gift: Gift) => void }) => {
    const showChips = !gift.archived
    const isOutOfStock = gift.quantity <= 0
    const isLowStock = !isOutOfStock && gift.quantity < 10
    const hasDisplayedChip = showChips && (isLowStock || isOutOfStock)

    return (
        <IonItem detail={false} button onClick={() => onClick(gift)}>
            <Flex alignItems="center" justifyContent="space-between" flexWrap="nowrap">
                <Box pt={hasDisplayedChip ? 2 : 3} pb={3}>
                    <Flex alignItems="center" mb={hasDisplayedChip ? 0 : 1}>
                        <Heading as="h6"my={0} mr={2}>
                            {gift.name}
                        </Heading>
                        {showChips && (
                            <>
                                {isLowStock && <GiftChip color="warning" label="Low Inventory" />}
                                {isOutOfStock && <GiftChip color="danger" label="Out of Stock" />}
                            </>
                        )}
                    </Flex>
                    <Text.p fontSize="small" color="medium" mb={0}>
                        {gift.cost && `$${gift.cost?.toFixed(2)} - `}
                        {gift.description}
                    </Text.p>
                </Box>
                <Box textAlign="right">
                    <Text.p my={1} fontSize="large">
                        {gift.quantity}
                    </Text.p>
                </Box>
            </Flex>
        </IonItem>
    )
}
