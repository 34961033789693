import { SortDirection } from '../../components/Filter/Filter.types'

export interface Gift {
    id: string
    name: string
    description?: string
    quantity: number
    cost?: number | null
    archived?: boolean | null
}

export interface GiftFilter {
    // Sorting mechanisms
    method: SortMethod
    direction: SortDirection

    // Filtering mechanisms
    search: string
    sections: GiftSection[]
}

export enum GiftSection {
    ACTIVE = 'Active',
    ARCHIVED = 'Archived',
    ALL = 'All',
}

export enum SortMethod {
    ALPHABETICAL = 'Alphabetical',
    INVENTORY = 'Inventory',
    COST = 'Cost',
}

export const INITIAL_FILTER = {
    method: SortMethod.ALPHABETICAL,
    search: '',
    direction: SortDirection.ASC,
    // Show all sections
    sections: [GiftSection.ACTIVE, GiftSection.ARCHIVED, GiftSection.ALL],
}
