import React from 'react'

import { arrowForward } from 'ionicons/icons'

import { Box, Button, Flex, Heading, Icon, Text } from '@components'

import { Section } from './Section'

export const Pricing = () => (
    <Section bg="light" id="pricing">
        <Heading as="h2"fontWeight="900" mt={0} mb={6}>
            Select the premium plan that works for your budget.
        </Heading>
        <Flex gap={[5, 5, 6, 8]}>
            <Box
                width={[1, 'calc(50% - 12px)', 'calc(50% - 16px)', 'calc(50% - 32px)']}
                bg="white"
                borderRadius={16}
                px={[6, 4, 4, 6]}
                py={[5, 3, 3, 5]}
                border="blueHighlight"
            >
                <Flex justifyContent="space-between" alignItems="center" height={44}>
                    <Heading as="h4"mt={0} mb={0}>
                        Annual plan
                    </Heading>
                    <Box
                        borderRadius={10}
                        color="white"
                        bg="greens.2"
                        py={[3, 2, 2, 3]}
                        px={[5, 3, 3, 5]}
                        fontWeight="bold"
                    >
                        Best value
                    </Box>
                </Flex>
                <Heading as="h2"fontSize={48} fontWeight="bold">
                    <Flex alignItems="flex-start">
                        <Box lineHeight="48px">$99.</Box>
                        <Box fontSize={28}>99</Box>
                        <Box lineHeight="48px">/yr.</Box>
                    </Flex>
                </Heading>
                <Text.p mb={5}>
                    An annual subscription is $99.99 per year and automatically renews each year.
                </Text.p>
                <Button
                    variant="logoPrimary"
                    borderRadius="16px"
                    className="ion-no-margin"
                    routerLink="/login?signup=true"
                >
                    <Text.p mb={0} mr={2}>
                        Get started
                    </Text.p>
                    <Icon icon={arrowForward} size={20} />
                </Button>
            </Box>
            <Box
                width={[1, 'calc(50% - 12px)', 'calc(50% - 16px)', 'calc(50% - 32px)']}
                bg="white"
                borderRadius={16}
                px={[6, 4, 4, 6]}
                py={[5, 3, 3, 5]}
            >
                <Flex alignItems="center" height={44}>
                    <Heading as="h4"mt={0} mb={0}>
                        Monthly plan
                    </Heading>
                </Flex>
                <Heading as="h2"fontSize={48} fontWeight="bold">
                    <Flex alignItems="flex-start">
                        <Box lineHeight="48px">$9.</Box>
                        <Box fontSize={28}>99</Box>
                        <Box lineHeight="48px">/mo.</Box>
                    </Flex>
                </Heading>
                <Text.p mb={5}>
                    A monthly subscription is $9.99 per month and automatically renews each month.
                </Text.p>
                <Button
                    variant="logoPrimary"
                    borderRadius="16px"
                    className="ion-no-margin"
                    routerLink="/login?signup=true"
                >
                    <Text.p mb={0} mr={2}>
                        Get started
                    </Text.p>
                    <Icon icon={arrowForward} size={20} />
                </Button>
            </Box>
        </Flex>
    </Section>
)
