import React, { createContext, useEffect, useRef, useState } from 'react'

import { AuthStateChange, FirebaseAuthentication, User } from '@capacitor-firebase/authentication'
import { Preferences } from '@capacitor/preferences'
import { FirebaseApp, getApp, initializeApp } from 'firebase/app'
import { Auth, getAuth, indexedDBLocalPersistence, initializeAuth } from 'firebase/auth'
import { jwtDecode } from 'jwt-decode'

import { useLoginMutation } from '@graphql'
import { getSessionSpecificKey, PopByUser } from '@hooks'
import { isApp, SETUP_SUBSCRIBED, SETUP_SUBSCRIBED_STRIPE } from '@utils'

export const UserNameKey = 'user.name'

const firebaseConfig = {
    apiKey: 'AIzaSyBmnZq0-KPHU7mByT3z6UaVPIroBvFA-NE',
    authDomain: 'homefront-286517.firebaseapp.com',
    projectId: 'homefront-286517',
    storageBucket: 'homefront-286517.appspot.com',
    messagingSenderId: '1035137719011',
    appId: '1:1035137719011:web:d1fa6be1068720ce3043d6',
    measurementId: 'G-RH5K7FFMHY',
}

export const AuthContext = createContext<
    | {
          app: FirebaseApp
          authorized: boolean // Used so we know when we can access the app.
          auth: Auth
          authenticateUser: (user: User, bypass: boolean) => Promise<void>
          setSubscribed: (subscribed: boolean) => void
          user?: PopByUser
      }
    | undefined
>(undefined)

export interface FirebaseProviderProps {
    children: React.ReactNode
}

const getOrInitializeApp = () => {
    try {
        return getApp()
    } catch (e) {
        return initializeApp(firebaseConfig)
    }
}

export const AuthProvider = ({ children }: FirebaseProviderProps) => {
    const [loginUserMutation, _] = useLoginMutation()
    const [app] = useState<FirebaseApp>(getOrInitializeApp())
    const [auth] = useState<Auth>(getAuth(app))
    const [authorized, setAuthorized] = useState<boolean>(false)

    const isLoggingInRef = useRef(false)
    const [user, setUser] = useState<PopByUser>()

    const getUserFromStorage = async (): Promise<PopByUser | undefined> => {
        if (user || isLoggingInRef.current) {
            return
        }

        // See if we can pull the user out of storage
        const authToken = await Preferences.get({ key: 'authToken' })

        if (authToken.value) {
            try {
                // Set the user from the decoded token
                const decodedUser = jwtDecode(authToken.value)
                console.debug('authToken retrieved from storage and was successfully decoded.')
                return decodedUser as PopByUser
            } catch {
                // It may be corrupted; clear it out to start fresh.
                await Preferences.remove({ key: 'authToken' })
                console.debug('Caught error while pulling authToken from storage. Removing...')
                return undefined
            }
        }
    }

    const authenticateUser = async (firebaseUser: User, bypass: boolean = false) => {
        if (isLoggingInRef.current && !bypass) {
            return
        }

        isLoggingInRef.current = true

        console.debug('Authenticating firebaseUser with our server:')
        const { token } = await FirebaseAuthentication.getIdToken()
        const name = (await Preferences.get({ key: UserNameKey })).value
        console.debug('Retrieved firebase idToken')

        try {
            const variables = {
                login: { idToken: token, displayName: firebaseUser.displayName || name },
            }
            const { data, errors } = await loginUserMutation({ variables })

            if (data) {
                // We were able to successfully authenticate with the backend
                const appUser = {
                    ...firebaseUser,
                    displayName: firebaseUser.displayName || name,
                    stripeSubscriptionId: data.login.stripeSubscriptionId,
                    stripeCustomerId: data.login.stripeCustomerId,
                    subscribed: data.login.subscribed || false,
                    isAdmin: data.login.isAdmin || undefined,
                }
                setUser(appUser)

                // Update local storage
                await Promise.all([
                    Preferences.set({ key: 'authToken', value: data.login.access_token }),
                    async () => {
                        if (appUser.stripeSubscriptionId?.startsWith('sub_')) {
                            await Preferences.set({
                                key: SETUP_SUBSCRIBED_STRIPE,
                                value: '1',
                            })
                        }
                    },
                    async () => {
                        if (appUser.subscribed) {
                            await Preferences.set({
                                key: SETUP_SUBSCRIBED,
                                value: '1',
                            })
                        }
                    },
                ])

                setAuthorized(true)
            } else {
                console.error('Encountered errors authenticating with backend: ', errors)
                setAuthorized(false)
            }
        } catch (err) {
            console.error('Encountered exception while logging in user.', err)
            setAuthorized(false)
        } finally {
            if (!bypass) {
                isLoggingInRef.current = false
            }
        }
    }

    useEffect(() => {
        const attemptToLoginUser = async () => {
            isLoggingInRef.current = true
            // Try to get the user from storage
            const storageUser = await getUserFromStorage()
            if (storageUser) {
                // We were able to pull the user out of storage. Set the state and
                // nothing else to do
                setUser(storageUser)
            } else {
                // See if we have a logged in Firebase user
                let getFirebaseUserResult = null
                try {
                    getFirebaseUserResult = await FirebaseAuthentication.getCurrentUser()
                } catch {
                    console.debug('Unable to get firebase user. Assuming null')
                }

                if (getFirebaseUserResult && getFirebaseUserResult.user) {
                    // We have a firebase user; login to the backend.
                    await authenticateUser(getFirebaseUserResult.user, true)
                    setAuthorized(true)
                } else {
                    setAuthorized(false)
                }
            }

            isLoggingInRef.current = false
        }

        // This is only use on-device, so we can restore a user's
        // session when they re-open the app after killing it.
        if (!user && !isLoggingInRef.current) {
            attemptToLoginUser()
        }
        // @ts-ignore
    }, [])

    useEffect(() => {
        // If firebase authentication changes in any way we need to grab
        // the user from the server as well, even if it's the same.
        FirebaseAuthentication.addListener(
            'authStateChange',
            async ({ user: firebaseUser }: AuthStateChange) => {
                if (firebaseUser && !user && !isLoggingInRef.current) {
                    console.debug('authStateChange has a user but we have not signed them in yet.')
                    // Firebase has confirmed identity. Now authenticate
                    // with the backend (which will either find the user
                    // or create a new one).
                    await authenticateUser(firebaseUser)
                }
            },
        )

        return () => {
            FirebaseAuthentication.removeAllListeners()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user])

    const setSubscribed = (subscribed: boolean) => {
        setUser((curUser) => (curUser ? { ...curUser, subscribed } : undefined))
    }

    return (
        <AuthContext.Provider
            value={{
                app,
                auth,
                authenticateUser,
                setSubscribed,
                authorized,
                user,
            }}
        >
            {children}
        </AuthContext.Provider>
    )
}
