import React, { useEffect, useState } from 'react'

import {
    IonContent,
    IonGrid,
    IonList,
    IonModal,
    IonRefresher,
    IonRefresherContent,
    IonSearchbar,
    IonSpinner,
    RefresherEventDetail,
} from '@ionic/react'
import { RouteComponentProps, useHistory } from 'react-router'

import { Box, Button, Centered, Heading, Page, Text } from '@components'
import { Template } from '@graphql'
import { useAppState } from '@hooks'
import { isPhone } from '@utils'

import { AddTemplateButton } from './AddTemplateButton'
import { TemplateCard } from './TemplateCard'
import { TemplateForm } from './TemplateForm'

export const Templates = (props: RouteComponentProps) => (
    <Page title="Messages">
        <IonContent>
            <TemplatesContent {...props} />
        </IonContent>
    </Page>
)

const matchTemplate = (filter: string, template: Template) => {
    const _filter = filter.toLowerCase()
    const check = (value: string) => value.toLowerCase().includes(_filter)

    return check(template.name) || check(template.message)
}

export const TemplatesContent = ({ match }: RouteComponentProps) => {
    const history = useHistory()
    const [filter, setFilter] = useState('')
    const [selectedTemplate, setSelectedTemplate] = useState<Template>()
    const { templates: _templates } = useAppState()
    const { data: templates, loading, error, refetch } = _templates

    const onTemplateClick = (template: Template) => setSelectedTemplate(template)

    useEffect(() => {
        // Reload templates whenever this component is shown.
        if (history.location.pathname === match.url) {
            refetch()
        }
    }, [history.location.pathname, match.url, refetch])

    if (loading) {
        return (
            <Centered>
                <IonSpinner />
            </Centered>
        )
    }

    if (error) {
        return (
            <Centered>
                <Text.p>There was an error fetching your templates</Text.p>
                <Button variant="primary" onClick={async () => await refetch()}>
                    Retry
                </Button>
            </Centered>
        )
    }

    if (!loading && templates.length === 0) {
        return (
            <Centered>
                <Heading as="h4">You haven't created any templates yet</Heading>
                <AddTemplateButton refetch={refetch} />
            </Centered>
        )
    }

    return (
        <>
            <IonGrid fixed={!isPhone}>
                <IonRefresher
                    slot="fixed"
                    onIonRefresh={async (event: CustomEvent<RefresherEventDetail>) => {
                        await refetch()
                        event.detail.complete()
                    }}
                >
                    <IonRefresherContent />
                </IonRefresher>
                <Box borderBottom="base" borderBottomWidth={2}>
                    <IonSearchbar value={filter} onIonChange={(e) => setFilter(e.detail.value!)} />
                </Box>
                <IonList lines="full">
                    {templates
                        .filter((template) => matchTemplate(filter, template))
                        .map((template, index) => (
                            <TemplateCard
                                key={`template-${template.id}`}
                                template={template}
                                onClick={onTemplateClick}
                            />
                        ))}
                </IonList>
            </IonGrid>
            <AddTemplateButton refetch={refetch} floating />
            <EditTemplateModal
                template={selectedTemplate}
                numTemplates={templates.length}
                onClose={() => setSelectedTemplate(undefined)}
                onSuccess={() => {
                    setSelectedTemplate(undefined)
                    refetch()
                }}
            />
        </>
    )
}

const EditTemplateModal = ({
    template,
    numTemplates,
    onClose,
    onSuccess,
}: {
    template: Template | undefined
    numTemplates: number
    onClose: () => void
    onSuccess: () => void
}) => (
    <IonModal isOpen={!!template} onDidDismiss={() => onClose()}>
        <TemplateForm
            template={template}
            numTemplates={numTemplates}
            onSuccess={onSuccess}
            onCancel={onClose}
        />
    </IonModal>
)
