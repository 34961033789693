import React from 'react'

import { IonItemDivider } from '@ionic/react'

import { Box } from '@components'

import { GiftItem } from './GiftItem'
import { Gift, GiftSection as Section } from './Gifts.types'

export const GiftSection = ({
    section,
    gifts,
    onGiftClick,
    showHeader,
}: {
    section: string
    gifts: Gift[]
    onGiftClick: (gift: Gift) => void
    showHeader?: boolean
}) => {
    if (gifts.length === 0) {
        return null
    }

    return (
        <>
            {showHeader && <IonItemDivider sticky>{section}</IonItemDivider>}
            <Box opacity={section === Section.ARCHIVED ? 0.6 : 1}>
                {gifts.map((gift, index) => (
                    <GiftItem key={`gift-${index}`} gift={gift} onClick={onGiftClick} />
                ))}
            </Box>
        </>
    )
}
