import React from 'react'

import styled from 'styled-components'
import {
    border,
    BorderProps,
    color,
    ColorProps,
    layout,
    LayoutProps,
    position,
    PositionProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
} from 'styled-system'

export type BoxProps = SpaceProps &
    BorderProps &
    ColorProps &
    LayoutProps &
    PositionProps &
    TypographyProps & { as?: React.ElementType }

const Box = styled.div<BoxProps>`
    ${border}
    ${color}
    ${space}
    ${layout}
    ${position}
    ${typography}
`

export default Box
