import React from 'react'

import { IonPage, IonRouterOutlet } from '@ionic/react'
import { RouteComponentProps } from 'react-router'
import { Route } from 'react-router-dom'

import { Dashboard } from './Dashboard'
import { ViewPopby } from './ViewPopby'

export const DashboardRoutes = ({ match }: RouteComponentProps) => (
    <IonPage>
        <IonRouterOutlet>
            <Route path={`${match.url}/:popbyId`} exact component={ViewPopby} />
            <Route path={`${match.url}`} exact component={Dashboard} />
        </IonRouterOutlet>
    </IonPage>
)
