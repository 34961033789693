import { Browser } from '@capacitor/browser'
import { useIonAlert } from '@ionic/react'

import { useGetBillingPortalSessionLazyQuery } from '@graphql'

import { useAppState } from './useAppState'

const APPLE_SUBSCRIPTION_ID = 'apple'

export const useSubscription = () => {
    const { user } = useAppState()
    const [showAlert] = useIonAlert()
    const [getStripeSession] = useGetBillingPortalSessionLazyQuery()

    const manage = async () => {
        if (user && !user.data?.subscribed) {
            await showAlert('You are not currently subscribed.')
            return
        }

        const subscriptionId = user?.data?.stripeSubscriptionId

        if (!user || !subscriptionId) {
            await showAlert('Unable to determine subscription status. Please try again later.')
            return
        }

        if (subscriptionId === APPLE_SUBSCRIPTION_ID) {
            await Browser.open({ url: 'https://apps.apple.com/account/subscriptions' })
        } else {
            const response = await getStripeSession()

            if (response.data?.getPortalSession?.url) {
                await Browser.open(response.data?.getPortalSession)
            } else {
                await showAlert(
                    'There was an error loading your subscription. ' +
                        'Please try again and contact us if the problem persists.',
                )
            }
        }
    }

    return { manage }
}
