import React from 'react'

import { IonFooter, IonToolbar, useIonAlert } from '@ionic/react'
import { callSharp, mailSharp } from 'ionicons/icons'
import { useHistory } from 'react-router'

import { Box, Button, Flex, Icon } from '@components'
import { Client } from '@graphql'
import { useAppState } from '@hooks'
import { getTelLink, isApp, isWebsite } from '@utils'

import { launchNavigation } from '../PopbyClient'

export const ClientFooter = ({ client }: { client: Client }) => {
    const [showAlert] = useIonAlert()
    const history = useHistory()
    const { gifts, globalHistory } = useAppState()

    if (isWebsite) {
        return null
    }

    const startPopby = async () => {
        if (gifts.data.stocked.length === 0) {
            await showAlert({
                header: 'No gifts',
                message: "You haven't added any gifts yet. Add a gift before starting a pop-by.",
                buttons: [
                    {
                        text: 'OK',
                        role: 'confirm',
                        handler: () => globalHistory.push('/app/gifts?add=true'),
                    },
                ],
                backdropDismiss: false,
            })
        } else if (isApp) {
            await showAlert({
                header: 'Client Pop-By',
                message: 'Do you want navigation instructions?',
                buttons: [
                    {
                        text: 'No',
                        handler: () => history.push(`/app/clients/view/${client.id}/popby`),
                    },
                    {
                        text: 'Yes',
                        role: 'confirm',
                        handler: () => {
                            launchNavigation(client.id, client.address)
                            history.push(`/app/clients/view/${client.id}/popby`)
                        },
                    },
                ],
                backdropDismiss: false,
            })
        } else {
            history.push(`/app/clients/view/${client.id}/popby`)
        }
    }

    return (
        <IonFooter>
            <IonToolbar color="light" className="ion-no-padding" style={{ padding: 0 }}>
                <Flex>
                    {client.phone ? (
                        <Box
                            as={Button}
                            width={75}
                            fill="clear"
                            href={getTelLink(client.phone)}
                            borderRight="base"
                            className="ion-no-margin"
                        >
                            <Icon icon={callSharp} color="logo-primary" size={28} />
                        </Box>
                    ) : null}
                    {client.email ? (
                        <Box
                            as={Button}
                            width={75}
                            fill="clear"
                            href={`mailto:${client.email}`}
                            borderRight="base"
                            className="ion-no-margin"
                        >
                            <Icon icon={mailSharp} color="logo-primary" size={28} />
                        </Box>
                    ) : null}
                    <Box
                        as={Button}
                        variant="primary"
                        style={{ flexGrow: 1 }}
                        height="47.6px"
                        m={0}
                        onClick={startPopby}
                    >
                        Start Pop-By
                    </Box>
                </Flex>
            </IonToolbar>
        </IonFooter>
    )
}
