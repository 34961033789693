import React from 'react'

import { ApolloProvider } from '@apollo/client'
import { IonApp, IonRouterOutlet } from '@ionic/react'
import { IonReactRouter } from '@ionic/react-router'
import 'cordova-plugin-purchase'

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css'
import '@ionic/react/css/display.css'
import '@ionic/react/css/flex-utils.css'
import '@ionic/react/css/float-elements.css'

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css'

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css'
import '@ionic/react/css/structure.css'
import '@ionic/react/css/text-alignment.css'
import '@ionic/react/css/text-transformation.css'
import '@ionic/react/css/typography.css'
import { AppThemeProvider } from '@reducers'
import theme, { initTheme } from '@theme'
import { Route } from 'react-router-dom'
import { ThemeProvider } from 'styled-components'

import { AppProvider, AuthProvider } from '@components'
import { Affiliates, AppURLListener, HomePage, SetupSubscription, SubscriptionSuccess } from '@pages'
import { getApolloClient, isWebsite } from '@utils'

import { AppRoutes } from './AppRoutes'

/* Custom App-Wide Theming */
import './theme/variables.css'

initTheme()

export const App: React.FC = () => (
    <IonApp style={{ top: -5 }}>
        <AppThemeProvider>
            <ThemeProvider theme={theme}>
                <ApolloProvider client={getApolloClient()}>
                    <IonReactRouter>
                        <IonRouterOutlet>
                            <AuthProvider>
                                <AppProvider>
                                    <AppURLListener />
                                    <AppRoutes />
                                    <Route path="/subscribe" component={SetupSubscription} />
                                    {isWebsite && <Affiliates />}
                                    {isWebsite && (
                                        <Route
                                            path="/subscribe/success"
                                            component={SubscriptionSuccess}
                                        />
                                    )}
                                    {isWebsite && <Route path="/" exact component={HomePage} />}
                                </AppProvider>
                            </AuthProvider>
                        </IonRouterOutlet>
                    </IonReactRouter>
                </ApolloProvider>
            </ThemeProvider>
        </AppThemeProvider>
    </IonApp>
)
