import React from 'react'

import { IonImg } from '@ionic/react'

import { isPhone, isWebsite } from '@utils'

import { Box, Flex, Text } from './Design'

export const DownloadApp = () => {
    if (!(isPhone && isWebsite)) return null

    return (
        <>
            <Text.p color="medium" textAlign="center">
                Download the app
            </Text.p>
            <Flex
                justifyContent="center"
                as="a"
                href="https://apps.apple.com/us/app/pop-by-pro/id1641621318"
                target="_blank"
                rel="noopener noreferrer"
                mb={5}
            >
                <IonImg
                    src="/assets/home/Download_on_the_App_Store_Badge.svg"
                    alt="Download on the App Store"
                    style={{ width: '150px' }}
                />
            </Flex>
        </>
    )
}
