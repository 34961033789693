import React, { useState } from 'react'
import { useForm } from 'react-hook-form'
import { IonSpinner, useIonAlert } from '@ionic/react'

import { Box, Button, Centered, ErrorMessage, Flex, Heading, Text } from '@components'

import { useCreateClientUploadMutation, useSendClientUploadMutation } from '@graphql'

interface ImportHelpClientsInput {
    file: FileList
}

const MAX_FILE_SIZE = 1024 * 10000
const ERROR_NO_FILE = 'Please select a file to import.'
const ERROR_MULTIPLE_FILES = 'Please select a single file to import.'
const ERROR_TOO_BIG = 'Please ensure your file is 10 MB or less.'
const ERROR_GENERIC =
    'There was an error uploading your file. Please try again and if issues persist reach out to support.'

export const ImportClientsHelp = () => {
    const [processing, setProcessing] = useState(false)
    const {
        handleSubmit: handleHelpSubmit,
        register: registerHelp,
        formState: helpFormState,
    } = useForm<ImportHelpClientsInput>()
    const [showAlert] = useIonAlert()
    const [createClientUpload] = useCreateClientUploadMutation()
    const [sendClientUpload] = useSendClientUploadMutation()

    const fail = async (reason: string) => {
        console.warn(reason)
        setProcessing(false)
        await showAlert(reason)
    }

    if (processing) {
        return (
            <Centered>
                <IonSpinner />
                <Text.p mt={3}>Importing contacts. This may take a couple minutes.</Text.p>
                <Text.p>Do not leave or refresh this page.</Text.p>
            </Centered>
        )
    }

    const submit = async (data: ImportHelpClientsInput) => {
        try {
            setProcessing(true)
            const files = data.file

            if (!files.length) return fail(ERROR_NO_FILE)
            else if (files.length > 1) return fail(ERROR_MULTIPLE_FILES)
            else if (files[0].size > MAX_FILE_SIZE) return fail(ERROR_TOO_BIG)

            // Generate the pre-signed URL
            const urlResponse = await createClientUpload({ variables: { fileType: files[0].type } })
            if (!urlResponse.data?.createClientUpload.key) {
                throw new Error('Unable to generate upload URL')
            }

            const { url, key } = urlResponse.data.createClientUpload

            // Upload the file to S3
            const response = await fetch(url, {
                body: files[0],
                method: 'PUT',
                headers: { 'Content-Type': files[0].type },
            })

            if (!response.ok) {
                return fail(ERROR_GENERIC)
            }

            // Let's send the file to support
            const sendResponse = await sendClientUpload({
                variables: { filename: files[0].name, key },
            })
            if (sendResponse.data?.sendClientUpload.key) {
                setProcessing(false)
                await showAlert(
                    'Your file has been sent to our support team. Please allow 48 hours for clients to be imported.',
                )
            } else {
                return fail(ERROR_GENERIC)
            }
        } catch (e) {
            console.error(e)
            return fail(ERROR_GENERIC)
        }
    }

    return (
        <Box p={3}>
            <Heading as="h2"mb={4}>Not a spreadsheet jockey?</Heading>
            <Text.p>
                Simply upload your contacts here and don't worry about the formatting. We'll make
                sure the data is formatted correctly and uploaded into to your Pop-By Pro app.
                Remember, the first and last name, address and mobile number are minimally required.
                Give us about a day but not more than 48 hours to get your data loaded. We’ll let
                you know when it's completed!
            </Text.p>
            <Flex alignItems="center" borderTop="base" pt={3}>
                <Flex alignItems="center" gap={3} flexGrow={1} width={[1, 1, 'auto']}>
                    <ErrorMessage error={helpFormState.errors.file} />
                    <input type="file" {...registerHelp('file', { required: true })} />
                </Flex>
                <Flex
                    flexGrow={0}
                    width={[1, 1, 'auto']}
                    mt={[5, 5, 0]}
                    justifyContent={['center', 'center', 'flex-end']}
                >
                    <Button variant="primary" onClick={handleHelpSubmit(submit)}>
                        Send to Support
                    </Button>
                </Flex>
            </Flex>
        </Box>
    )
}
