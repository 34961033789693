import React from 'react'

import { IonContent, IonGrid } from '@ionic/react'

import { Box } from '@components'
import { isPhone } from '@utils'

type IonContentProps = React.ComponentProps<typeof IonContent>

const Content = ({
    forceFullWidth,
    children,
    ...rest
}: IonContentProps & { forceFullWidth?: boolean }) => {
    if (isPhone || forceFullWidth) {
        return <IonContent {...rest}>{children}</IonContent>
    }

    return (
        <IonContent {...rest}>
            <Box as={IonGrid} fixed height="100%">
                {children}
            </Box>
        </IonContent>
    )
}

export default Content
