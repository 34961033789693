import React from 'react'

import { IonPage, IonRouterOutlet } from '@ionic/react'
import { RouteComponentProps } from 'react-router'
import { Route } from 'react-router-dom'

import { FAQItem } from './FAQItem'
import { FAQList } from './FAQList'

export const FAQsRoutes = ({ match }: RouteComponentProps) => (
    <IonPage>
        <IonRouterOutlet>
            <Route path={`${match.url}/:key`} exact component={FAQItem} />
            <Route path={`${match.url}`} exact component={FAQList} />
        </IonRouterOutlet>
    </IonPage>
)
