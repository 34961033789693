import React, { useState } from 'react'

import {
    IonCheckbox,
    IonChip,
    IonIcon,
    IonItem,
    IonItemDivider,
    IonLabel,
    IonList,
    IonSearchbar,
} from '@ionic/react'
import { closeCircle } from 'ionicons/icons'

import { Box, Button, Filter, Flex, Heading, SortDirection, Text } from '@components'
import { getUpdatedSelections } from '@components'
import { useAppState } from '@hooks'

import {
    Client,
    ClientFilter,
    ClientType,
    ClientTypes,
    INITIAL_FILTER,
    Section,
    SortMethod,
} from './Clients.types'
import { stateShortToLong } from './Clients.utils'

type SetFilter = (key: string, value: any) => void

const AllSections = [Section.DUE, Section.NOT_DUE, Section.INACTIVE, Section.ARCHIVED, Section.ALL]

export const ClientFilterBar = ({ clients }: { clients: Client[] }) => {
    const { filters, setFilter } = useAppState()
    const filter = filters.clients as ClientFilter
    const [cityZipSubDisplay, setCityZipSubDisplay] = useState<boolean>(false)

    const agents = new Set<string>()
    const cities = new Set<string>()
    const zipCodes = new Set<string>()
    const states = new Set<string>()
    const types = new Set<ClientType>()

    clients.forEach((client) => {
        if (client.agentName) agents.add(client.agentName)
        if (client.address.zip) zipCodes.add(client.address.zip)
        if (client.address.city) cities.add(`${client.address.city}, ${client.address.state}`)
        if (client.address.state) states.add(stateShortToLong[client.address.state])

        if (client.type) types.add(client.type)
        else types.add(ClientType.NONE)
    })

    return (
        <Filter
            name="clients"
            initial={INITIAL_FILTER}
            sortMethods={[SortMethod.ALPHABETICAL, SortMethod.DISTANCE, SortMethod.LAST_POP_BY]}
            /**
             * When displaying the clients alphabetically, it didn't really make
             * sense to group them into the Due/Not Due sections, which is why an
             * `Active` section was added. In the alphabetical display, we only show
             * Active and Inactive. The other displays will omit Active and instead
             * show Due, Not Due, and Inactive.
             */
            sortMethodSections={{
                [SortMethod.ALPHABETICAL]: [
                    Section.ACTIVE,
                    Section.INACTIVE,
                    Section.ARCHIVED,
                    Section.ALL,
                ],
                [SortMethod.DISTANCE]: AllSections,
                [SortMethod.LAST_POP_BY]: AllSections,
            }}
            /**
             * Note that when the sort method is changed we revert to the ascending
             * sort direction to help the user. It would be annoying to have been
             * viewing by Last Pop-Bys with `Least recent visits first` and switch
             * to viewing by distance with the sort direction still descending
             * (furthest clients first).
             */
            sortDirectionDescriptions={{
                [SortMethod.ALPHABETICAL]: {
                    [SortDirection.ASC]: 'A-Z',
                    [SortDirection.DESC]: 'Z-A',
                },
                [SortMethod.DISTANCE]: {
                    [SortDirection.ASC]: 'Closest clients first',
                    [SortDirection.DESC]: 'Furthest clients first',
                },
                [SortMethod.LAST_POP_BY]: {
                    [SortDirection.ASC]: 'Most recent visits first',
                    [SortDirection.DESC]: 'Least recent visits first',
                },
            }}
            hideDefaultOptions={cityZipSubDisplay}
        >
            {!cityZipSubDisplay && (
                <>
                    <PickClientTypes
                        options={Array.from<ClientType>(types)}
                        filter={filter}
                        setFilter={setFilter}
                    />
                    <PickAgents
                        options={Array.from<string>(agents)}
                        filter={filter}
                        setFilter={setFilter}
                    />
                </>
            )}
            <PickCityZips
                cities={Array.from<string>(cities)}
                zipCodes={Array.from<string>(zipCodes)}
                states={Array.from<string>(states)}
                filter={filter}
                setFilter={setFilter}
                cityZipSubDisplay={cityZipSubDisplay}
                setCityZipSubDisplay={setCityZipSubDisplay}
            />
        </Filter>
    )
}

interface PickerProps {
    filter: ClientFilter
    setFilter: SetFilter
}

interface OptionPickerProps extends PickerProps {
    options: string[]
}

const PickClientTypes = ({
    options,
    filter,
    setFilter,
}: OptionPickerProps & {
    options: ClientType[]
}) => {
    const displayedOptions = ClientTypes.filter((type) => options.includes(type))

    return (
        <>
            <Heading as="h5"mt={5}>Client Types</Heading>
            {displayedOptions.map((clientType, idx) => (
                <IonItem
                    key={clientType}
                    lines={idx === displayedOptions.length - 1 ? 'none' : undefined}
                >
                    <IonLabel style={{ marginRight: 0 }}>{clientType}</IonLabel>
                    <IonCheckbox
                        slot="start"
                        value={clientType}
                        checked={filter.clientTypes.includes(clientType)}
                        onIonChange={(e) => {
                            const selected = e.detail.checked
                            setFilter('clients', (prev: ClientFilter) => ({
                                ...prev,
                                clientTypes: getUpdatedSelections(
                                    prev.clientTypes,
                                    clientType,
                                    selected,
                                ),
                            }))
                        }}
                    />
                </IonItem>
            ))}
        </>
    )
}

const PickCityZips = ({
    cities,
    zipCodes,
    states,
    filter,
    setFilter,
    cityZipSubDisplay,
    setCityZipSubDisplay,
}: PickerProps & {
    cities: string[]
    zipCodes: string[]
    states: string[]
    cityZipSubDisplay: boolean
    setCityZipSubDisplay: React.Dispatch<React.SetStateAction<boolean>>
}) => {
    const [query, setQuery] = useState<string>('')

    const match = (value: string) =>
        query !== '' && !filter.terms.includes(value)
            ? value.toLowerCase().includes(query.toLowerCase())
            : false

    const displayedCities = cities.filter(match).sort()
    const displayedZipCodes = zipCodes.filter(match).sort()
    const stateOptions = states.filter((term) => !filter.terms.includes(term))

    return (
        <>
            <Heading as="h5"mt={5} mb={1}>
                Locations
            </Heading>
            <Text.p fontSize="small" mb={0}>
                Note: These selections are additive (clients will be shown if any match).
            </Text.p>
            <Flex flexWrap="nowrap" alignItems="center" gap={3}>
                <IonSearchbar
                    class="ion-no-padding"
                    placeholder="Search..."
                    onIonChange={(e) => setQuery(e.detail.value!)}
                    onIonFocus={() => setCityZipSubDisplay(true)}
                    value={query}
                />
                {cityZipSubDisplay && (
                    <Button
                        variant="linkPrimary"
                        fill="outline"
                        height="36px"
                        onClick={() => {
                            setQuery('')
                            setCityZipSubDisplay(false)
                        }}
                    >
                        Done Searching
                    </Button>
                )}
            </Flex>

            {filter.terms.length > 0 && (
                <Box pb={2} mt={-2}>
                    {filter.terms.map((term) => (
                        <IonChip key={term}>
                            <IonLabel>{term}</IonLabel>
                            <IonIcon
                                icon={closeCircle}
                                onClick={() =>
                                    setFilter('clients', (prev: ClientFilter) => ({
                                        ...prev,
                                        terms: getUpdatedSelections(prev.terms, term, false),
                                    }))
                                }
                            />
                        </IonChip>
                    ))}
                </Box>
            )}
            <IonList>
                {cityZipSubDisplay && stateOptions.length > 0 && (
                    <>
                        <IonItemDivider>States</IonItemDivider>
                        {stateOptions.map((term) => (
                            <IonItem key={term} lines="full">
                                <IonLabel>{term}</IonLabel>
                                <IonCheckbox
                                    slot="start"
                                    value={term}
                                    onIonChange={(e) => {
                                        const selected = e.detail.checked
                                        setFilter('clients', (prev: ClientFilter) => ({
                                            ...prev,
                                            terms: getUpdatedSelections(prev.terms, term, selected),
                                        }))

                                        if (selected) {
                                            setQuery('')
                                        }
                                    }}
                                />
                            </IonItem>
                        ))}
                    </>
                )}

                {cityZipSubDisplay &&
                (displayedCities.length > 0 || displayedZipCodes.length > 0) ? (
                    <>
                        <LocationSection
                            header="Cities"
                            options={displayedCities}
                            filter={filter}
                            setFilter={setFilter}
                            setQuery={setQuery}
                        />
                        <LocationSection
                            header="Zip Codes"
                            options={displayedZipCodes}
                            filter={filter}
                            setFilter={setFilter}
                            setQuery={setQuery}
                        />
                    </>
                ) : query !== '' ? (
                    <Text.p mt={4} textAlign="center">
                        No results found
                    </Text.p>
                ) : (
                    <Text.p mt={4} textAlign="center">
                        Start typing to filter results...
                    </Text.p>
                )}
            </IonList>
        </>
    )
}

const LocationSection = ({
    header,
    options,
    setFilter,
    setQuery,
}: OptionPickerProps & {
    header: string
    setQuery: React.Dispatch<React.SetStateAction<string>>
}) => {
    if (options.length === 0) {
        return null
    }

    return (
        <>
            <IonItemDivider>{header}</IonItemDivider>
            {options.length > 0 &&
                options.map((term, idx) => (
                    <IonItem key={term} lines={idx === options.length - 1 ? 'none' : 'full'}>
                        <IonLabel>{term}</IonLabel>
                        <IonCheckbox
                            slot="start"
                            value={term}
                            onIonChange={(e) => {
                                const selected = e.detail.checked
                                setFilter('clients', (prev: ClientFilter) => ({
                                    ...prev,
                                    terms: getUpdatedSelections(prev.terms, term, selected),
                                }))

                                if (selected) {
                                    setQuery('')
                                }
                            }}
                        />
                    </IonItem>
                ))}
        </>
    )
}

const PickAgents = ({ options, filter, setFilter }: OptionPickerProps) => {
    if (options.length < 2) {
        return null
    }

    return (
        <>
            <Heading as="h5"mt={5}>Agent</Heading>
            <IonItem>
                <IonLabel style={{ marginRight: 0 }}>All (including blank)</IonLabel>
                <IonCheckbox
                    slot="start"
                    value="All"
                    checked={filter.agents.includes('All')}
                    onIonChange={(e) => {
                        const selected = e.detail.checked
                        setFilter('clients', (prev: ClientFilter) => ({
                            ...prev,
                            agents: selected ? ['All'] : options,
                        }))
                    }}
                />
            </IonItem>
            {options.sort().map((agent, idx) => (
                <IonItem key={agent} lines={idx === options.length - 1 ? 'none' : undefined}>
                    <IonLabel style={{ marginRight: 0 }}>{agent}</IonLabel>
                    <IonCheckbox
                        slot="start"
                        value={agent}
                        checked={filter.agents.includes(agent) || filter.agents.includes('All')}
                        onIonChange={(e) => {
                            const selected = e.detail.checked
                            setFilter('clients', (prev: ClientFilter) => ({
                                ...prev,
                                agents: getUpdatedSelections(prev.agents, agent, selected),
                            }))
                        }}
                        disabled={agent !== 'All' && filter.agents.includes('All')}
                    />
                </IonItem>
            ))}
        </>
    )
}
