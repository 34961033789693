import { DbUser } from '@components'
import { Address } from '@graphql'

export const getDateDisplay = (val: Date | string) =>
    (typeof val === 'string' ? new Date(val) : val).toLocaleDateString(undefined, {
        dateStyle: 'long',
    })

export const getTimeDisplay = (val: Date | string) =>
    (typeof val === 'string' ? new Date(val) : val).toLocaleTimeString(undefined, {
        timeStyle: 'short',
    })

export const getDateTimeDisplay = (val: Date | string) =>
    `${getDateDisplay(val)} ${getTimeDisplay(val)}`

export const addressToString = (address: Address) =>
    [address.street, address.city, address.state, address.zip]
        .reduce((curr, value) => `${curr}${value ? value + ' ' : ''}`, '')
        .trim()

export const stripPhone = (phone: string) => phone.replace(/\W/g, '')
export const getTelLink = (phone: string) => `tel:${stripPhone(phone)}`

export const fillTemplate = (
    user: DbUser | undefined,
    template: string,
    values: {
        firstName?: string
        lastName?: string
        spouseFirstName?: string | null
        spouseLastName?: string | null
    },
) => {
    // Morning is before noon, afternoon is before 6p, evening is after.
    const hour = new Date().getHours()
    const timeOfDay = hour < 12 ? 'morning' : hour < 18 ? 'afternoon' : 'evening'

    const firstNameDisplay = values.spouseFirstName
        ? `${values.firstName} & ${values.spouseFirstName}`
        : values.firstName

    return template
        .replace(/SIGNATURE/g, user?.signature || '')
        .replace(/FIRST_NAME/g, firstNameDisplay || '')
        .replace(/TIME_OF_DAY/g, timeOfDay)
}
