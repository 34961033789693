/**
 * Used for section/clientTypes/agent selections, so we can safely
 * check & uncheck fields but also hit the reset button and
 * everything works as we expect it to.
 */
export const getUpdatedSelections = <T>(
    previousValues: T[],
    changedValue: T,
    selected: boolean,
) => {
    // Check to see if we can skip this update (i.e. we hit the
    // reset button and the change isn't coming from the field).
    if (selected === previousValues.includes(changedValue)) {
        return previousValues
    }

    // Remove or add to the list.
    return previousValues.includes(changedValue)
        ? previousValues.filter((x) => x !== changedValue)
        : [...previousValues, changedValue]
}
