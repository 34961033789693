import React, { useEffect, useState } from 'react'

import { IonSpinner } from '@ionic/react'
import { RouteComponentProps, useHistory } from 'react-router'

import { AddressMap, Centered, Content, Page } from '@components'
import { useGetClientLazyQuery } from '@graphql'

import { ClientFooter } from './ClientFooter'
import { ClientPopbys } from './ClientPopbys'
import { ClientQuickDetails } from './ClientQuickDetails'
import { ClientSettingsButton } from './ClientSettingsButton'

interface Props
    extends RouteComponentProps<{
        clientId: string
    }> {}

export const ViewClient = ({ match }: Props) => {
    const [refresh, setRefresh] = useState<Date>(new Date())
    const { clientId } = match.params
    const [getClient, { data, refetch }] = useGetClientLazyQuery({ variables: { clientId } })
    const client = data?.getClient
    const history = useHistory()

    useEffect(() => {
        getClient()
    }, [getClient, clientId])

    useEffect(() => {
        // Reload whenever this component is shown.
        if (history.location.pathname === match.url) {
            refetch()
            setRefresh(new Date())
        }
    }, [history.location.pathname, match.url, refetch])

    if (!client) {
        return (
            <Page title="View Client" showBackButton SettingsButton={<></>}>
                <Content>
                    <Centered>
                        <IonSpinner />
                    </Centered>
                </Content>
            </Page>
        )
    }

    return (
        <Page
            title={`${client.firstName} ${client.lastName}`}
            showBackButton
            SettingsButton={<ClientSettingsButton client={client} refetch={refetch} />}
        >
            <Content>
                <AddressMap address={client.address} />
                <ClientQuickDetails client={client} refetch={refetch} />
                <ClientPopbys client={client} refresh={refresh} />
            </Content>
            {!client.archived && <ClientFooter client={client} />}
        </Page>
    )
}
