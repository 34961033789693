import { GiftFilter, SortMethod } from './Gifts.types'
import { Gift } from './Gifts.types'

/**
 * See if the filter deems the given gift worthy to be displayed.
 */
export const matchGift = (filter: GiftFilter, gift: Gift) => {
    const _filter = filter.search.toLowerCase()
    const check = (value: string) => value.toLowerCase().includes(_filter)

    return (
        check(gift.name) ||
        check(`$${gift.cost?.toFixed(2)}`) ||
        (gift.description && check(gift.description))
    )
}

/**
 * Sorts by full names in ascending alphabetical order.
 */
const sortByName = (a: Gift, b: Gift) => a.name.localeCompare(b.name)

/**
 * Sorts by the current inventory of each gift.
 *
 * Similarly stocked gifts are resolved by name in ascending
 * alphabetical order.
 */
const sortByQuantity = (a: Gift, b: Gift) =>
    a.quantity !== b.quantity ? b.quantity! - a.quantity! : sortByName(a, b)

/**
 * Sorts by product cost. Gifts that don't have a cost are evaluated as
 * having cost $0.00.
 *
 * Both null? Sort by name in ascending alphabetical order.
 * One null? Sort the one without above the other (should go to top)
 * Otherwise, compare the costs putting less expensive first.
 */
const sortByCost = (a: Gift, b: Gift) =>
    !a.cost && !b.cost ? sortByName(a, b) : !a.cost ? -1 : !b.cost ? 1 : a.cost - b.cost

export const SORTERS = {
    [SortMethod.ALPHABETICAL]: sortByName,
    [SortMethod.INVENTORY]: sortByQuantity,
    [SortMethod.COST]: sortByCost,
}
