import React, { useEffect } from 'react'

import { IonItem, IonItemDivider, IonList, IonSpinner } from '@ionic/react'
import styled from 'styled-components'

import { Box, Centered, Flex, Heading, Text } from '@components'
import { Client, useGetPopbysForClientLazyQuery } from '@graphql'
import { getDateTimeDisplay } from '@utils'

interface Popby {
    timestamp: string
    giftName: string
    giftCost: number | null | undefined
    method: string
}

const ListHeader = styled(IonItemDivider)`
    --padding-end: 0;
`

export const ClientPopbys = ({ client, refresh }: { client: Client; refresh: Date }) => {
    const [getPopbys, { data, loading, refetch }] = useGetPopbysForClientLazyQuery({
        variables: { clientId: client.id },
    })
    const popbys = (data?.getPopbysForClient || []) as Popby[]
    const lifetimeCost = popbys.reduce((curr, popby) => curr + (popby.giftCost || 0), 0)

    useEffect(() => {
        if (data) {
            refetch()
        } else {
            getPopbys()
        }
    }, [data, getPopbys, refetch, refresh])

    if (loading) {
        return (
            <Centered>
                <IonSpinner />
            </Centered>
        )
    }

    return (
        <>
            <IonList>
                <ListHeader sticky>
                    <Flex justifyContent="space-between" alignItems="center" my={1}>
                        <Heading as="h5"m={0}>Pop-Bys</Heading>
                        <Box textAlign="right">
                            <Text.p fontWeight="normal" fontSize="small" mb={client.sales ? 1 : 0}>
                                lifetime cost:{' '}
                                <Text.span fontWeight="bold">
                                    ${lifetimeCost.toLocaleString()}
                                </Text.span>
                            </Text.p>
                            {client.sales ? (
                                <Text.p fontWeight="normal" fontSize="small" mb={0}>
                                    total sales:{' '}
                                    <Text.span fontWeight="bold">
                                        ${client.sales.toLocaleString()}
                                    </Text.span>
                                </Text.p>
                            ) : null}
                        </Box>
                    </Flex>
                </ListHeader>
                {popbys.map((popby, index) => (
                    <PopbyItem key={`popby-${index}`} popby={popby} />
                ))}
            </IonList>
            {popbys.length === 0 && (
                <Box mt={4} pl="20px">
                    <Text.p>You have not visited {client.firstName} yet!</Text.p>
                </Box>
            )}
        </>
    )
}

const PopbyItem = ({ popby }: { popby: Popby }) => (
    <IonItem>
        <Flex>
            <Text.p color="medium" fontSize="small" mt={3} mb={1}>
                {getDateTimeDisplay(popby.timestamp)}
            </Text.p>
            <Flex justifyContent="space-between">
                <Text.p mb={3}>{popby.giftName}</Text.p>
                {popby.giftCost ? <Text.p mb={3}>${popby.giftCost?.toFixed(2)}</Text.p> : null}
            </Flex>
        </Flex>
    </IonItem>
)
