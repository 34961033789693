import React, { useEffect, useRef } from 'react'

import { useHistory } from 'react-router'
import { Redirect, Route } from 'react-router-dom'

import { useAuth, usePreferences } from '@hooks'
import {
    AppHome,
    HandleUnsubscribe,
    Login,
    ModPage,
    SetupBasicInfo,
    SetupIntro,
    SetupNextSteps,
} from '@pages'
import { isApp, isSetupComplete, isWebsite, useSetup } from '@utils'

import { GeoPermissions, NotificationPermissions } from './pages/Setup/Permissions'
import { useGetNonArchivedClientCountQuery } from '@graphql'

export const AppRoutes = () => {
    const history = useHistory()
    const originalPath = useRef(`${history.location.pathname}${history.location.search}`)
    const { authorized, user } = useAuth()
    const { data, refetch } = useGetNonArchivedClientCountQuery({ skip: !user || !authorized })
    const nonArchivedClientCount = data?.getNonArchivedClientCount.count || 0
    const hasDoneInitialRouting = useRef(false)
    const { proceed } = useSetup()
    const preferences = usePreferences()

    useEffect(() => {
        const ensureSetup = async () => {
            const path = history.location.pathname

            if (path === '/') {
                if (isApp) {
                    history.push('/login')
                } else if (originalPath.current !== '/') {
                    return
                }
            }

            if (authorized) {
                if (path === '/' && !isApp) {
                    hasDoneInitialRouting.current = true
                    return // They're on the marketing page, leave them be.
                }

                if (!(await isSetupComplete(preferences, user))) {
                    await proceed()
                } else if (!hasDoneInitialRouting.current) {
                    await proceed(originalPath.current || undefined)
                } else if (path.startsWith('/setup')) {
                    // If they're on a setup page when we've already
                    // done all the setup, shunt them to the dashboard.
                    await proceed()
                } else if (!user?.subscribed && nonArchivedClientCount > 5) {
                    history.push('/handleUnsubscribe')
                }

                // If they were forced into setup we still set this to
                // true since we want to drop them into the dashboard.
                hasDoneInitialRouting.current = true
            } else {
                originalPath.current = `${history.location.pathname}${history.location.search}`
            }
        }

        ensureSetup()
    }, [authorized, history.location.pathname, history.location.search, data])

    if (!authorized) {
        return (
            <Route path={isWebsite ? ['/login', '/app', '/setup'] : undefined} component={Login} />
        )
    }

    return (
        <>
            <Route path="/app" component={AppHome} />
            <Route path="/setup/intro" component={SetupIntro} />
            <Route path="/setup/basic_info" component={SetupBasicInfo} />
            <Route path="/setup/next_steps" component={SetupNextSteps} />
            {isApp && <Route path="/setup/geo" component={GeoPermissions} />}
            {isApp && <Route path="/setup/notif" component={NotificationPermissions} />}
            {!isApp && user && user.isAdmin ? <Route path="/mod" component={ModPage} /> : null}
            <Route path="/handleUnsubscribe" render={() => <HandleUnsubscribe onFinish={refetch} />} />
            <Route exact path="/login">
                {/* We're already authorized if we're rendering these routes. */}
                <Redirect to="/app/dashboard" />
            </Route>
        </>
    )
}
