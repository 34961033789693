export * from './apollo'
export * from './formatters'
export * from './subscription'
export * from './location'
export * from './logging'
export * from './platform'
export * from './routing'
export * from './TODO_secrets'
export * from './validation'
export type { Coordinates } from './location'

export enum TermDocument {
    TERMS_OF_USE = 'TERMS_OF_USE',
    PRIVACY_POLICY = 'PRIVACY_POLICY',
    EULA = 'EULA',
}

export const AllTermDocuments: TermDocument[] = [
    TermDocument.TERMS_OF_USE,
    TermDocument.PRIVACY_POLICY,
    TermDocument.EULA,
]

interface TermsBlob {
    header: string
    src: string
}

const Terms: { [key in TermDocument]: TermsBlob } = {
    [TermDocument.TERMS_OF_USE]: {
        header: 'Terms & Conditions',
        src: 'https://app.termly.io/document/terms-of-use-for-saas/ef749fb3-54a2-47e9-8756-a9f19b407485',
    },
    [TermDocument.PRIVACY_POLICY]: {
        header: 'Privacy Policy',
        src: 'https://app.termly.io/document/privacy-policy/6ac70a36-4a4d-4f08-940d-0433bf7d092d',
    },
    [TermDocument.EULA]: {
        header: 'End User License Agreement',
        src: 'https://app.termly.io/document/eula/b2070422-d652-4fd3-9344-2b71b5fe3777',
    },
}

export { Terms }
export type { TermsBlob }
