import React, { useState } from 'react'

import { SMS } from '@awesome-cordova-plugins/sms'
import {
    IonFooter,
    IonItem,
    IonLabel,
    IonList,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonSpinner,
    IonToolbar,
    useIonAlert,
} from '@ionic/react'
import { call, closeCircle, keypad } from 'ionicons/icons'
import { RouteComponentProps, useHistory } from 'react-router'

import { Box, Button, Centered, Content, Flex, Heading, Icon, Page, Text } from '@components'
import { useAddOrEditPopbyMutation, useGetPopbyInfoQuery } from '@graphql'
import { useAppState } from '@hooks'
import { fillTemplate, Geo, getDateDisplay, getTelLink, isApp, stripPhone } from '@utils'

import { ClientNotes } from '../Notes'
import { launchNavigation } from './utils'

enum NotifyMethod {
    text = 'text',
    call = 'call',
    none = 'none',
}

interface PopbyState {
    giftId?: string
    method?: NotifyMethod
    templateId?: string // required when method === 'text'
}

interface Props
    extends RouteComponentProps<{
        clientId: string
    }> {}

// TODO: break this component up
export const PopbyClient = ({ match }: Props) => {
    const { clientId } = match.params
    const [popbyState, setPopbyState] = useState<PopbyState>({ method: NotifyMethod.text })
    const [createPopby] = useAddOrEditPopbyMutation()
    const { data, loading } = useGetPopbyInfoQuery({ variables: { clientId } })
    const client = data?.getClient
    const pastGifts = data?.getPopbysForClient || []
    const [showAlert] = useIonAlert()
    const { gifts: _gifts, templates, user, globalHistory } = useAppState()
    const gifts = _gifts.data.stocked
    const messages = templates.data

    const history = useHistory()

    const selectGift = (giftId: string) => setPopbyState((prev) => ({ ...prev, giftId }))
    const selectMethod = (method: NotifyMethod) => setPopbyState((prev) => ({ ...prev, method }))

    const isValid = () =>
        popbyState.giftId &&
        popbyState.method &&
        (!client?.phone || popbyState.method !== NotifyMethod.text || popbyState.templateId)

    const getTextMessage = () =>
        fillTemplate(
            user.data,
            messages.filter((x) => x.id === popbyState.templateId)[0].message,
            client!,
        )

    const submit = async () => {
        const method = popbyState.method!
        const message = popbyState.templateId ? getTextMessage() : undefined

        // Update the database
        await createPopby({
            variables: {
                popby: {
                    clientId,
                    timestamp: new Date().getTime(),
                    giftId: popbyState.giftId!,
                    method,
                    message: message || null,
                },
            },
        })
        _gifts.refetch()

        if (method === NotifyMethod.text && client!.phone) {
            const phoneNumbers = [client!.phone]
            if (client!.spousePhone) phoneNumbers.push(client!.spousePhone!)
            if (client!.agentPhone) phoneNumbers.push(client!.agentPhone!)

            if (isApp) {
                try {
                    await SMS.send(phoneNumbers.map(stripPhone), message!)
                } catch (e) {}
            } else {
                console.log(`SMS not available.\nWould have sent the following message: ${message}
                    \n\nRecipients: ${phoneNumbers}`)
            }
        } else if (method === NotifyMethod.call && client!.phone) {
            document.location = getTelLink(client!.phone)
        }

        const snoozeClient = () => {
            if (!Geo) {
                return
            }

            const interval = user.data?.notificationInterval
            if (interval && interval !== 0 && interval !== undefined) {
                console.log('Snoozing client for interval: ', interval)
                var result = new Date(new Date())
                result.setDate(result.getDate() + interval)
                Geo.snoozeClient({ clientUserId: clientId, snoozeDt: result })
            }
        }

        await showAlert({
            header: 'Up for another Pop-By?',
            buttons: [
                {
                    text: "No and I'm done for the day",
                    role: 'confirm',
                    handler: () => {
                        if (Geo) {
                            Geo.doneForDay()
                        }
                        snoozeClient()
                        history.replace('/app/clients')
                        globalHistory.push('/app/dashboard')
                    },
                },
                {
                    text: 'No',
                    handler: () => {
                        if (Geo) {
                            Geo.toggleOnNotifications()
                        }
                        snoozeClient()
                        history.replace('/app/clients')
                        globalHistory.push('/app/dashboard')
                    },
                },
                {
                    text: 'Yes',
                    role: 'confirm',
                    handler: () => {
                        if (Geo) {
                            Geo.toggleOnNotifications()
                        }
                        snoozeClient()
                        history.replace('/app/clients?continue=true')
                    },
                },
            ],
            backdropDismiss: false,
        })
    }

    if (loading || !client) {
        return (
            <Page title="Pop-By Client" showBackButton SettingsButton={<></>}>
                <Content>
                    <Centered>
                        <IonSpinner />
                    </Centered>
                </Content>
            </Page>
        )
    }

    return (
        <Page title="Pop-By" showBackButton>
            <Content>
                <Box p={3} pb={0}>
                    <Heading as="h2"mt={0}>
                        You are visiting{' '}
                        <Text.span fontWeight="bold">
                            {client.firstName} {client.lastName}
                        </Text.span>
                    </Heading>
                    <Box pb={3} textAlign="center" borderBottom="base">
                        <Button
                            variant="primary"
                            fill="outline"
                            onClick={() => launchNavigation(clientId, client.address)}
                        >
                            Launch Navigation
                        </Button>
                    </Box>
                </Box>
                {client.notes && (
                    <Box p={3} borderBottom="base" borderBottomWidth={2}>
                        <Text.p mb={0}>Notes</Text.p>
                        <ClientNotes notes={client.notes} />
                    </Box>
                )}
                {pastGifts.length > 0 && (
                    <Box p={3} borderBottom="base" borderBottomWidth={2}>
                        <Text.p mb={0}>Your most recently gave {client.firstName}:</Text.p>
                        <ul className="ion-no-margin" style={{ listStyle: 'disclosure-closed' }}>
                            {pastGifts.slice(0, 3).map((popby, idx) => (
                                <li key={idx}>
                                    {popby.giftName}
                                    <Text.span ml={2} fontSize="small" color="medium">
                                        {getDateDisplay(popby.timestamp)}
                                    </Text.span>
                                </li>
                            ))}
                        </ul>
                    </Box>
                )}

                <IonList>
                    <IonItem>
                        <IonLabel>Gift</IonLabel>
                        <Flex flex={2} justifyContent="flex-end">
                            <IonSelect
                                interface="action-sheet"
                                placeholder="Choose..."
                                onIonChange={(e) => selectGift(e.detail.value)}
                                style={{ maxWidth: '100%' }}
                            >
                                {gifts.map((gift) => (
                                    <IonSelectOption value={gift.id} key={gift.name}>
                                        {gift.name} ({gift.quantity} left)
                                    </IonSelectOption>
                                ))}
                            </IonSelect>
                        </Flex>
                    </IonItem>
                    {client.phone && popbyState.method === NotifyMethod.text ? (
                        <IonItem>
                            <IonLabel>Message</IonLabel>
                            <Flex flex={2} justifyContent="flex-end">
                                <IonSelect
                                    interface="action-sheet"
                                    placeholder="Choose..."
                                    onIonChange={(e) =>
                                        setPopbyState((prev) => ({
                                            ...prev,
                                            templateId: e.detail.value,
                                        }))
                                    }
                                    style={{ maxWidth: '100%' }}
                                    value={popbyState.templateId}
                                >
                                    {messages.map((template) => (
                                        <IonSelectOption value={template.id} key={template.id}>
                                            {template.name}
                                        </IonSelectOption>
                                    ))}
                                </IonSelect>
                            </Flex>
                        </IonItem>
                    ) : null}
                </IonList>
                <Box p={4}>
                    <Heading as="h6"mt={0}>Ready to finish?</Heading>
                    {client.phone ? (
                        <IonSegment
                            onIonChange={(e) => selectMethod(e.detail.value! as NotifyMethod)}
                            value={popbyState.method}
                        >
                            {[NotifyMethod.text, NotifyMethod.call, NotifyMethod.none].map(
                                (method) => (
                                    <Box
                                        as={IonSegmentButton}
                                        key={method}
                                        value={method}
                                        pt={2}
                                        pb={1}
                                    >
                                        <Icon
                                            color="logo-primary"
                                            icon={
                                                method === NotifyMethod.text
                                                    ? keypad
                                                    : method === NotifyMethod.call
                                                    ? call
                                                    : closeCircle
                                            }
                                        />
                                        <IonLabel color="logo-primary">
                                            {method.charAt(0).toUpperCase() + method.substring(1)}
                                        </IonLabel>
                                    </Box>
                                ),
                            )}
                        </IonSegment>
                    ) : null}
                </Box>
            </Content>
            <IonFooter>
                <IonToolbar className="ion-no-padding" style={{ padding: 0 }}>
                    <Flex>
                        <Button
                            variant="primary"
                            className="ion-no-margin"
                            disabled={!isValid()}
                            onClick={submit}
                            style={{ flexGrow: 1 }}
                        >
                            Finish Pop-By
                        </Button>
                    </Flex>
                </IonToolbar>
            </IonFooter>
        </Page>
    )
}
