import React from 'react'
import { IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react'
import { useHistory } from 'react-router'

import { Box, Centered, Content, Flex, Heading } from '@components'
import { useGetUsersQuery } from '@graphql'

import { UserFilterBar } from './Users'

export const ModDashboard = () => {
    const history = useHistory()
    const { data, loading } = useGetUsersQuery()
    const [filter, setFilter] = React.useState<string | undefined>()

    if (loading) {
        return (
            <Centered>
                <IonSpinner />
            </Centered>
        )
    }

    return (
        <Content>
            <Flex
                alignItems={'center'}
                flexDirection="column"
                flexWrap={'nowrap'}
                justifyContent={'stretch'}
                width="100%"
            >
                <Box borderBottom={'1px solid'} style={{ flex: 1, width: 300 }}>
                    <Heading as="h2">Users</Heading>
                </Box>
                <UserFilterBar filter={filter} setFilter={setFilter} />
                <IonList lines="full" style={{ flex: 1, width: 300 }}>
                    {data?.getUsers?.map((user) => (
                        <IonItem key={user.uid}>
                            <Flex mt={2} flexWrap="nowrap" gap={2}>
                                <Box width={1 / 2}>
                                    <Flex
                                        flexDirection={'column'}
                                        justifyContent="space-between"
                                        mt={1}
                                        onClick={() => history.push(`/mod/user/${user.uid}`)}
                                        style={{ cursor: 'pointer' }}
                                    >
                                        <IonLabel position="stacked">
                                            {user.name || '<None>'}
                                        </IonLabel>
                                        <IonLabel position="stacked">{user.email}</IonLabel>
                                        {/* <ErrorMessage error={formState.errors.firstName} /> */}
                                    </Flex>
                                </Box>
                            </Flex>
                        </IonItem>
                    ))}
                </IonList>
            </Flex>
        </Content>
    )
}
