import React, { useEffect } from 'react'

import { IonItem, IonLabel, IonList, IonSpinner } from '@ionic/react'
import { RouteComponentProps, useHistory } from 'react-router'

import { AddressMap, Box, Centered, Content, Flex, Heading, Page, Text } from '@components'
import { useGetPopbyLazyQuery } from '@graphql'
import { getDateTimeDisplay, getTimeDisplay } from '@utils'

interface Props
    extends RouteComponentProps<{
        popbyId: string
    }> {}

export const ViewPopby = ({ match }: Props) => {
    const { popbyId } = match.params
    const [getPopby, { data, refetch }] = useGetPopbyLazyQuery({ variables: { popbyId } })
    const popby = data?.getPopby
    const history = useHistory()

    useEffect(() => {
        getPopby()
    }, [getPopby, popbyId])

    useEffect(() => {
        // Reload whenever this component is shown.
        if (history.location.pathname === match.url) {
            refetch()
        }
    }, [history.location.pathname, match.url, refetch])

    if (!popby) {
        return (
            <Page title="View Pop-By" showBackButton SettingsButton={<></>}>
                <Content>
                    <Centered>
                        <IonSpinner />
                    </Centered>
                </Content>
            </Page>
        )
    }

    return (
        <Page title="View Pop-By" showBackButton>
            <Content>
                <Box mx={4} my={3}>
                    <Text.p color="medium" fontSize="small" mb={0}>
                        {getDateTimeDisplay(popby.timestamp)}
                    </Text.p>
                    <Heading as="h4"mt={1}>
                        You visited {popby.client.firstName} {popby.client.lastName}
                    </Heading>
                </Box>
                <AddressMap address={popby.client.address} />
                <IonList>
                    <IonItem>
                        <IonLabel>Gift</IonLabel>
                        {popby.giftName}
                    </IonItem>
                    <IonItem>
                        <Flex my={3}>
                            <Flex justifyContent="space-between">
                                <IonLabel>Communication Method</IonLabel>
                                {popby.method.charAt(0).toUpperCase() + popby.method.substring(1)}
                            </Flex>
                            <Text.p fontSize="small" color="medium" mt={2} mb={0}>
                                {popby.method === 'none' ? (
                                    <>
                                        You didn't call or text {popby.client.firstName} (i.e. you
                                        could have spoken to them face-to-face when you dropped it
                                        off).
                                    </>
                                ) : (
                                    <>
                                        You {popby.method}ed {popby.client.firstName}{' '}
                                        {popby.client.spouseFirstName &&
                                            ` & ${popby.client.spouseFirstName}`}{' '}
                                        at {getTimeDisplay(popby.timestamp)}
                                    </>
                                )}
                            </Text.p>
                        </Flex>
                    </IonItem>
                    {popby.client.agentName && (
                        <IonItem>
                            <IonLabel>Agent</IonLabel>
                            {popby.client.agentName}
                        </IonItem>
                    )}
                </IonList>
            </Content>
        </Page>
    )
}
