import React from 'react'

import { IonBackButton, IonButtons, IonHeader, IonPage, IonTitle, IonToolbar } from '@ionic/react'

import { Settings } from '@pages'

export const Page = ({
    title,
    children,
    showBackButton = false,
    SettingsButton,
}: {
    title: string
    children: React.ReactNode
    showBackButton?: boolean
    SettingsButton?: React.ReactNode
}) => (
    <IonPage>
        <IonHeader>
            <IonToolbar>
                {showBackButton && (
                    <IonButtons slot="start">
                        <IonBackButton />
                    </IonButtons>
                )}
                <IonTitle>{title}</IonTitle>
                <IonButtons slot="end">{SettingsButton || <Settings />}</IonButtons>
            </IonToolbar>
        </IonHeader>
        {children}
    </IonPage>
)
