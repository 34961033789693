import React, { useState } from 'react'

import {
    IonButtons,
    IonHeader,
    IonImg,
    IonModal,
    IonTitle,
    IonToolbar,
    useIonAlert,
} from '@ionic/react'
import { Link, useLocation } from 'react-router-dom'

import { Box, Button, Flex, Heading, Text } from '@components'
import { AllTermDocuments, TermDocument, Terms, TermsBlob } from '@utils'

import { Section } from './Section'

export const Footer = () => {
    const location = useLocation()
    const searchParams = new URLSearchParams(location.search)
    const desiredTerm = searchParams.get('term')
        ? (searchParams.get('term') as TermDocument)
        : undefined
    const termBlob =
        desiredTerm && AllTermDocuments.includes(desiredTerm) ? Terms[desiredTerm] : undefined
    const [terms, setTerms] = useState<TermsBlob | undefined>(termBlob)
    const [showAlert] = useIonAlert()

    return (
        <>
            <Section bg="brandPrimary" color="white" flexGrow={1}>
                <Flex mb={7} justifyContent={['center', 'center', 'left']}>
                    <Flex width="auto" justifyContent="center" display={['none', 'none', 'block']}>
                        <IonImg
                            src="/assets/PopbyPro_vert_white.png"
                            alt="Pop-By Pro logo"
                            style={{ width: '150px' }}
                        />
                    </Flex>
                    <Flex width={1} justifyContent="center" display={['block', 'block', 'none']}>
                        <IonImg
                            src="/assets/PopbyPro_horiz_white.png"
                            alt="Pop-By Pro logo"
                            style={{ height: '150px' }}
                        />
                    </Flex>
                    <Flex justifyContent="space-around" width="auto" flexGrow={1}>
                        <Box
                            width={[1, 1, 'auto']}
                            textAlign={['center', 'center', 'left']}
                            mb={[4, 4, 0]}
                        >
                            <Heading as="h4"fontWeight="bold" mb={4}>
                                Pop-By Pro
                            </Heading>
                            <FooterLink label="Desktop Sign In" to="/login" />
                            <FooterLink
                                label="Download App"
                                href="https://apps.apple.com/us/app/pop-by-pro/id1641621318"
                            />
                            <FooterLink label="Pricing" scrollTo="#pricing" />
                        </Box>
                        <Box
                            width={[1, 1, 'auto']}
                            textAlign={['center', 'center', 'left']}
                            mb={[4, 4, 0]}
                        >
                            <Heading as="h4"fontWeight="bold" mb={4}>
                                Support
                            </Heading>
                            <FooterLink
                                label="Privacy Policy"
                                onClick={() => setTerms(Terms.PRIVACY_POLICY)}
                            />
                            <FooterLink
                                label="Terms & Conditions"
                                onClick={() => setTerms(Terms.TERMS_OF_USE)}
                            />
                            <FooterLink label="EULA" onClick={() => setTerms(Terms.EULA)} />
                        </Box>
                        <Box width={[1, 1, 'auto']} textAlign={['center', 'center', 'left']}>
                            <Heading as="h4"fontWeight="bold" mb={4}>
                                Company
                            </Heading>
                            <FooterLink label="Nestgenesis LLC, Leawood, Kansas" />
                            <FooterLink label="support@nest.work" href="mailto:support@nest.work" />
                            <FooterLink label="(816) 844-6470" href="tel:8168446470" />
                        </Box>
                    </Flex>
                </Flex>
                <Flex justifyContent="center" mb={3}>
                    <FooterLink
                        label={
                            <IonImg
                                src="/assets/home/facebook-logo.png"
                                alt="Facebook logo"
                                style={{ height: '30px', width: '30px' }}
                            />
                        }
                        href="https://www.facebook.com/groups/popbypro"
                    />
                </Flex>
                <Text.p mb={0} textAlign="center" fontSize="small">
                    &copy; {new Date().getFullYear()} Nestgenesis LLC | All Rights Reserved
                </Text.p>
            </Section>
            <IonModal isOpen={!!terms} onDidDismiss={() => setTerms(undefined)}>
                <IonHeader>
                    <IonToolbar>
                        <IonTitle>{terms?.header}</IonTitle>
                        <IonButtons slot="end">
                            <Button onClick={() => setTerms(undefined)}>Done</Button>
                        </IonButtons>
                    </IonToolbar>
                </IonHeader>
                <Flex flexDirection="column" flexWrap="nowrap" height="100%">
                    <Flex as="iframe" src={terms?.src} height="auto" frameBorder={0} flexGrow={1} />
                </Flex>
            </IonModal>
        </>
    )
}

const FooterLink = ({
    label,
    to,
    href,
    scrollTo,
    onClick,
}: {
    label: string | React.ReactNode
    to?: string
    href?: string
    scrollTo?: string
    onClick?: () => void
}) => {
    if (href) {
        return (
            <Text.p
                as="a"
                href={href}
                style={{ textDecoration: 'none' }}
                color="white"
                mb={4}
                display="block"
                target="_blank"
                rel="noopener noreferrer"
            >
                {label}
            </Text.p>
        )
    }

    if (scrollTo) {
        return (
            <Text.p
                style={{ cursor: 'pointer' }}
                color="white"
                onClick={(e: any) => {
                    e.preventDefault()
                    document?.querySelector(scrollTo)?.scrollIntoView({ behavior: 'smooth' })
                }}
            >
                {label}
            </Text.p>
        )
    }

    if (to) {
        return (
            <Link to={to} style={{ textDecoration: 'none' }}>
                <Text.p color="white">{label}</Text.p>
            </Link>
        )
    }

    return (
        <Text.p color="white" style={{ cursor: onClick ? 'pointer' : undefined }} onClick={onClick}>
            {label}
        </Text.p>
    )
}
