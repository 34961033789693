import React, { useState } from 'react'

export interface UserFilterBarProps {
    filter?: string
    setFilter: (filter: string) => void
}

export const UserFilterBar = ({ filter, setFilter }: UserFilterBarProps) => {
    const handleFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value)
    }

    return (
        <div>
            <input
                type="text"
                value={filter}
                onChange={handleFilterChange}
                placeholder="Filter users"
            />
        </div>
    )
}
