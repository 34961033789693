import React, { createContext, useEffect, useState } from 'react'

import { QueryResult } from '@apollo/client'
import { History } from 'history'
import { useHistory } from 'react-router'

import {
    Exact,
    GetGiftsQuery,
    GetTemplatesQuery,
    GetUserQuery,
    Gift,
    Template,
    useGetGiftsLazyQuery,
    useGetTemplatesLazyQuery,
    useGetUserLazyQuery,
} from '@graphql'
import { useAuth } from '@hooks'

import { INITIAL_FILTER as INITIAL_CLIENT_FILTER } from '../../pages/Clients/Clients/Clients.types'
import { INITIAL_FILTER as INITIAL_DASHBOARD_FILTER } from '../../pages/Dashboard/Dashboard.types'
import { INITIAL_FILTER as INITIAL_GIFT_FILTER } from '../../pages/Gifts/Gifts.types'

export interface DbUser {
    name?: string | null
    firstName?: string | null
    lastName?: string | null
    phone?: string | null
    company?: string | null
    signature?: string | null
    notifications: boolean
    notificationInterval: number
    isAdmin?: boolean | null
    subscribed: boolean
    stripeSubscriptionId?: string | null
}

export const AppContext = createContext<
    | {
          user: Omit<QueryResult<GetUserQuery, Exact<{ [p: string]: never }>>, 'data'> & {
              data: DbUser | undefined
          }
          gifts: Omit<QueryResult<GetGiftsQuery, Exact<{ [p: string]: never }>>, 'data'> & {
              data: {
                  all: Gift[]
                  active: Gift[]
                  stocked: Gift[]
              }
          }
          templates: Omit<QueryResult<GetTemplatesQuery, Exact<{ [p: string]: never }>>, 'data'> & {
              data: Template[]
          }
          globalHistory: History
          filters: { [key: string]: any }
          setFilter: (key: string, value: any) => void
      }
    | undefined
>(undefined)

export interface FirebaseProviderProps {
    children: React.ReactNode
}

/**
 * AppProvider holds on to the most frequently used information and
 * one-off helpers global to all components. Data should be used in
 * several spots to warrant inclusion in this context provider.
 *
 * Queries should follow the pattern of exposing all variables alongside
 * the data which can be adjusted here for ease of use.
 */
export const AppProvider = ({ children }: FirebaseProviderProps) => {
    const { authorized } = useAuth()
    const [userQuery, userQueryVariables] = useGetUserLazyQuery()
    const [giftsQuery, giftQueryVariables] = useGetGiftsLazyQuery()
    const [templatesQuery, templateQueryVariables] = useGetTemplatesLazyQuery()
    const [filters, setFilters] = useState<{ [key: string]: any }>({
        clients: INITIAL_CLIENT_FILTER,
        dashboard: INITIAL_DASHBOARD_FILTER,
        gifts: INITIAL_GIFT_FILTER,
    })

    useEffect(() => {
        const refresh = () => Promise.all([userQuery(), giftsQuery(), templatesQuery()])

        if (authorized) {
            refresh()
        }
    }, [authorized, userQuery, giftsQuery, templatesQuery])

    const gifts = giftQueryVariables.data?.getGiftsForUser || []

    const setFilter = (key: string, updater: (prev: any) => object) =>
        setFilters((prev) => ({ ...prev, [key]: updater(prev[key]) }))

    return (
        <AppContext.Provider
            value={{
                user: {
                    ...userQueryVariables,
                    data: userQueryVariables.data?.getUser,
                },
                gifts: {
                    ...giftQueryVariables,
                    data: {
                        all: gifts,
                        active: gifts.filter((gift) => !gift.archived),
                        // Stocked means active and stocked.
                        stocked: gifts.filter((gift) => !gift.archived && gift.quantity > 0),
                    },
                },
                templates: {
                    ...templateQueryVariables,
                    data: templateQueryVariables.data?.getTemplatesForUser || [],
                },
                globalHistory: useHistory(),
                filters,
                setFilter,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}
