import { SortDirection } from '../../../components/Filter/Filter.types'

export interface Client {
    id: string
    firstName: string
    lastName: string
    email: string
    type: ClientType
    address: {
        city: string
        state: string
        zip: string
        coordinates: {
            lat: number
            lng: number
        }
    }
    notifications: boolean
    distance?: number
    lastPopby: string | null
    dueForPopby?: boolean
    archived?: boolean | null
    agentName?: string
    agentPhone?: string
    birthday?: string
}

export enum ClientType {
    VETERAN = 'Veteran',
    A_PLUS = 'A+',
    A = 'A',
    B = 'B',
    C = 'C',
    NONE = 'Blank',
}

export const ClientTypes = [
    ClientType.A_PLUS,
    ClientType.A,
    ClientType.B,
    ClientType.C,
    ClientType.VETERAN,
    ClientType.NONE,
]

export interface ClientFilter {
    // Sorting mechanisms
    method: SortMethod
    direction: SortDirection

    // Filtering mechanisms
    search: string
    sections: Section[]
    clientTypes: ClientType[]
    agents: string[]
    terms: string[]
}

export enum Section {
    ACTIVE = 'Active',
    DUE = 'Due for Pop-By',
    NOT_DUE = 'Up to Date',
    INACTIVE = 'Inactive',
    ARCHIVED = 'Archived',
    ALL = 'All (no sections)',
}

export enum SortMethod {
    ALPHABETICAL = 'Alphabetical',
    DISTANCE = 'Distance',
    LAST_POP_BY = 'Last Pop-By',
}

export const INITIAL_FILTER = {
    method: SortMethod.DISTANCE,
    search: '',
    direction: SortDirection.ASC,
    // Show all sections
    sections: [Section.ACTIVE, Section.DUE, Section.NOT_DUE, Section.INACTIVE],
    // Show all client types
    clientTypes: ClientTypes,
    agents: ['All'],
    terms: [],
}
