import React from 'react'

import {
    startOfMonth as _startOfMonth,
    startOfQuarter as _startOfQuarter,
    startOfToday as _startOfToday,
    startOfWeek as _startOfWeek,
    startOfYear as _startOfYear,
} from 'date-fns'

import { Flex, Text } from '@components'

import { Popby, Scale } from '../Dashboard.types'

export const Highlights = ({ popbys, scale }: { popbys: Popby[]; scale: Scale }) => {
    const startOfToday = _startOfToday()
    const startOfThisWeek = _startOfWeek(startOfToday)
    const startOfThisMonth = _startOfMonth(startOfToday)
    const startOfThisQuarter = _startOfQuarter(startOfToday)
    const startOfThisYear = _startOfYear(startOfToday)

    const highlights = popbys.reduce(
        (acc, popby) => {
            const ts = new Date(popby.timestamp)

            if (ts > startOfThisYear) {
                acc.year += 1
                if (ts > startOfThisQuarter) {
                    acc.quarter += 1
                    if (ts > startOfThisMonth) {
                        acc.month += 1
                        if (ts > startOfThisWeek) {
                            acc.week += 1
                            if (ts > startOfToday) {
                                acc.day += 1
                            }
                        }
                    }
                }
            }

            return acc
        },
        { day: 0, week: 0, month: 0, quarter: 0, year: 0, total: 0 },
    )

    let primaryCount, secondaryCount, secondaryLabel, primaryLabel

    const today: [string, number] = ['Today', highlights.day]
    const week: [string, number] = ['Week', highlights.week]
    const month: [string, number] = ['Month', highlights.month]
    const quarter: [string, number] = ['Quarter', highlights.quarter]
    const year: [string, number] = ['Year', highlights.year]

    switch (scale) {
        case Scale.DAY:
            ;[primaryLabel, primaryCount] = today
            ;[secondaryLabel, secondaryCount] = week
            break
        case Scale.WEEK:
            ;[primaryLabel, primaryCount] = week
            ;[secondaryLabel, secondaryCount] = month
            break
        case Scale.MONTH:
            ;[primaryLabel, primaryCount] = month
            ;[secondaryLabel, secondaryCount] = quarter
            break
        case Scale.QUARTER:
            ;[primaryLabel, primaryCount] = quarter
            ;[secondaryLabel, secondaryCount] = year
            break
        case Scale.YEAR:
            ;[primaryLabel, primaryCount] = year
            ;[secondaryLabel, secondaryCount] = quarter
            break
    }

    return (
        <Flex justifyContent="space-evenly" alignItems="flex-end" mb={4} gap={3}>
            <Highlight count={secondaryCount} label={secondaryLabel} />
            <Highlight count={primaryCount} label={primaryLabel} primary />
            <Highlight count={popbys.length} label="Total" />
        </Flex>
    )
}

const Highlight = ({
    count,
    label,
    primary,
}: {
    count: number
    label: string
    primary?: boolean
}) => (
    <Flex
        bg="light"
        borderRadius={8}
        textAlign="center"
        py={3}
        px={2}
        width="calc(33.33333% - 8px)"
        flexDirection="column"
    >
        <Text.p fontSize={primary ? '48px' : '30px'} lineHeight={primary ? '48px' : '30px'} mb={2}>
            {count}
        </Text.p>
        <Flex alignItems="center" justifyContent="center" flexGrow={1}>
            <Text.p mb={0} fontSize={primary ? '20px' : '14px'}>
                {label}
            </Text.p>
        </Flex>
    </Flex>
)
