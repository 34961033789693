import React from 'react'

import { Button, Flex, Text } from '@components'
import { useSubscription } from '@hooks'

import { SettingsItem } from './SettingsItem'

export const SubscriptionSetting = () => {
    const subscription = useSubscription()

    return (
        <SettingsItem>
            <Flex alignItems="center" justifyContent="space-between">
                <Text.p marginBottom={0}>Subscription</Text.p>
                <Button onClick={() => subscription.manage()} variant="link">
                    Manage
                </Button>
            </Flex>
        </SettingsItem>
    )
}
