import {
    GetOptions,
    GetResult,
    Preferences,
    RemoveOptions,
    SetOptions,
} from '@capacitor/preferences'

import { useAuth } from '@hooks'

export interface UsePreferences {
    get: (options: GetOptions) => Promise<GetResult>
    set: (options: SetOptions) => Promise<void>
    remove: (options: RemoveOptions) => Promise<void>
}

export const getSessionSpecificKey = (key: string, uid?: string) => (uid ? `${key}_${uid}` : key)

export const usePreferences = (): UsePreferences => {
    const { user } = useAuth()

    const get = async (options: GetOptions) =>
        await Preferences.get({ ...options, key: getSessionSpecificKey(options.key, user?.uid) })

    const set = async (options: SetOptions) =>
        await Preferences.set({ ...options, key: getSessionSpecificKey(options.key, user?.uid) })

    const remove = async (options: RemoveOptions) =>
        await Preferences.remove({ ...options, key: getSessionSpecificKey(options.key, user?.uid) })

    return { get, set, remove }
}
