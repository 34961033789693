import React from 'react'

import {
    chatboxEllipses,
    fileTrayFull,
    gift,
    map,
    navigate,
    notifications,
    people,
    statsChart,
} from 'ionicons/icons'

import { Box, Flex, Heading, Icon, Text } from '@components'

import { Section } from './Section'

export const FeatureList = () => (
    <Section bg="white" id="features">
        <Heading as="h2"fontWeight="900" mt={0}>
            Pop-By Pro's premium features
        </Heading>
        <Text.p fontSize="large" mb={7} maxWidth={700}>
            Bring together everything you need to generate leads and connect with your clients.
            Created <em>by</em> realtors <em>for</em> realtors.
        </Text.p>
        <Flex gap="48px 24px">
            <Feature icon={notifications} description="Alerts you to nearby Pop-By opportunities" />
            <Feature icon={map} description="Provides mapping directions" />
            <Feature icon={gift} description="Keeps track of your gift inventory" />
            <Feature icon={navigate} description="Creates a proactive pop-by plan" />
            <Feature icon={fileTrayFull} description="Tracks history of pop-bys for each client" />
            <Feature icon={statsChart} description="Tracks metrics and performance over time" />
            <Feature
                icon={chatboxEllipses}
                description="Prompts you to send a text message or phone call to follow-up"
            />
            <Feature icon={people} description="Import contacts directly into Pop-By Pro" />
        </Flex>
    </Section>
)

const Feature = ({ icon, description }: { icon: string; description: string }) => (
    <Box
        width={['calc(50% - 12px)', 'calc(33.33333% - 16px)', 'calc(25% - 18px)']}
        color="brandPrimary"
    >
        <Text.p fontSize="48px" mb={2}>
            <Icon icon={icon} />
        </Text.p>
        <Text.p mb={0}>{description}</Text.p>
    </Box>
)
