import React, { useState } from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { useIonActionSheet, useIonAlert, useIonToast } from '@ionic/react'
import { ellipsisVerticalSharp } from 'ionicons/icons'
import { useHistory } from 'react-router'

import { Button, Icon } from '@components'
import { Client, GetClientQuery, useArchiveClientMutation } from '@graphql'

import { EditClientModal } from './EditClientModal'

export const ClientSettingsButton = ({
    client,
    refetch,
}: {
    client: Client
    refetch: () => Promise<ApolloQueryResult<GetClientQuery>>
}) => {
    const [showActionSheet] = useIonActionSheet()
    const [archiveClientMutation, { error }] = useArchiveClientMutation()
    const [showEditModal, setShowEditModal] = useState<boolean>(false)
    const [showAlert] = useIonAlert()
    const [toast] = useIonToast()
    const history = useHistory()
    const isArchived = client.archived

    const showEdit = () => setShowEditModal(true)
    const dismiss = () => setShowEditModal(false)

    const handleArchive = async () => {
        if (isArchived) {
            await toggleArchive()
        } else {
            await showAlert({
                message: 'Are you sure you want to archive this client?',
                buttons: [
                    'Cancel',
                    { text: 'Archive', handler: async () => await toggleArchive() },
                ],
            })
        }
    }

    const toggleArchive = async () => {
        await archiveClientMutation({
            variables: { clientId: client.id, archived: !isArchived },
        })

        if (!error) {
            await toast({
                message: `${isArchived ? 'Unarchived' : 'Archived'} ${client!.firstName} ${
                    client!.lastName
                }`,
                color: 'medium',
                duration: 2500,
            })
            history.replace('/app/clients')
        } else {
            await showAlert(
                'There was an error updating this client. ' +
                    'Please try again and contact us if the problem persists.',
            )
        }
    }

    return (
        <>
            <Button
                onClick={() =>
                    showActionSheet({
                        buttons: [
                            ...(isArchived ? [] : [{ text: 'Edit', handler: showEdit }]),
                            { text: isArchived ? 'Unarchive' : 'Archive', handler: handleArchive },
                            { text: 'Cancel', role: 'cancel' },
                        ],
                        header: 'Client Actions',
                    })
                }
            >
                <Icon icon={ellipsisVerticalSharp} />
            </Button>
            <EditClientModal
                client={client}
                isEditing={showEditModal}
                dismiss={dismiss}
                refetch={refetch}
            />
        </>
    )
}
