const breakpoints = ['40em', '52em', '70em']

const space = [0, 4, 8, 12, 16, 24, 32, 48, 64, 72]
const fontSizes = {
    small: 13,
    base: 17,
    large: 24,
}

// Colors taken from variables.css (ionic base styles)
const primary = 'var(--ion-color-primary)'
const secondary = 'var(--ion-color-secondary)'
const tertiary = 'var(--ion-color-tertiary)'
const success = 'var(--ion-color-success)'
const warning = 'var(--ion-color-warning)'
const danger = 'var(--ion-color-danger)'
const dark = 'var(--ion-color-dark)'
const medium = 'var(--ion-color-medium)'
const light = 'var(--ion-color-light)'
const brandPrimary = 'var(--ion-color-logo-primary)'
const brandSecondary = 'var(--ion-color-logo-secondary)'
const brandTertiary = 'var(--ion-color-logo-tertiary)'

const greens = ['', 'var(--color-green-1)', 'var(--color-green-2)', 'var(--color-green-3)']
const blues = [
    '',
    'var(--color-blue-1)',
    'var(--color-blue-2)',
    'var(--color-blue-3)',
    'var(--color-blue-4)',
]
const reds = ['', 'var(--color-red-1)', 'var(--color-red-2)', 'var(--color-red-3)']
const yellows = ['', 'var(--color-yellow-1)', 'var(--color-yellow-2)', 'var(--color-yellow-3)']

// Taken from ionic variables.css
const colors = {
    white: '#FFFFFF',
    black: '#000000',
    primary,
    secondary,
    tertiary,
    success,
    warning,
    danger,
    dark,
    medium,
    light,
    brandPrimary,
    brandSecondary,
    brandTertiary,

    greens,
    blues,
    reds,
    yellows,
}

const borders = {
    none: '0',
    base: `1px solid var(--ion-color-light-shade)`,
    greenOutline: '1px solid var(--color-green-2)',
    blueHighlight: '2px solid var(--color-blue-2)',
}

const theme = {
    borders,
    breakpoints,
    colors,
    fontSizes,
    space,
}

export default theme
