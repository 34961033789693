// These are configured in app store connect.
export enum AppleProduct {
    MONTHLY = 'pro.popby.monthly',
    ANNUAL = 'pro.popby.yearly',
}

export enum Duration {
    MONTH = 'month',
    YEAR = 'year',
}

export interface Product {
    price: string
    label: string
    iapProduct: AppleProduct
}

export const PRODUCT_MONTHLY: Product = {
    price: '$9.99/mo',
    label: 'Monthly',
    iapProduct: AppleProduct.MONTHLY,
}

export const PRODUCT_ANNUAL: Product = {
    price: '$99.99/yr',
    label: 'Annual',
    iapProduct: AppleProduct.ANNUAL,
}

export const PRODUCTS = {
    [Duration.MONTH]: PRODUCT_MONTHLY,
    [Duration.YEAR]: PRODUCT_ANNUAL,
}

export const isAppleSubscription = (subscriptionId: string) => subscriptionId === 'apple'
export const isStripeSubscription = (subscriptionId: string | null | undefined) =>
    subscriptionId && !isAppleSubscription(subscriptionId)
