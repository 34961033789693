import React from 'react'

import { IonBadge } from '@ionic/react'
import { FieldError } from 'react-hook-form'

export const ErrorMessage = ({ error }: { error: FieldError | undefined }) => {
    if (!error) {
        return null
    }

    let message = error.message

    if (message === '' && error.type) {
        if (error.type === 'required') {
            message = 'required'
        } else if (error.type === 'minLength') {
            message = 'too short'
        } else if (error.type === 'maxLength') {
            message = 'too long'
        } else {
            message = 'invalid'
        }
    }

    return <IonBadge color="danger">{message}</IonBadge>
}
