import React, { useEffect } from 'react'

import { useHistory } from 'react-router-dom'

export const AppURLListener: React.FC = () => {
    let history = useHistory()

    useEffect(() => {
        const handleNotificationClicked = (data: any) => {
            history.push(`/app/clients/view/${data['clientId']}`)
        }

        window.addEventListener('notificationClicked', handleNotificationClicked)

        return () => {
            window.removeEventListener('notificationClicked', handleNotificationClicked)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return null
}
