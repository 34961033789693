import React from 'react'

import { IonList } from '@ionic/react'

import { Text } from '@components'
import { SortDirection } from '@components'

import { GiftFilter, GiftSection as Section } from './Gifts.types'
import { Gift } from './Gifts.types'
import { SORTERS } from './Gifts.utils'
import { GiftSection } from './GiftSection'

interface Props {
    numTotal: number
    filter: GiftFilter
    active: Gift[]
    archived: Gift[]
    onGiftClick: (gift: Gift) => void
}

export const GiftSections = ({ numTotal, filter, active, archived, onGiftClick }: Props) => {
    const { method: sortMethod, direction: sortDirection, sections } = filter
    const showActive = sections.includes(Section.ACTIVE)
    const showArchived = sections.includes(Section.ARCHIVED)
    const numDisplayedResults =
        (showActive ? active.length : 0) + (showArchived ? archived.length : 0)

    if (numDisplayedResults === 0) {
        return (
            <Text.p textAlign="center" p={4} mt={4}>
                No gifts match your search criteria.
            </Text.p>
        )
    }

    const sorter = SORTERS[sortMethod]
    active.sort(sorter)
    archived.sort(sorter)

    if (sortDirection === SortDirection.DESC) {
        active.reverse()
        archived.reverse()
    }

    return (
        <>
            {numDisplayedResults !== numTotal && (
                <Text.p p={2} pb={0} fontSize="small" textAlign="center">
                    Showing <strong>{numDisplayedResults}</strong> matching gift
                    {numDisplayedResults === 1 ? '' : 's'} out of {numTotal} total
                    {archived.length > 0 && <> ({archived.length} archived)</>}.
                </Text.p>
            )}
            <IonList lines="full">
                {showActive && (
                    <GiftSection
                        section={Section.ACTIVE}
                        gifts={active}
                        onGiftClick={onGiftClick}
                        showHeader={showArchived && archived.length > 0}
                    />
                )}
                {showArchived && (
                    <GiftSection
                        section={Section.ARCHIVED}
                        gifts={archived}
                        onGiftClick={onGiftClick}
                        showHeader
                    />
                )}
            </IonList>
        </>
    )
}
