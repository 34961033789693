import React, { ReactNode } from 'react'

import { IonItem } from '@ionic/react'

import { Flex } from '@components'

export const SettingsItem = ({
    children,
    ...rest
}: {
    children: ReactNode
    [key: string]: any
}) => (
    <IonItem {...rest}>
        <Flex my={3} justifyContent="space-between" alignItems="center">
            {children}
        </Flex>
    </IonItem>
)
