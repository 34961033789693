import { SortDirection } from '../../components/Filter/Filter.types'

export interface Popby {
    id: string
    timestamp: string
    client: {
        firstName: string
        lastName: string
        type?: string | null
        agentName?: string | null
    }
    giftName: string
}

export enum ClientType {
    VETERAN = 'Veteran',
    A_PLUS = 'A+',
    A = 'A',
    B = 'B',
    C = 'C',
    NONE = 'Blank',
}

export const ClientTypes = [
    ClientType.A_PLUS,
    ClientType.A,
    ClientType.B,
    ClientType.C,
    ClientType.VETERAN,
    ClientType.NONE,
]

export enum Scale {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    QUARTER = 'quarter',
    YEAR = 'year',
}

export const Scales = [Scale.DAY, Scale.WEEK, Scale.MONTH, Scale.QUARTER, Scale.YEAR]

export interface DashboardFilter {
    // Sorting mechanisms
    direction: SortDirection

    // Display mechanisms
    method: Scale

    // Filtering mechanisms
    search: string
    clientTypes: ClientType[]
    agents: string[]

    sections: string[] // Unused
}

export const INITIAL_FILTER: DashboardFilter = {
    search: '',
    method: Scale.WEEK,
    sections: [],
    direction: SortDirection.ASC,
    // Show all client types
    clientTypes: ClientTypes,
    agents: ['All'],
}
