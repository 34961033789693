import React from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { IonItem, IonLabel, IonList } from '@ionic/react'

import { Box, Flex, Text } from '@components'
import { Client, GetClientQuery } from '@graphql'

import { ClientNotes } from '../Notes'
import { ClientNotificationsToggle } from './ClientNotificationsToggle'

export const ClientQuickDetails = ({
    client,
    refetch,
}: {
    client: Client
    refetch: () => Promise<ApolloQueryResult<GetClientQuery>>
}) => (
    <IonList lines="full">
        <IonItem>
            <Flex flexWrap="nowrap" alignItems="center" gap={5}>
                {client.type && (
                    <Flex>
                        <IonLabel>
                            Client Type: <strong>{client.type}</strong>
                        </IonLabel>
                    </Flex>
                )}
                {client.archived ? (
                    'Archived'
                ) : (
                    <ClientNotificationsToggle client={client} refetch={refetch} />
                )}
            </Flex>
        </IonItem>
        {client.email && (
            <IonItem>
                <Flex alignItems="center" flexWrap="nowrap" justifyContent="space-between">
                    <IonLabel>Email:</IonLabel>
                    <Box>
                        <strong>{client.email}</strong>
                    </Box>
                </Flex>
            </IonItem>
        )}
        {client.phone && (
            <IonItem>
                <Flex alignItems="center" flexWrap="nowrap" justifyContent="space-between">
                    <IonLabel>Phone:</IonLabel>
                    <Box>
                        <strong>{client.phone}</strong>
                    </Box>
                </Flex>
            </IonItem>
        )}
        {client.company && (
            <IonItem>
                <Flex alignItems="center" flexWrap="nowrap" justifyContent="space-between">
                    <IonLabel>Company:</IonLabel>
                    <Box>
                        <strong>{client.company}</strong>
                    </Box>
                </Flex>
            </IonItem>
        )}
        {client.agentName && (
            <IonItem>
                <Flex alignItems="center" flexWrap="nowrap" justifyContent="space-between">
                    <IonLabel>Agent:</IonLabel>
                    <Box>
                        <strong>{client.agentName}</strong>
                    </Box>
                </Flex>
            </IonItem>
        )}
        {client.notes && (
            <IonItem className="ion-align-items-start">
                <Box my={3} mr="auto">
                    <IonLabel className="ion-no-margin">Notes</IonLabel>
                    <ClientNotes notes={client.notes} />
                </Box>
            </IonItem>
        )}
    </IonList>
)
