import React from 'react'

import { IonItem, IonList } from '@ionic/react'

import { Box, Content, DownloadApp, Heading, Page, Text } from '@components'
import { isWebsite } from '@utils'

import faqs from './faqs.json'

export const FAQList = () => (
    <Page title="FAQs">
        <Content>
            <IonList lines="full">
                {faqs.map((faq) => (
                    <IonItem routerLink={`/app/faq/${faq.key}`} key={faq.key}>
                        <Box py={3}>{`${faq.title.charAt(0).toUpperCase()}${faq.title
                            .substring(1)
                            .toLowerCase()}`}</Box>
                    </IonItem>
                ))}
            </IonList>
            {isWebsite && (
                <Box mt={4} maxWidth={225} mx="auto">
                    <DownloadApp />
                </Box>
            )}
            <Box width={400} mx="auto" mt={6} textAlign="center">
                <Heading as="h3">Still having trouble?</Heading>
                <Text.p>
                    Checkout our{' '}
                    <strong>
                        <a href="https://www.youtube.com/@Pop-ByProVideos/featured">Youtube Channel</a>
                    </strong>{' '}
                    for instructional videos on how to use Pop-By Pro
                </Text.p>
                <Text.p>Contact us using one of the below methods</Text.p>
                <Text.p>
                    <strong>Email:</strong> <a href="mailto:support@nest.work">support@nest.work</a>
                </Text.p>
                <Text.p>
                    <strong>Phone:</strong> <a href="tel:8168446470">(816) 844-6470</a>
                </Text.p>
            </Box>
        </Content>
    </Page>
)
