import React from 'react'
import { IonRouterOutlet } from '@ionic/react'
import { Redirect, Route } from 'react-router'

import { Content, Page } from '@components'

import { ModDashboard } from './ModDashboard'
import { UserView } from './UserView'

export const ModPage = () => {
    return (
        <Page title="Moderator Dashboard">
            <Content scrollY={true}>
                <IonRouterOutlet>
                    <Route exact path="/mod">
                        <Redirect to="/mod/dashboard" />
                    </Route>
                    <Route path="/mod/dashboard" component={ModDashboard} />
                    <Route path="/mod/user/:uid" component={UserView} />
                </IonRouterOutlet>
            </Content>
        </Page>
    )
}
