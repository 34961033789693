import React, { useEffect, useState } from 'react'

import { Style } from '@capacitor/status-bar'
import {
    IonCheckbox,
    IonInput,
    IonItem,
    IonLabel,
    IonList,
    IonModal,
    IonPage,
    IonSpinner,
    useIonAlert,
} from '@ionic/react'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

import {
    Box,
    Button,
    Centered,
    Content,
    ErrorMessage,
    Flex,
    Heading,
    PhoneField,
    Text,
} from '@components'
import { useUpdateUserMutation } from '@graphql'
import { useAppState, usePreferences, useStatusBarTheme } from '@hooks'
import { SETUP_BASIC_INFO, TermDocument, Terms, useSetup } from '@utils'

interface UserBasicInfo {
    firstName: string
    lastName: string
    company?: string
    phone: string
}

const StyledIonItem = styled(IonItem)`
    --padding-start: 0;
    --inner-padding-start: 8px;
    --inner-padding-end: 8px;
    --padding-end: 0;
    --background: white;
`

const LABEL_WIDTH = 100

export const SetupBasicInfo = () => {
    useStatusBarTheme(Style.Light) // No toolbar on this page.
    const [showTermsModal, setShowTermsModal] = useState<TermDocument>()
    const [termsAccepted, setTermsAccepted] = useState<boolean>(false)
    const [showAlert] = useIonAlert()
    const [submitting, setSubmitting] = useState(false)
    const { user } = useAppState()
    const [update, { error }] = useUpdateUserMutation()
    const preferences = usePreferences()
    const { proceed } = useSetup()

    const {
        handleSubmit,
        formState: { errors },
        setValue,
        register,
    } = useForm<UserBasicInfo>()

    useEffect(() => {
        if (user) {
            setValue('firstName', user.data?.firstName || '')
            setValue('lastName', user.data?.lastName || '')
            setValue('company', user.data?.company || '')
            setValue('phone', user.data?.phone || '')
        }
    }, [user, setValue])

    const validateAndSubmit = async (data: UserBasicInfo) => {
        if (!termsAccepted) {
            await showAlert('Please accept the terms and conditions.')
        } else {
            await submit(data)
        }
    }

    const submit = async (data: UserBasicInfo) => {
        setSubmitting(true)
        await update({ variables: { updates: data } })

        if (!error) {
            await preferences.set({ key: SETUP_BASIC_INFO, value: 'true' })
            await proceed()
            await user.refetch()
        } else {
            await showAlert(
                'There was an error saving your information. ' +
                    'Please try again and contact us if the problem persists.',
            )
            setSubmitting(false)
        }
    }

    if (submitting) {
        return (
            <IonPage>
                <Content>
                    <Centered>
                        <IonSpinner />
                    </Centered>
                </Content>
            </IonPage>
        )
    }

    return (
        <>
            <IonPage>
                <Content>
                    <Centered>
                        <Heading as="h1">Getting Started</Heading>
                        <Text.p color="medium" mb={0}>
                            First, tell us a bit about yourself!
                        </Text.p>
                        <Box
                            as={IonList}
                            mx="auto"
                            px={4}
                            py={2}
                            m={4}
                            bg="white"
                            borderRadius={12}
                            border="base"
                            maxWidth="100%"
                        >
                            {!user?.data?.firstName && (
                                <StyledIonItem>
                                    <Box width={LABEL_WIDTH}>
                                        <IonLabel>First Name</IonLabel>
                                    </Box>
                                    <IonInput
                                        placeholder="John"
                                        autoCapitalize="words"
                                        {...register('firstName', { required: true })}
                                    />
                                    <ErrorMessage error={errors.firstName} />
                                </StyledIonItem>
                            )}
                            {!user?.data?.lastName && (
                                <StyledIonItem>
                                    <Box width={LABEL_WIDTH}>
                                        <IonLabel>Last Name</IonLabel>
                                    </Box>
                                    <IonInput
                                        placeholder="Doe"
                                        autoCapitalize="words"
                                        {...register('lastName', { required: true })}
                                    />
                                    <ErrorMessage error={errors.lastName} />
                                </StyledIonItem>
                            )}
                            <StyledIonItem>
                                <Box width={LABEL_WIDTH}>
                                    <IonLabel>Company</IonLabel>
                                </Box>
                                <IonInput
                                    placeholder="Doe, LLC"
                                    autoCapitalize="words"
                                    {...register('company', { required: false })}
                                />
                                <ErrorMessage error={errors.company} />
                            </StyledIonItem>
                            <StyledIonItem>
                                <Box width={LABEL_WIDTH}>
                                    <IonLabel>Phone</IonLabel>
                                </Box>
                                <PhoneField register={register} setValue={setValue} />
                                <ErrorMessage error={errors.phone} />
                            </StyledIonItem>
                            <Flex minHeight="44px" px={2} alignItems="center" flexWrap="nowrap">
                                <IonCheckbox
                                    slot="start"
                                    checked={termsAccepted}
                                    onIonChange={(e) => setTermsAccepted(e.detail.checked)}
                                    style={{ minWidth: '25px' }}
                                />
                                <Text.p ml={2} mb={0}>
                                    I agree to the{' '}
                                    <Text.span
                                        color="blues.1"
                                        onClick={() => setShowTermsModal(TermDocument.TERMS_OF_USE)}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        terms and conditions,{' '}
                                    </Text.span>
                                    <Text.span
                                        color="blues.1"
                                        onClick={() => setShowTermsModal(TermDocument.EULA)}
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        EULA,{' '}
                                    </Text.span>
                                    &{' '}
                                    <Text.span
                                        color="blues.1"
                                        onClick={() =>
                                            setShowTermsModal(TermDocument.PRIVACY_POLICY)
                                        }
                                        style={{ textDecoration: 'underline', cursor: 'pointer' }}
                                    >
                                        privacy policy
                                    </Text.span>
                                </Text.p>
                            </Flex>
                        </Box>
                        <Box>
                            <Button
                                onClick={handleSubmit(validateAndSubmit)}
                                expand="block"
                                variant="primary"
                            >
                                Continue
                            </Button>
                        </Box>
                    </Centered>
                </Content>
            </IonPage>
            <IonModal
                isOpen={showTermsModal !== undefined}
                onDidDismiss={() => setShowTermsModal(undefined)}
            >
                <Flex flexDirection="column" flexWrap="nowrap" height="100%">
                    {showTermsModal !== undefined ? (
                        <>
                            <Flex
                                as="iframe"
                                src={Terms[showTermsModal].src}
                                height="auto"
                                frameBorder={0}
                                flexGrow={1}
                            />
                            <Box p={4}>
                                <Button
                                    variant="primary"
                                    expand="block"
                                    onClick={() => {
                                        setTermsAccepted(true)
                                        setShowTermsModal(undefined)
                                    }}
                                >
                                    Agree
                                </Button>
                            </Box>
                        </>
                    ) : null}
                </Flex>
            </IonModal>
        </>
    )
}
