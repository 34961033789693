import { LaunchNavigator } from '@awesome-cordova-plugins/launch-navigator'

import { Address } from '@graphql'
import { addressToString, Geo, isPhone } from '@utils'

export const launchNavigation = (clientUserId: string, address: Address) => {
    const { lat, lng } = address.coordinates

    if (Geo) {
        // Turn off notifications while we drive there
        Geo.doneForDay()
        LaunchNavigator.navigate(addressToString(address))
        Geo.registerNavigationListener({ clientUserId }).catch((err: string) => {
            console.error('Encountered an error while registering navigation listener: ', err)
            if (err === 'NOT_FOUND') {
                // For some reason the user got deleted. Add it then try again
                Geo!
                    .addClient({ clientUserId, latitude: lat, longitude: lng })
                    .then(() => Geo!.registerNavigationListener({ clientUserId }))
            }
        })
    } else if (isPhone) {
        document.location = `geo:${lat},${lng}`
    } else {
        document.location = `maps:?q=${addressToString(address)}`
    }
}
