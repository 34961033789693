import React, { useEffect, useState } from 'react'

import { IonItem, IonLabel, IonToggle, useIonAlert } from '@ionic/react'

import { useSetUserNotificationsMutation } from '@graphql'
import { useAppState } from '@hooks'

export const NotificationsToggle = () => {
    const { user } = useAppState()
    const { notifications: userNotificationsOn } = user.data!
    const [setNotifications] = useSetUserNotificationsMutation()
    const [showAlert] = useIonAlert()

    const [notify, setNotify] = useState<boolean>(userNotificationsOn)

    // A useEffect is used here instead of inlining this code into the
    // onIonChange prop below to prevent duplicate submissions.
    useEffect(() => {
        const update = async () => {
            if (notify) {
                await toggle(notify)
            } else {
                await showAlert({
                    message:
                        'Are you sure you want to disable notifications? ' +
                        'You will not be notified of any nearby pop-bys.',
                    buttons: [
                        { text: 'Cancel', handler: () => setNotify(true) },
                        { text: 'Disable', role: 'destructive', handler: () => toggle(notify) },
                    ],
                })
            }
        }

        const toggle = async (checked: boolean) => {
            await setNotifications({ variables: { notify: checked } })
            await user.refetch()
        }

        if (notify !== userNotificationsOn) {
            update()
        }

        // client.notifications not included in deps because we want to
        // compare against the original value here.
    }, [notify, user, setNotifications, showAlert, userNotificationsOn])

    return (
        <IonItem>
            <IonToggle checked={notify} onIonChange={(e) => setNotify(e.detail.checked)}>
                <IonLabel>Notifications</IonLabel>
            </IonToggle>
        </IonItem>
    )
}
