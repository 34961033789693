import React, { ReactNode } from 'react'

import { Flex } from '@components'

import { FlexProps } from './Flex'

const Centered = ({ children, ...rest }: { children: ReactNode } & FlexProps) => (
    <Flex
        height="100%"
        justifyContent="center"
        alignItems="center"
        flexDirection="column"
        textAlign="center"
        {...rest}
    >
        {children}
    </Flex>
)

export default Centered
