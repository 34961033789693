import React from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { IonModal } from '@ionic/react'

import { Client, GetClientQuery } from '@graphql'

import { ClientForm } from '../ClientForm'

export const EditClientModal = ({
    client,
    isEditing,
    dismiss,
    refetch,
}: {
    client: Client
    isEditing: boolean
    dismiss: () => void
    refetch: () => Promise<ApolloQueryResult<GetClientQuery>>
}) => (
    <IonModal isOpen={isEditing}>
        <ClientForm
            client={client}
            onSuccess={() => {
                dismiss()
                refetch()
            }}
            onCancel={dismiss}
        />
    </IonModal>
)
