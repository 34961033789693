import { StatusBar, Style } from '@capacitor/status-bar'
import { setupIonicReact } from '@ionic/react'

import { isApp, isAppInBrowser } from '@utils'

export const setStatusBarStyle = (style: Style) => {
    if (isApp && !isAppInBrowser) {
        StatusBar.setStyle({ style })
    }
}

export const initTheme = () => {
    setupIonicReact({ mode: 'ios' })

    // The top bar is a dark color so make the status icons/info white.
    setStatusBarStyle(Style.Dark)
}
