import React, { useState } from 'react'

import { ApolloQueryResult } from '@apollo/client'
import { IonFab, IonFabButton, IonModal } from '@ionic/react'
import { add } from 'ionicons/icons'

import { Button, Icon } from '@components'
import { GetTemplatesQuery } from '@graphql'

import { TemplateForm } from './TemplateForm'

export const AddTemplateButton = ({
    floating = false,
    refetch,
}: {
    floating?: boolean
    refetch: () => Promise<ApolloQueryResult<GetTemplatesQuery>>
}) => {
    const [showModal, setShowModal] = useState<boolean>(false)

    const show = () => setShowModal(true)
    const dismiss = () => setShowModal(false)

    const onAddSuccess = async () => {
        dismiss()
        await refetch()
    }

    return (
        <>
            {floating ? (
                <IonFab vertical="bottom" horizontal="end" slot="fixed">
                    <IonFabButton color="link" onClick={show}>
                        <Icon icon={add} />
                    </IonFabButton>
                </IonFab>
            ) : (
                <Button variant="primary" onClick={show}>
                    Get Started
                </Button>
            )}
            <IonModal isOpen={showModal} onDidDismiss={dismiss}>
                <TemplateForm onSuccess={onAddSuccess} onCancel={dismiss} />
            </IonModal>
        </>
    )
}
