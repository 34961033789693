import React, { ReactNode } from 'react'

import { Box, Flex, Icon, Text } from '@components'

export const SubscriptionHighlight = ({
    icon,
    label,
    description,
}: {
    icon: string
    label: string
    description: ReactNode
}) => (
    <Flex
        alignItems="center"
        color="green"
        flexWrap="nowrap"
        p={3}
        mb={4}
        borderRadius={12}
        bg="greens.3"
        border="greenOutline"
    >
        <Box ml={3} mr={5}>
            <Icon icon={icon} size={24} />
        </Box>
        <Box>
            <Text.p mb={2} fontWeight="bold">
                {label}
            </Text.p>
            <Text.p fontSize="small" mb={0}>
                {description}
            </Text.p>
        </Box>
    </Flex>
)
