import React from 'react'

import { Style } from '@capacitor/status-bar'
import { IonPage } from '@ionic/react'
import '@ionic/react/css/ionic-swiper.css'
import 'swiper/css'
import 'swiper/css/navigation'
import 'swiper/css/pagination'

import { Box, Button, Centered, Content, Flex, Logo } from '@components'
import { usePreferences, useStatusBarTheme } from '@hooks'
import { SETUP_INTRO, useSetup } from '@utils'

export const SetupIntro = () => {
    useStatusBarTheme(Style.Light) // No toolbar on this page.
    const preferences = usePreferences()
    const { proceed } = useSetup()

    const next = async () => {
        await preferences.set({ key: SETUP_INTRO, value: 'true' })
        await proceed()
    }

    return (
        <IonPage>
            <Content>
                <Centered>
                    <Flex flexDirection="column" p={5} height="100%" justifyContent="center">
                        <Logo maxHeight="35%" />
                        <Box
                            mx="auto"
                            p={4}
                            my={5}
                            bg="white"
                            borderRadius={12}
                            border="base"
                            maxWidth={400}
                        >
                            <strong>Pop-By Pro</strong> was created to integrate your pop-by
                            practice into your daily life.
                        </Box>
                        <Box>
                            <Button onClick={next} variant="primary">
                                Awesome!
                            </Button>
                        </Box>
                    </Flex>
                </Centered>
            </Content>
        </IonPage>
    )
}
