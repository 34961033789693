import styled from 'styled-components'
import {
    color,
    ColorProps,
    layout,
    LayoutProps,
    space,
    SpaceProps,
    typography,
    TypographyProps,
} from 'styled-system'

export type HeadingProps = LayoutProps & TypographyProps & ColorProps & SpaceProps

const Heading = styled.span<HeadingProps>`
    ${layout}
    ${typography}
    ${color}
    ${space}
`

export default Heading
