import React from 'react'

import { useAppTheme } from '@hooks'

import { Box } from '../Design'
import { BoxProps } from '../Design/Box'

export const Logo = (props: BoxProps) => {
    const { isLightTheme } = useAppTheme()

    return (
        // @ts-ignore
        <Box {...props}>
            <img
                src="/assets/PopbyPro_vert_color.png"
                alt="Pop-By Pro Logo"
                style={{
                    display: isLightTheme ? 'block' : 'none',
                    margin: '0 auto',
                    maxHeight: '100%',
                }}
            />
            <img
                src="/assets/PopbyPro_vert_white.png"
                alt="Pop-By Pro Logo"
                style={{
                    display: isLightTheme ? 'none' : 'block',
                    margin: '0 auto',
                    maxHeight: '100%',
                }}
            />
        </Box>
    )
}
