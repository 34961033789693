import React from 'react'

import { IonButton } from '@ionic/react'

import { Box } from '@components'

import { BoxProps } from './Box'

type IonButtonProps = Omit<React.ComponentProps<typeof IonButton>, 'color'>
type ButtonVariant =
    | 'default'
    | 'primary'
    | 'logoPrimary'
    | 'neonPrimary'
    | 'link'
    | 'linkPrimary'
    | 'danger'
    | 'warning'
    | 'success'

const VARIANT_PROPS: { [K in ButtonVariant]: IonButtonProps & { color?: string } } = {
    default: {},
    primary: { color: 'link', fill: 'solid' },
    logoPrimary: { color: 'logo-primary', fill: 'solid' },
    neonPrimary: { color: 'logo-tertiary', fill: 'solid' },
    link: { color: 'link', fill: 'clear' },
    linkPrimary: { color: 'logo-primary', fill: 'clear' },
    danger: { color: 'danger', fill: 'solid' },
    warning: { color: 'warning', fill: 'solid' },
    success: { color: 'success', fill: 'solid' },
}

const Button = ({ variant, ...rest }: { variant?: ButtonVariant } & IonButtonProps & BoxProps) => {
    const variantProps = VARIANT_PROPS[variant!]

    return (
        <Box
            as={IonButton}
            {...variantProps}
            {...rest}
            style={{
                ...(rest.style || {}),
                '--border-radius': rest.borderRadius || 0,
                '--border-width': '2px',
            }}
        />
    )
}

Button.defaultProps = {
    variant: 'default',
}

export default Button
